import request from "lib/axio-utilis";
import { getUserDetail } from "utils/getUserDetailsHelper";

const usersAPI = {
  addUserInfo: (data) => {
    return request({ url: "/api/v1/users/createUser", method: "post", data: data });
  },
  EditUserInfo: (data) => {
    return request({ url: "/api/v1/users/editUser", method: "post", data: data });
  },
  deleteUserInfo: (data) => {
    return request({ url: "/api/v1/users/deleteUser", method: "post", data: data });
  },
  getAllUserData: () => {
    const loginUser = getUserDetail("_id");
    if (loginUser) {
      return request({ url: `/api/v1/users/getAllUser/${loginUser}` });
    }
  },
  getParticularUserInfo: async (id) => {
    return request({ url: "/api/v1/users/getUserById", method: "post", data: { userId: id } });
  },
};

export default usersAPI;
