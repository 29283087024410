import React from "react";
import "../styles/splashScreen.css";
import { Box, Container } from "@mui/material";
import { ReactComponent as Logo } from "assets/icons/main_logo.svg";

const SplashScreen = () => {
  return (
    <>
      <Container maxWidth="xl">
        <Box className="logo">
          <Logo />
        </Box>
      </Container>
    </>
  );
};

export default SplashScreen;
