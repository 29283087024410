import axios from "axios";
import config from "configs/config";
import apiClient from "lib/api-client";

//--------------- Save Limits ------------------------\\

export const createLimits = async (payload) => {
  let response = await apiClient.post(`${config.baseUrl}/api/v1/limit/create`, payload);
  console.log("createLimits -->", response);
  return response.data;
};

//--------------- Get Limits -----------------------\\

export const getLimits = async (userId) => {
  let response = await apiClient.get(`${config.baseUrl}/api/v1/limit/get-limit/${userId}`);
  console.log("getLimits -->", response);
  return response.data;
};

// http://localhost:8000/api/v1/limit/get-limit/67051b8b6f5a3f68cc04898b
