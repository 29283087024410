import { useInfiniteQuery } from "@tanstack/react-query";
import catalogAPIs from "../api/catalogAPIs";

export const useGetSingleCataloProducts = ({ catalogId = "", limit = 10, search = "", isArchive = false }) => {
  return useInfiniteQuery({
    queryKey: ["catalogProducts", { catalogId: catalogId, limit: limit, search: search, isArchive: isArchive }],
    queryFn: ({ pageParam = 1 }) => catalogAPIs.getSingleCatalogProducts({ pageParam, queryKey: ["catalogProducts", { catalogId: catalogId, limit: limit, search: search, isArchive: isArchive }] }),
    getNextPageParam: (lastPage) => {
      if (lastPage.nextPage < lastPage.totalPage) {
        return lastPage.nextPage + 1;
      }
      return undefined;
    },
    cacheTime: 200000,
    staleTime: 200000,
    refetchOnWindowFocus: true,
  });
};
