import React from "react";
import SortContext from "./SortContext";

//Render  Click View close

//render - > view the edit icon
// click - >
const SortContextProvider = ({ children }) => {
  const [sortOptions, setSortOptions] = React.useState([]);
  const [isEdit, setIsEdit] = React.useState("");
  const [isBtnEnable, setIsBtnEnable] = React.useState(false);

  const [isCollectionEdit, setIsCollectionEdit] = React.useState("");

  const [selectedSortValue, setSelectedSortValue] = React.useState("");
  const [search, setSearch] = React.useState(null);

  // For Set> Edit Breadcrumb
  const [isEditClick, setIsEditClick] = React.useState(false);
  return (
    <SortContext.Provider
      value={{
        sortOptions,
        setSortOptions,
        selectedSortValue,
        setSelectedSortValue,
        isEdit,
        setIsEdit,
        isEditClick,
        setIsEditClick,
        search,
        setSearch,
        isCollectionEdit,
        setIsCollectionEdit,
        isBtnEnable,
        setIsBtnEnable,
      }}
    >
      {children}
    </SortContext.Provider>
  );
};

export default SortContextProvider;
