import { Grid, Skeleton } from "@mui/material";
import React from "react";

const LoadingSmallCard = ({ count }) => {
  return (
    <Grid container spacing={4} className="Grid-padding">
      {Array.from(new Array(count)).map((_, index) => (
        <Grid item xs={12} sm={12} md={4} lg={3} xl={2.3} key={index}>
          <div className="skeleton-container">
            <Skeleton variant="rectangular" width={"317.75px"} height={62} />
            <div className="shimmer"></div>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

export default LoadingSmallCard;
