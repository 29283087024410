import { useInfiniteQuery } from "@tanstack/react-query";
import catalogReportsAPIs from "../api/catalogReportsAPIs";

export const useGetCatalogStylesReports = ({ limit = 22, sortBy = "view", serarch = "", sortType = "desc", catalogId }) => {
  return useInfiniteQuery({
    queryKey: ["CatalogStylesReports", { limit: limit, sortBy: sortBy, serarch: serarch, sortType: sortType, catalogId: catalogId }],
    queryFn: ({ pageParam = 1 }) =>
      catalogReportsAPIs.getCatalogReportStyles({
        pageParam,
        queryKey: ["CatalogStylesReports", { limit: limit, sortBy: sortBy, sortType: sortType, serarch: serarch, catalogId: catalogId }],
      }),
    getNextPageParam: (lastPage) => {
      if (lastPage.nextPage < lastPage.totalPage) {
        return lastPage.nextPage + 1;
      }
      return undefined;
    },
    enabled: !!catalogId ,
    cacheTime: 200000,
    staleTime: 200000,
    refetchOnWindowFocus: true,
  });
};
