import React, { useEffect, useState } from "react";
import { MenuItem, Select } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { transformData } from "utils/transformdata";
import { usePermissions } from "context/PermissionsContext";
import { findSpecificPage, hasAccessPage } from "utils/checkUserRigths";

const options = [];

const DropdownBtn = ({ data, cardId, location }) => {
  const [selectValue, setSelectValue] = useState("Add Designs");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const permission = usePermissions();

  const collectionViewAccess = hasAccessPage(findSpecificPage("Collection", permission), "view");
  const categoryViewAccess = hasAccessPage(findSpecificPage("Category", permission), "view");

  const handleChangeFun = (event) => {
    const selectedValue = event.target.value;
    setSelectValue(selectedValue);
    if (selectedValue !== "Add Designs") {
      const url = selectedValue.split("").slice(1).join("");
      navigate(`/${url}`, {
        state: {
          innerChnages: true,
          catalogueName: `SET - ${data?.setNumber}`, //catalogueName same as SETNAME
          setId: cardId.id,
          setUpdate: true,
          backPath: location?.pathname,
          backName: location?.state?.name,
          openCart: false,
        },
      });
      const desingsIds = data?.designs?.map((desing) => transformData(desing));
      sessionStorage.setItem("updateProductsData", JSON.stringify(desingsIds));
    }
  };

  useEffect(() => {
    if (categoryViewAccess) {
      options.push({ option: "By Category", link: "/Category" });
    }

    if (collectionViewAccess) {
      options.push({ option: "By Collection", link: "/Collection" });
    }
    return () => {
      options.length = 0;
    };
  }, []);

  return (
    <>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        fullWidth
        value={selectValue}
        onChange={handleChangeFun}
        displayEmpty
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        open={open}
        MenuProps={{
          PaperProps: {
            sx: {
              padding: "0px 5px",
              marginTop: "3px",
              backgroundColor: "#FFFFFF",
              color: "#000000",
              borderRadius: "5px",
              "& .MuiList-root": {
                "& .MuiMenuItem-root": {
                  margin: "2px",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  "&:hover": {
                    backgroundColor: "#444444",
                    color: "#FFFFFF",
                    borderRadius: "5px",
                    margin: "2px",
                  },
                },
              },
            },
          },
        }}
        sx={{
          fontFamily: "Poppins",
          fontSize: "14px",
          lineHeight: "22px",
          fontWeight: 500,
          height: "41px",
          borderRadius: "5px",
          textTransform: "none",
          color: "black",
          border: "1px solid black",
          backgroundColor: "#FFFFFF",
          "&:hover": {
            backgroundColor: "#FFFFFF",
            color: "#000000",
          },
          width: "113px",
          padding: "0px 10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          "& .MuiSelect-select": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            padding: "10px !important",
          },
          "& .MuiSelect-icon": {
            display: "none",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&:focus": {
            backgroundColor: "transparent",
          },
        }}
        renderValue={(selected) => (selected === "Add Designs" ? "Add Designs" : selected)}
      >
        {open
          ? options.map((item) => (
              <MenuItem key={item.option} value={item.link}>
                {item.option}
              </MenuItem>
            ))
          : [
              <MenuItem key="placeholder" value="Add Designs" disabled>
                Add Designs
              </MenuItem>,
              ...options.map((item) => (
                <MenuItem key={item.option} value={item.link}>
                  {item.option}
                </MenuItem>
              )),
            ]}
      </Select>
    </>
  );
};

export default DropdownBtn;
