export const getUserDetail = (key) => {
  console.log("Inside  getUserDetail");
  // const userInfo = sessionStorage.getItem("userData");
  const userInfo = localStorage.getItem("userData");
  if (userInfo) {
    const convertData = JSON.parse(userInfo);
    //return the actual Key
    return convertData[key];
  }
  return null;
};
