import { useLocation, useParams } from "react-router-dom";
import CatalogueProducts from "./CatalogueProduct";
import CatalogueActivity from "./CatalogueActivity";

const CatalogueDynamic = () => {
  const { id } = useParams();
  const location = useLocation();
  const isActivity = location.state?.isActivity;

  if (isActivity) {
    return <CatalogueActivity activityId={id} />;
  }

  return <CatalogueProducts catalogueId={id} />;
};

export default CatalogueDynamic;
