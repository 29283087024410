const { createTheme } = require("@mui/material");

const profileTheme = createTheme({
  components: {
    MuiFormControl: {
      styleOverrides: {
        Input: {
          color: "#FFFFFF",
          fontFamily: "Poppins",
          fontWeight: 400,
          fontSize: "18px",
          lineHeight: "27px",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: "Poppins",
          fontWeight: 400,
          fontSize: "20px",
          lineHeight: "22px",
          color: "#000000",
          marginBottom: "15px",
          "&.Mui-focused ": {
            color: "#000000", // Border color when focused
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          border: "1px solid #000000",
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          height: "50px",
        },
        notchedOutline: {
          borderColor: "transparent",
        },
        input: {
          fontFamily: "Poppins",
          fontWeight: 400,
          fontSize: "18px",
          lineHeight: "27px",
          color: "#000000",
        },
      },
    },
  },
});

export default profileTheme;
