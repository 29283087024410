import { useInfiniteQuery } from "@tanstack/react-query";
import catalogClientAPIs from "../api/catalogClientAPIs";

export const useGetAllCatalogsClients = ({ limit = 10, sortBy = "", serarch = "", sortType = "asc", catalogId = "671cf9a98ea4aa46627b4470" }) => {
  return useInfiniteQuery({
    queryKey: ["catalogClientsData", { limit: limit, sortBy: sortBy, serarch: serarch, sortType: sortType, catalogId: catalogId }],
    queryFn: ({ pageParam = 1 }) => catalogClientAPIs.getAllCatalogsClients({ pageParam, queryKey: ["catalogClientsData", { limit: limit, sortBy: sortBy, serarch: serarch, catalogId: catalogId }] }),
    getNextPageParam: (lastPage) => {
      if (lastPage.nextPage < lastPage.totalPage) {
        return lastPage.nextPage + 1;
      }
      return undefined;
    },
    cacheTime: 200000,
    staleTime: 200000,
    refetchOnWindowFocus: true,
  });
};
