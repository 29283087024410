import { Grid } from "@mui/material";
import { ProductCard } from "components/ui";
import { useCatalogSettingContext } from "context/CatalogSettingContext";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import CatalogClientTimeSpentModal from "./CatalogClientTimeSpentModal";
import withReportData from "../../utils/withReportData";
import ReportDatas from "../../utils/ReportDatas";
import CatalogClinetSortModal from "./CatalogClinetSortModal";
import { useGetCatalogClientStylesReports } from "../hooks/useGetCatalogClientStylesReports";
import clientReportsAPIs from "../api/clientReportsAPIs";
import SortContext from "context/SortContext";

const catalogProductsReports = [
  {
    id: 1,
    bronze: "NS-00410",
    view: 23,
    cart: 20,
    removeCart: 3,
    clock: "00:15:23",
  },
  {
    id: 2,
    silver: "NS-00560",
    view: 26,
    cart: 15,
    removeCart: 6,
    clock: "00:20:23",
  },
  {
    id: 3,
    gold: "NS-00603",
    view: 30,
    cart: 10,
    removeCart: 8,
    clock: "00:10:23",
  },
];

const catalogProductsDetails = [
  {
    id: 1,
    bronze: "NS-00410",
    diamond: "500/1.495",
    weight: "6.59/6.25",
    labour: "X",
    date: "15/02/2022",
  },
  {
    id: 2,
    silver: "NS-00560",
    diamond: "300/0.995",
    weight: "9.09/8.05",
    labour: "R",
    date: "17/02/2022",
  },
  {
    id: 3,
    gold: "NS-00603",
    diamond: "400/1.095",
    weight: "10.59/9.25",
    labour: "H",
    date: "19/02/2022",
  },
];

const CatalogClientReportScreen = ({ btnTitles, setBtnTitles, reportData, setReportData, handleHideDetails, ...props }) => {
  const { timeSpentCatalogOpen, setTimeSpentCatalogOpen, sortModal, setSortModal, serarch, setSerarch } = useCatalogSettingContext();
  const { selectedSortValue, sortOptions, setSortOptions, setSelectedSortValue } = React.useContext(SortContext);
  console.log("search =====>", serarch);
  const sort = sortOptions.find((item) => item.id == selectedSortValue);
  const { id, catalogClinetId } = useParams();
  const { data, isLoading, fetchNextPage, hasNextPage, isError, isFetching, isFetchingNextPage, error } = useGetCatalogClientStylesReports({
    catalogClinetId: catalogClinetId,
    catalogId: id,
    sortBy: sort?.sortBy,
    serarch: serarch || "",
    sortType: sort?.sortType,
  });

  // const handleShowDetails = async (_id) => {
  //   try {
  //     const details = await clientReportsAPIs.showDeginsDetials(_id);

  //     // Check if styleInfo exists
  //     if (!details?.reportStyles) {
  //       console.error("Details or styleInfo is missing");
  //       return;
  //     }

  //     setBtnTitles((prev) => ({ ...prev, [_id]: "Hide Details" }));

  //     setReportData((prev) => {
  //       const updatedData = {
  //         ...prev,
  //         pages: prev.pages.map((page) => ({
  //           ...page,
  //           reportStyles: page.reportStyles.map((product) => {
  //             const isMatch = product.style.toString() === _id.toString();
  //             return isMatch ? { ...product, ...details.reportStyles } : product;
  //           }),
  //         })),
  //       };

  //       setBtnTitles((prev) => ({ ...prev, [_id]: "Hide Details" }));
  //       return updatedData;
  //     });
  //   } catch (error) {
  //     console.error("Error fetching details:", error);
  //   }
  // };

  // useEffect(() => {
  //   if (data) {
  //     setReportData(data);
  //   }
  // }, [data]);

  const handleShowDetails = (_id) => {
    setBtnTitles((prev) => ({ ...prev, [_id]: "Hide Details" }));
  };

  useEffect(() => {
    return () => {
      setSortOptions([]);
      setSelectedSortValue("");
      setSerarch("");
    };
  }, []);

  return (
    <ReportDatas
      {...props}
      isLoading={isLoading}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isError={isError}
      isFetching={isFetching}
      isFetchingNextPage={isFetchingNextPage}
      error={error}
      btnTitles={btnTitles}
      reportData={data || []}
      handleShowDetails={handleShowDetails}
      handleHideDetails={handleHideDetails}
      timeSpentCatalogModal={!isLoading && <CatalogClientTimeSpentModal open={timeSpentCatalogOpen} setOpen={setTimeSpentCatalogOpen} />}
      sortCatalogModal={<CatalogClinetSortModal open={sortModal} setOpen={setSortModal} />}
    />
  );
};

export default withReportData(CatalogClientReportScreen, catalogProductsDetails, catalogProductsReports, "Client Report");
