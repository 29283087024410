import React from "react";
import Modal from "components/ui/Modal";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import { getIcon } from "utils/iconsConfig";
import "./ordersModal.css";
import CalenderComp from "./CalenderComp";

const orders = [
  {
    date: "Today",
    notifications: [
      {
        phone: "+91 8856049691",
        message: "Have Placed Order Of Catalog",
        catalogId: "56049691",
      },
    ],
  },
  {
    date: "Yesterday",
    notifications: [
      {
        phone: "+91 8856049691",
        message: "Have Placed Order Of Catalog",
        catalogId: "56049691",
      },
      {
        phone: "+91 8856049691",
        message: "Have Placed Order Of Catalog",
        catalogId: "56049691",
      },
    ],
  },
  {
    date: "25-07-24",
    notifications: [
      {
        phone: "+91 8856049691",
        message: "Have Placed Order Of Catalog",
        catalogId: "56049691",
      },
    ],
  },
];

const OrdersNotificationModal = ({ open, handleClose }) => {
  const [showCalendar, setShowCalendar] = React.useState(false);

  //toggle the calender
  const toggleCalendar = () => setShowCalendar((prev) => !prev);

  return (
    <>
      <Modal open={open} handleClose={handleClose} style={{ width: "430px", height: "650px" }}>
        <Box className="order-modal">
          <Box className="top-section">
            <Typography variant="h5">Order Notification</Typography>
            <IconButton onClick={toggleCalendar}>{getIcon("date", "black")}</IconButton>
          </Box>

          <Divider orientation="horizontal" sx={{ margin: 0, padding: 0 }} />

          <Box className="orders-list">
            {orders.map((day, ind) => {
              return (
                <div key={ind}>
                  <div className="day-section">
                    <span></span>
                    <strong>{day.date}</strong>
                  </div>

                  {day?.notifications?.map((noti, ind) => {
                    return (
                      <div key={ind}>
                        <div key={ind} className="notification">
                          <p>{noti.phone + " " + noti.message + " " + "#" + noti.catalogId}</p>
                        </div>
                        {ind !== day?.notifications?.length - 1 && <Divider orientation="horizontal" />}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </Box>
        </Box>
      </Modal>

      {/* {showCalendar && <CalenderComp closeCalender={() => setShowCalendar(false)} />} */}

      {showCalendar && <CalenderComp open={showCalendar} handleClose={toggleCalendar} />}
    </>
  );
};

export default OrdersNotificationModal;
