import request from "lib/axio-utilis";

const clientReportsAPIs = {
  //clinet -report page API:-
  getCatalogClientStylesReports: async ({ queryKey, pageParam = 1 }) => {
    const { limit, sortBy, serarch, sortType, catalogId, catalogClinetId } = queryKey[1];
    const requestData = {
      page: pageParam,
      limit: limit,
      catalogId: catalogId,
      catalogClinetId: catalogClinetId,
      sortBy: sortBy,
      sortType: sortType,
      serarch: serarch,
    };

    const response = await request({ url: "/api/v1/catalogSetting/client-report", method: "post", data: requestData });

    if (response.status !== 200) {
      throw new Error("Error fetching Catalog Client Style Report Information.");
    }

    return {
      reportStyles: response.data.clientReportStyles,
      nextPage: response.data.currentPage,
      totalPage: response.data.totalPages,
    };
  },

  showDeginsDetials: async (styleId) => {
    const requestData = {
      styleId: styleId,
    };

    const response = await request({ url: "/api/v1/catalogSetting/client-report/showDeginsDetials", method: "post", data: requestData });

    if (response.status !== 200) {
      throw new Error("Error fetching Style Report Information.");
    }

    return {
      reportStyles: response.data.styleInfo,
    };
  },

  getTotalTimeSpentOnCatalogByClient: async ({ queryKey, pageParam = 1 }) => {
    const { limit, catalogClientId } = queryKey[1];
    const requestData = {
      page: pageParam,
      limit: limit,
      catalogClientId: catalogClientId,
    };

    const response = await request({ url: "/api/v1/catalogSetting/client-report/getTotalTimeSpentOnCatalogByClient", method: "post", data: requestData });

    if (response.status !== 200) {
      throw new Error("Error fetching Catalog Client Time Report Information.");
    }

    return {
      report: response.data.noOfLogin,
      totalTimeSpent: response.data.totalTimeSpentOnCatalog,
      nextPage: response.data.currentPage,
      totalPage: response.data.totalPages,
    };
  },
};

export default clientReportsAPIs;
