import React from "react";
import { Box, Grid } from "@mui/material";
import { getIcon } from "utils/iconsConfig";
import Button from "components/ui/Button";
import Card from "components/ui/Card";
import "./setcard.css";
import { ImageComp } from ".";
import { usePermissions } from "context/PermissionsContext";
import { findSpecificPage, hasAccessPage } from "utils/checkUserRigths";

// const CardContainer = styled(Card)`
//   padding: 20px;
//   border-radius: 10px;
//   min-width: 435px;
//   max-width: 435px;
//   height: 276px;
//   display: flex;
//   justify-content: space-between;
// `;

const SetCard = ({ isBtn, openDrawer, btnTitle, buttonClick, innerChnages, data, isPresent, btnInfo }) => {
  const { setNumber, totalDiamonds, goldWeights, numberOfStyles, designsImgs, date, labour, maxMetalType } = data;

  const permission = usePermissions();
  const setArchiveAccess = hasAccessPage(findSpecificPage("Sets", permission), "archive");
  const viewAAccess = btnInfo && btnInfo.length > 0 && btnInfo.some((btn) => btn.view);

  return (
    <div className="set-card">
      <Card style={{ padding: "20px", borderRadius: "10px", minWidth: "435px", maxWidth: "435px", height: "276px" }}>
        <div className="left-side">
          <div className="content">
            {getIcon(maxMetalType)}
            <p className="heading">SET-{setNumber}</p>
          </div>

          <div className="content">
            {getIcon("set")}
            <p className="subheading">{numberOfStyles}Pieces</p>
          </div>

          <div className="content">
            {getIcon("diamond")}
            <p className="subheading">{totalDiamonds}</p>
          </div>

          <div className="content">
            {getIcon("goldbar")}
            <p className="subheading">{goldWeights}</p>
          </div>
          <div className="content">
            {getIcon("labour")}
            <p className="subheading">{labour}</p>
          </div>

          <div className="content">
            {getIcon("date")}
            <p className="subheading">{date}</p>
          </div>
        </div>

        <div className="right-side">
          <Grid container spacing={0.5} m={0} p={0} onClick={openDrawer}>
            <Grid
              item
              sm={6}
              lg={6}
              sx={{
                width: "114px",
                height: (isBtn === "click" && setArchiveAccess) || (isBtn === "click" && viewAAccess) || innerChnages ? "91px" : "114px",
                paddingTop: "0!important",
                paddingLeft: "0!important",
              }}
            >
              <div className="image-div">
                <ImageComp url={designsImgs[0].image} />
              </div>
            </Grid>

            <Grid
              item
              sm={6}
              lg={6}
              sx={{ width: "114px", height: (isBtn === "click" && setArchiveAccess) || (isBtn === "click" && viewAAccess) || innerChnages ? "91px" : "114px", paddingTop: "0!important" }}
            >
              <div className="image-div">
                <ImageComp url={designsImgs[1].image} />
              </div>
            </Grid>
            <Grid
              item
              sm={6}
              lg={6}
              sx={{ width: "114px", height: (isBtn === "click" && setArchiveAccess) || (isBtn === "click" && viewAAccess) || innerChnages ? "91px" : "114px", paddingLeft: "0!important" }}
            >
              <div className="image-div">
                <ImageComp url={designsImgs[2].image} />
              </div>
            </Grid>
            <Grid item sm={6} lg={6} sx={{ width: "114px", height: (isBtn === "click" && setArchiveAccess) || (isBtn === "click" && viewAAccess) || innerChnages ? "91px" : "114px" }}>
              <div className="image-div">
                <ImageComp url={designsImgs[3].image} />
                {numberOfStyles - 4 > 0 ? <Box className="overlayText">+{numberOfStyles - 4}</Box> : null}
              </div>
            </Grid>
          </Grid>

          {innerChnages ? (
            <Button
              sx={{
                "&.Mui-disabled": {
                  backgroundColor: "#FFFFFF !important",
                  color: "#444444 !important",
                  border: "1px solid #444444 !important",
                },
              }}
              fullWidth={true}
              variant="contained"
              disabled={isPresent ? isPresent : false}
              className={btnTitle === "Add" ? "btnAdd" : "btnAdded"}
              disableRipple
              onClick={buttonClick}
            >
              {btnTitle}
            </Button>
          ) : btnInfo && btnInfo.length > 0 ? (
            <Box sx={{ width: "102%", display: "flex", justifyContent: "space-between", alignItems: "center", marginRight: "5px", gap: "5px" }}>
              {isBtn === "click" &&
                btnInfo
                  ?.filter((item) => item.view)
                  .map((btn, _, filteredArr) => {
                    return (
                      <Button
                        fullWidth={true}
                        className={`${btn.title === "Delete" ? "btn-delete" : "btn-unarchive"} btn ${filteredArr.length === 1 && btn.title === "Unarchive" ? "btn-width" : ""}`}
                        variant="contained"
                        disableRipple
                        onClick={() => buttonClick(btn.title)}
                      >
                        {btn.title}
                      </Button>
                    );
                  })}
            </Box>
          ) : isBtn === "click" ? (
            setArchiveAccess && (
              <Button fullWidth={true} sx={{ border: "0.5px solid #E77B7B", width: "102%" }} variant="contained" className="btn" disableRipple onClick={buttonClick}>
                {btnTitle}
              </Button>
            )
          ) : null}

          {/* {isBtn === "click" && (
            <Button fullWidth={true} variant="contained" className="btn" disableRipple onClick={buttonClick}>
              {btnTitle}
            </Button>
          )} */}
        </div>
      </Card>
    </div>
  );
};

export default SetCard;
