import { useInfiniteQuery } from "@tanstack/react-query";
const { default: apiClient } = require("lib/api-client");

const fetchCategories = async ({ pageParam = 1, queryKey }) => {
  const [, { limit, sortBy, order, searchTerm }] = queryKey;
  const response = await apiClient.post("/api/v1/styles/getStyleByCategory", {
    pageNumber: pageParam,
    limit,
    sortBy,
    order,
    searchTerm,
  });

  if (response.status !== 200) {
    throw new Error("Error fetching categories");
  }

  return {
    categories: response.data.categories,
    nextPage: response.data.pagination.pageNumber + 1,
    totalPages: response.data.pagination.totalPages,
  };
};

export const useCategories = ({ limit = 10, sortBy = "", order = "asc", searchTerm = "" }) => {
  return useInfiniteQuery({
    queryKey: ["categories", { limit, sortBy, order, searchTerm }],
    queryFn: ({ pageParam = 1 }) => fetchCategories({ pageParam, queryKey: ["categories", { limit, sortBy, order, searchTerm }] }),
    getNextPageParam: (lastPage) => {
      if (lastPage.nextPage <= lastPage.totalPages) {
        return lastPage.nextPage;
      }
      return undefined;
    },
    staleTime: 5 * 60 * 1000,
    retry: 1,
  });
};
