import { useCatalogSettingContext } from "context/CatalogSettingContext";
import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import clientReportsAPIs from "../Report/api/clientReportsAPIs";

const withReportData = (WrappedComponent, catalogProductsDetails, catalogProductsReports, routTille) => {
  return (props) => {
    const [btnTitles, setBtnTitles] = useState({});
    const [reportData, setReportData] = useState([]);
    const { setUserReport, setUserCurrentPath, setSortModal } = useCatalogSettingContext();

    const handleShowDetails = (_id) => {
      setBtnTitles((prev) => ({ ...prev, [_id]: "Hide Details" }));
      // const details = await clientReportsAPIs.showDeginsDetials(_id);
      // const details = catalogProductsDetails.find((item) => item.id === id);

      // setReportData((prev) => prev.map((product) => (product._id === _id ? { ...product, ...details } : product)));
    };

    const handleHideDetails = (_id) => {
      setBtnTitles((prev) => ({ ...prev, [_id]: "Show Details" }));

      // const report = catalogProductsReports.find((item) => item.id === id);
      // setReportData((prev) => prev.map((product) => (product.id === id ? { ...product, ...report } : product)));
    };

    const location = useLocation();

    useEffect(() => {
      setUserReport(false);
      setUserCurrentPath({
        name: `${routTille} ${location?.state?.subName || ""}`,
        path: location?.pathname,
      });

      return () => {
        setUserReport(true);
        setUserCurrentPath({});
      };
    }, [location, setUserCurrentPath, setUserReport]);

    useEffect(() => {
      return () => setSortModal(false);
    }, [setSortModal]);

    return (
      <WrappedComponent
        {...props}
        btnTitles={btnTitles}
        setBtnTitles={setBtnTitles}
        reportData={reportData}
        setReportData={setReportData}
        handleShowDetails={handleShowDetails}
        handleHideDetails={handleHideDetails}
      />
    );
  };
};

export default withReportData;
