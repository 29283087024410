import axios from "axios";
import config from "configs/config";
import apiClient from "lib/api-client";

//--------------- Save Rights ------------------------\\

export const saveRights = async (payload) => {
  let response = await apiClient.post(`${config.baseUrl}/api/v1/role/assignRightsToExistingRole`, payload);
  return response.data;
};
