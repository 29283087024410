import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import Product from "./Product";
import SortContext from "context/SortContext";
import { useFilter } from "context/FilterContext";
import { useDesings } from "../api/get-desings";
import { useLocation } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { transformData } from "utils/transformdata";
import ProductSkeleton from "components/ui/Skeleton/ProductSkeleton";
import GoToTop from "components/ui/GoToTop";
import { ReactComponent as Background } from "assets/icons/bg.svg";

const Products = () => {
  const location = useLocation();
  const { selectedSortValue, sortOptions, search } = React.useContext(SortContext);
  const selectedOption = sortOptions.find((option) => option.id == selectedSortValue);
  const selectedSortBY = selectedOption?.sortBy;
  const selectedSortOrder = selectedOption?.order;
  const { filterValues } = useFilter();

  const labourValues = filterValues.Labour.map((item) => item.split(" - ")[0]).filter(Boolean);
  const classType = filterValues.Class;
  const diamondPieces = filterValues["Diamond Pieces"];
  const diamondWeight = filterValues["Diamond Weight"];
  const goldGrossWeight = filterValues["Gold Gross-Weight"];
  const goldNetWeight = filterValues["Gold Net-Weight"];
  const designInCollections = filterValues["Design In Collection"];
  const designInSets = filterValues["Design In Sets"];

  const categoryName = location.state?.name[0];
  // const subCategoryName = location.state?.name[1];
  const subCategoryName = location.state?.name[1].split("-")[0].trim();

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isError, error } = useDesings({
    limit: 12,
    categoryName,
    subCategoryName,
    sortBy: selectedSortBY,
    sortOrder: selectedSortOrder,
    labour: labourValues,
    classType: classType,
    diamondPieces,
    diamondWeight,
    goldGrossWeight,
    goldNetWeight,
    designInCollections,
    designInSets,
    search,
  });

  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 1.0,
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [selectedOption, filterValues]);

  if (isLoading) return <ProductSkeleton count={10} />;
  if (isError) return <div>Error: {error.message}</div>;

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        // alignItems="center"
        // spacing={5}
        sx={{ flex: "1 1 1", display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "30px", rowGap: "32px" }}
        className="Grid-padding"
      >
        {data?.pages?.map((page) =>
          page.designs.map((design, _ind) => {
            const transformedProduct = transformData(design);
            return <Product product={transformedProduct} key={design._id} />;
          })
        )}
      </Grid>

      <GoToTop />

      {isFetchingNextPage && <ProductSkeleton count={12} />}

      <div ref={ref} style={{ height: "20px", marginTop: "10px" }} />

      {data?.pages?.length === 0 ||
        (!data?.pages.some((page) => page?.designs?.length > 0) && (
          <div
            style={{
              display: "flex",
              textAlign: "center",
              margin: "auto",
              padding: "auto",
              justifyContent: "center",
              alignItems: "center",
              height: "78vh",
            }}
          >
            <Background />
          </div>
        ))}
    </>
  );
};

export default Products;
