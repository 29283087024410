import React from "react";
import { AsyncImage } from "loadable-image";

const ImageComp = ({ url }) => {
  return (
    <AsyncImage
      //   onClick={() => handleOpenImageModal(data.img, name)}
      src={url}
      //   style={{ height: isBtn ? "90%" : "100%", width: "100%", objectFit: "contain" }}
      style={{
        width: "100%",
        height: "100%",
        objectFit: "fill",
      }}
      loader={<div style={{ background: "#888" }} />}
      error={<div style={{ background: "#eee" }} />}
    />
  );
};

export default ImageComp;
