import React from "react";
import Card from "@mui/material/Card";
import Skeleton from "@mui/material/Skeleton";
import { Box, Grid } from "@mui/material";

const ProductSkeleton = ({ count }) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      // alignItems="center"
      // spacing={5}
      sx={{ flex: "1 1 1", display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "30px", rowGap: "32px" }}
      className="Grid-padding"
    >
      {Array(count)
        .fill(0)
        .map((_, ind) => {
          return (
            <Grid item className="product">
              <Card sx={{ maxWidth: "430px", minWidth: "430px", padding: "20px", borderRadius: "10px", display: "flex", justifyContent: "space-between" }} className="Grid-padding">
                <div style={{ width: "100%" }}>
                  <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
                    <Skeleton animation="wave" variant="circular" width={20} height={20} />
                    <Skeleton animation="wave" height={12} width="60%" sx={{ marginLeft: 2 }} />
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
                    <Skeleton animation="wave" variant="circular" width={20} height={20} />
                    <Skeleton animation="wave" height={12} width="60%" sx={{ marginLeft: 2 }} />
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
                    <Skeleton animation="wave" variant="circular" width={20} height={20} />
                    <Skeleton animation="wave" height={12} width="60%" sx={{ marginLeft: 2 }} />
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
                    <Skeleton animation="wave" variant="circular" width={20} height={20} />
                    <Skeleton animation="wave" height={12} width="60%" sx={{ marginLeft: 2 }} />
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
                    <Skeleton animation="wave" variant="circular" width={20} height={20} />
                    <Skeleton animation="wave" height={12} width="60%" sx={{ marginLeft: 2 }} />
                  </Box>
                </div>
                <div style={{ width: "100%" }}>
                  <Skeleton sx={{ height: "200px", width: "190px" }} animation="wave" variant="rectangular" />
                </div>
              </Card>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default ProductSkeleton;
