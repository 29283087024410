export const usersFilds = (roleData, id) => {
  return [
    {
      title: "UserName",
      id: "username",
      name: "username",
      type: "text",
      placeholder: "Username",
    },
    {
      title: "First Name",
      id: "firstName",
      name: "firstName",
      type: "text",
      placeholder: "First Name",
    },
    {
      title: "Last Name",
      id: "lastName",
      name: "lastName",
      type: "text",
      placeholder: "Last Name",
    },
    {
      title: "Role",
      id: "role",
      name: "role",
      type: "select",
      placeholder: "Role",
      roleData: roleData,
    },
    {
      title: id ? "New Password" : "Password",
      id: "password",
      name: "password",
      type: "text",
      placeholder: id ? "New Password" : "Password",
    },
    {
      title: "Confirm Password",
      id: "confirmPassword",
      name: "confirmPassword",
      type: "text",
      placeholder: "Confirm Password",
    },
  ];
};
