import { Card as MUICard, styled, Box } from "@mui/material";

const StyledCard = styled(MUICard)(({ width, height, style }) => ({
  maxWidth: width || "300px",
  height: height || "auto",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px",
  cursor: "pointer",
  ...style,

  // border: "1px solid red",
  // paddingBottom: "15px",
}));

function Card({ width, height, style = {}, children, ...props }) {
  return (
    <Box>
      <StyledCard width={width} height={height} style={style} {...props}>
        {children}
      </StyledCard>
    </Box>
  );
}
export default Card;
