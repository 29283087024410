import React, { useEffect, useState } from "react";
import TimeSpentModal from "../../utils/TimeSpentModal";
import { useGetAllTotalTimeSpentOnCatalogByClient } from "../hooks/useGetAllTotalTimeSpentOnCatalogByClient";
import { useParams } from "react-router-dom";

// const reportTimeData = {
//   totalCount: "155:44:15",
//   subData: [
//     {
//       id: 1,
//       clientName: "Siddhesh Kotkar",
//       count: "20:15:15",
//     },
//     {
//       id: 2,
//       clientName: "Anil Dyavanpelli",
//       count: "15:10:08",
//     },
//     {
//       id: 3,
//       clientName: "Adi Patil",
//       count: "20:15:15",
//     },
//     {
//       id: 4,
//       clientName: "Sneh Patel",
//       count: "10:18:21",
//     },
//     {
//       id: 5,
//       clientName: "Vadhi G",
//       count: "50:25:45",
//     },
//   ],
// };

function CatalogTimeSpentModal({ open, setOpen }) {
  const { id } = useParams();
  const {
    data,
    isLoading: timeIsLoding,
    isError: timeIsError,
    error: timeError,
    hasNextPage,
    fetchNextPage,
    isFetchNextPage,
    isFetching,
  } = useGetAllTotalTimeSpentOnCatalogByClient({ catalogId: id });

  return (
    <TimeSpentModal
      open={open}
      setOpen={setOpen}
      data={data || []}
      timeIsLoding={timeIsLoding}
      timeIsError={timeIsError}
      timeError={timeError}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
      isFetchNextPage={isFetchNextPage}
      isFetching={isFetching}
      title="Total Time Spent By Each Client"
      mainHeading="Total Time Spent on Catalog"
      totalCountTitle="Total Time"
    />
  );
}

export default CatalogTimeSpentModal;
