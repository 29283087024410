import { ProductCard } from "components/ui";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Grid } from "@mui/material";
import RemoveProduct from "components/ui/RemoveModal/RemoveModal";
import { useLocation } from "react-router-dom";
// import productData from "db/productsData.json";
import { ReactComponent as Background } from "assets/icons/bg.svg";
import { useGlobalContext } from "context/GlobalContext";
import { transformData, transformDataForTrayModal } from "utils/transformdata";
import { useGetSingleCataloProducts } from "../hooks/useGetSingleCatalogProducts";
import { useInView } from "react-intersection-observer";
import CatalogueCardSkeleton from "./CatalogueCardSkeleton";
import SortContext from "context/SortContext";
import { debounce } from "lodash";
import { findSpecificPage, hasAccessPage } from "utils/checkUserRigths";
import { usePermissions } from "context/PermissionsContext";
import GoToTop from "components/ui/GoToTop";
import ProductSkeleton from "components/ui/Skeleton/ProductSkeleton";

function CatalogueProducts({ catalogueId }) {
  const location = useLocation();
  const state = location.state;
  const isEdit = state && state.edit ? state.edit : false;

  const [removeProductModal, setRemoveProductModal] = useState(false);
  const [removeProductInfo, setRemoveProductInfo] = useState({});
  const [catalogueData, setCatalogueData] = useState([]);
  const { search, setSearch } = React.useContext(SortContext);
  const { setCreateBtn, setCreateBtnName, setCreateBtnOption, setRemoveIcon, trashModalOpen, setTrashModalOpen, setIsArchiveBtn } = useGlobalContext();
  const [checkedItems, setCheckedItems] = useState([]);
  const [isPagination, setIsPagination] = useState(false);
  const { ref, inView } = useInView({
    threshold: 1.0,
  });
  const permission = usePermissions();

  const {
    data: newData,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isError,
    isFetching,
    isFetchingNextPage,
    error,
  } = useGetSingleCataloProducts({
    catalogId: catalogueId,
    limit: 10,
    search: search || "",
  });

  const fetchedCatalogueData = (data) => {
    if (data) {
      return data?.pages
        .map((page) => {
          return page?.catalogProducts.map((product) => product);
        })
        .flat();
    }
    return [];
  };

  const getCatalogueData = () => {
    const updateProductsData = JSON.parse(sessionStorage.getItem("updateProductsData")) || [];
    if (isEdit && updateProductsData?.length > 0) {
      if (isPagination) {
        const catalogData = JSON.parse(sessionStorage.getItem("catalogWithProduct")) || {};
        let existingItemCatalogProduct = catalogData.productData || [];
        let newDataProduct = fetchedCatalogueData(newData);
        let findNewEntrysData = newDataProduct.filter((newItem) => {
          return !existingItemCatalogProduct.some((existingItem) => existingItem._id === newItem._id);
        });
        catalogData.productData = newDataProduct;
        sessionStorage.setItem("catalogWithProduct", JSON.stringify(catalogData));
        let newUpdateProductData = [...updateProductsData, ...findNewEntrysData];
        sessionStorage.setItem("updateProductsData", JSON.stringify(newUpdateProductData));
        setCatalogueData(newUpdateProductData);
      } else {
        setCatalogueData(updateProductsData);
      }
    } else {
      setCatalogueData(fetchedCatalogueData(newData));
      const catalogData = JSON.parse(sessionStorage.getItem("catalogWithProduct")) || {};
      catalogData.productData = fetchedCatalogueData(newData);
      sessionStorage.setItem("catalogWithProduct", JSON.stringify(catalogData));
      sessionStorage.setItem("updateProductsData", JSON.stringify(fetchedCatalogueData(newData)));
    }
  };

  const removeCart = (element) => {
    console.log("Remove click");
    setRemoveProductInfo(element);
    setRemoveProductModal(true);
  };

  const btnInfo = [
    {
      btnName: "Remove",
      bgColor: "#E77B7B",
      click: () => {
        const findRemoveProductIds = Object.keys(checkedItems);
        const newCatalogueData = catalogueData.filter((curElemt) => !findRemoveProductIds.includes(String(curElemt._id || curElemt.id)));
        setCatalogueData(newCatalogueData);
        sessionStorage.setItem("updateProductsData", JSON.stringify(newCatalogueData));
        setTrashModalOpen(false);
        setCheckedItems({});
      },
    },
    {
      btnName: "Keep",
      bgColor: "#50C878",
      click: () => setTrashModalOpen(false),
    },
  ];

  const handleCheckboxChange = (itemId) => (event) => {
    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = { ...prevCheckedItems };

      if (event.target.checked) {
        // Add the item if checked
        newCheckedItems[itemId] = true;
      } else {
        // Remove the item if unchecked
        delete newCheckedItems[itemId];
      }

      return newCheckedItems;
    });
  };

  const debouncedFetchNextPage = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  useEffect(() => {
    if (inView) {
      debouncedFetchNextPage(); // Call the debounced function directly
      setIsPagination(true);
    }
  }, [inView, debouncedFetchNextPage, isPagination]);
  useEffect(() => {
    if (Object.keys(checkedItems).length > 0) {
      setRemoveIcon(true);
    } else {
      setRemoveIcon(false);
    }
    return () => setRemoveIcon(false);
  }, [checkedItems]);

  useEffect(() => {
    if (isEdit) {
      setCreateBtn(hasAccessPage(findSpecificPage("Catalog", permission), "edit"));
      setCreateBtnName("Add Design");
      setCreateBtnOption([
        { option: "By Category", link: "/Category", view: hasAccessPage(findSpecificPage("Category", permission), "view") },
        { option: "By Collection", link: "/Collection", view: hasAccessPage(findSpecificPage("Collection", permission), "view") },
        { option: "By Set", link: "/Sets", view: hasAccessPage(findSpecificPage("Sets", permission), "view") },
      ]);

      setIsArchiveBtn(hasAccessPage(findSpecificPage("Catalog", permission), "archive"));

      return () => {
        setCreateBtn(false);
        setCreateBtnName("");
        setCreateBtnOption([]);
        setIsArchiveBtn(false);
      };
    }
  }, [isEdit]);

  useEffect(() => {
    getCatalogueData();
  }, [isEdit]);

  useEffect(() => {
    if (newData) {
      getCatalogueData();
    }
  }, [newData]);

  useEffect(() => {
    setSearch("");
    return () => {
      setSearch("");
    };
  }, []);

  if (isLoading) return <ProductSkeleton count={10} />;

  return (
    <>
      {catalogueData && catalogueData.length > 0 ? (
        <Grid
          container
          className="Grid-padding"
          direction="row"
          justifyContent="flex-start"
          sx={{
            flex: "1 1 1",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "32px",
            rowGap: "32px",
          }}
        >
          {catalogueData.map((design, index) => {
            // const curElement = transformData(design);
            let curElement = transformDataForTrayModal(design);
            return (
              <Grid key={`${index}-${curElement.id}`} item className="product">
                <ProductCard
                  key={index}
                  data={curElement}
                  removeCart={() => removeCart(curElement)}
                  isBtn={isEdit ? false : false}
                  btnTitle="Remove"
                  hoverEffect={isEdit ? true : false}
                  handleCheckboxChange={handleCheckboxChange}
                  checkedItems={checkedItems}
                  style={{
                    // maxWidth: isEdit ? "419px" : "430px",
                    // minWidth: isEdit ? "400px" : "380px",
                    maxWidth: isEdit ? "430px" : "430px",
                    minWidth: isEdit ? "430px" : "430px",
                  }}
                />
              </Grid>
            );
          })}
          {/* {isFetchingNextPage && <div style={{ color: "red" }} />} */}
          {isFetchingNextPage && <ProductSkeleton count={40} />}

          <div ref={ref} />
        </Grid>
      ) : (
        <div
          style={{
            display: "flex",
            textAlign: "center",
            margin: "auto",
            padding: "auto",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <Background />
        </div>
      )}
      <GoToTop />
      <RemoveProduct open={trashModalOpen} setOpen={setTrashModalOpen} btnInfo={btnInfo} heading="Do you want to remove the Design?" removeInfo={removeProductInfo} />
    </>
  );
}

export default CatalogueProducts;
