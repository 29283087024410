import { ReactComponent as Gold } from "assets/icons/gold.svg";
import { ReactComponent as Bronze } from "assets/icons/brozen.svg";
import { ReactComponent as Silver } from "assets/icons/sliver.svg";
import { ReactComponent as Diamond } from "assets/icons/diamond.svg";
import { ReactComponent as BlackDimondIcon } from "assets/icons/Set Tray Icon/Diamond.svg";
import { ReactComponent as Babor } from "assets/icons/babor.svg";
import { ReactComponent as BlackBabor } from "assets/icons/Set Tray Icon/Labor.svg";
import { ReactComponent as DateIcon } from "assets/icons/date.svg";
import { ReactComponent as BlackDateIcon } from "assets/icons/Black_Calender.svg";
import { ReactComponent as Weight } from "assets/icons/small.svg";
import { ReactComponent as BlackWeight } from "assets/icons/Set Tray Icon/gold.svg";
import { ReactComponent as CatalogClientReportProductViewEye } from "assets/catalogSetting/ViewProduct/eye.svg";
import { ReactComponent as CatalogClientReportProductCart } from "assets/catalogSetting/ViewProduct/Cart.svg";
import { ReactComponent as CatalogClientReportProductRemoveCart } from "assets/catalogSetting/ViewProduct/RemoveCart.svg";
import { ReactComponent as CatalogClientReportProductClock } from "assets/catalogSetting/ViewProduct/Clock.svg";
import { ReactComponent as SetsIcons } from "assets/sidebar/enable/sets.svg";
import { ReactComponent as CollectionIcon } from "assets/sidebar/enable/collection.svg";
import { ReactComponent as BalckCollectionIcon } from "assets/icons/Set Tray Icon/collection.svg";
import { ReactComponent as EyeBtn } from "assets/icons/CatalogueCard/eye.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as GoToTop } from "assets/icons/goTop.svg";
import { ReactComponent as Next } from "assets/icons/next.svg";
import { ReactComponent as Prev } from "assets/icons/prev.svg";

export const getIcon = (type, variant = "default") => {
  const icons = {
    gold: variant === "black" ? <Gold /> : <Gold />,
    silver: variant === "black" ? <Silver /> : <Silver />,
    bronze: variant === "black" ? <Bronze /> : <Bronze />,
    diamond: variant === "black" ? <BlackDimondIcon /> : <Diamond />,
    goldbar: variant === "black" ? <BlackWeight /> : <Weight />,
    labour: variant === "black" ? <BlackBabor /> : <Babor />,
    date: variant === "black" ? <BlackDateIcon /> : <DateIcon />,
    catalogView: variant === "black" ? <CatalogClientReportProductViewEye /> : <CatalogClientReportProductViewEye />,
    catalogCart: variant === "black" ? <CatalogClientReportProductCart /> : <CatalogClientReportProductCart />,
    catalogRemoveCart: variant === "black" ? <CatalogClientReportProductRemoveCart /> : <CatalogClientReportProductRemoveCart />,
    catalogClock: variant === "black" ? <CatalogClientReportProductClock /> : <CatalogClientReportProductClock />,
    set: variant === "black" ? <SetsIcons /> : <SetsIcons />,
    collection: variant === "black" ? <BalckCollectionIcon /> : <CollectionIcon />,
    close: variant === "black" ? <CloseIcon /> : <CloseIcon />,
    eye: variant === "black" ? <EyeBtn /> : <EyeBtn />,
    GoToTop: variant === "black" ? <GoToTop /> : <GoToTop />,
    next: variant === "black" ? <Next /> : <Next />,
    prev: variant === "black" ? <Prev /> : <Prev />,
  };

  return icons[type] || null;
};
