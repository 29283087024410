import { useInfiniteQuery } from "@tanstack/react-query";
import clientReportsAPIs from "../api/clientReportsAPIs";

export const useGetTotalTimeSpentOnCatalogByClient = ({ limit = 10, catalogClientId = "6744381c59b61af167182258" }) => {
  return useInfiniteQuery({
    queryKey: ["TotalTimeSpentOnCatalogByClient", { limit: limit, catalogClientId: catalogClientId }],
    queryFn: ({ pageParam = 1 }) =>
      clientReportsAPIs.getTotalTimeSpentOnCatalogByClient({
        pageParam,
        queryKey: ["TotalTimeSpentOnCatalogByClient", { limit: limit, catalogClientId: catalogClientId }],
      }),
    getNextPageParam: (lastPage) => {
      if (lastPage.nextPage < lastPage.totalPage) {
        return lastPage.nextPage + 1;
      }
      return undefined;
    },
    cacheTime: 200000,
    staleTime: 200000,
    refetchOnWindowFocus: true,
  });
};
