import { AppBar, Box, Divider, Toolbar } from "@mui/material";
import Header from "components/Header/Header";
import Sidebar from "components/Sidebar/Siderbar";
import BreadCrumbComponent from "components/ui/BreadCrumb";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import SettingChildHeader from "./SettingChildHeader";

const SettingLayout = ({ icon }) => {
  return (
    <>
      <div className="main-layout">
        <AppBar position="fixed" elevation={0} sx={{ backgroundColor: "#000", padding: "0px 0px 6px 0px" }}>
          <Toolbar></Toolbar>
        </AppBar>
        <SettingChildHeader icon={icon} />
        <div className="main-body">
          <Sidebar />
          <div className="content" style={{ padding: "0px 0px 0px 10px" }}>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingLayout;
