const roledata = [
  // {
  //   roleName: "Dummy Role",
  //   Id: "1",
  // },
  // {
  //   roleName: "Dummy Role",
  //   Id: "1",
  // },
  // {
  //   roleName: "Dummy Role",
  //   Id: "1",
  // },
  // {
  //   roleName: "Dummy Role",
  //   Id: "1",
  // },
  // {
  //   roleName: "Dummy Role",
  //   Id: "1",
  // },
];

export default roledata;
