import React, { useContext, useEffect, useState } from "react";
import "./childHeader.css";
import BreadCrumbComponent from "components/ui/BreadCrumb";
import { Outlet, useLocation } from "react-router-dom";
import IconsChild from "./SubComponets/IconsChild";
import SortContext from "context/SortContext";

function ChildHeaderLayout({ pageName, icon, deIcon }) {
  const { setIsEdit, isEditClick, setIsEditClick } = useContext(SortContext);
  console.log("Inside Child Header ---------->>", isEditClick);

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const location = useLocation();
  const state = location.state;

  const isExactMatch = location.pathname === `/${pageName}` || location.pathname === `/${pageName.replace(/s$/, "")}`;

  const useQuery = () => {
    return new URLSearchParams(location.search);
  };

  const query = useQuery();
  let nextName = [pageName];
  nextName = nextName.concat(query.getAll("name"));

  useEffect(() => {
    const pathnames = location.pathname.split("/").filter((x) => x);

    const newBreadcrumbs = [];

    let newPath = "";

    if (!location.pathname.includes("Sets")) {
      setIsEditClick(false);
    }

    pathnames.forEach((name, index) => {
      newPath = newPath + "/" + name;
      newBreadcrumbs.push({
        name: nextName[index] ? nextName[index] : state ? state.name[index - 1] : "",
        path: newPath,
      });
    });

    if (state && state.subName) {
      newBreadcrumbs.push({
        name: state.subName,
        path: "",
      });
    }

    if (isEditClick) {
      newBreadcrumbs.push({
        name: "Edit",
        path: "",
      });
    }

    setBreadcrumbs(newBreadcrumbs);
  }, [location, isEditClick]);

  const handleBreadcrumbClick = (breadcrumb) => {
    // alert("Bread crummb clicked ");
    console.log("breadcrumb.name==========>>>>>>>>>>", breadcrumb.name);
    if (breadcrumb.name === "Set") {
      setIsEditClick(false);
      setIsEdit("view");
    }
  };

  return (
    <>
      <div className="child-header" style={{ padding: "10px" }}>
        <div className="child-header-sub">
          {isExactMatch ? icon : deIcon}
          <BreadCrumbComponent breadcrumbs={breadcrumbs} onBreadcrumbClick={handleBreadcrumbClick} />
        </div>
        <IconsChild location={location} />
      </div>
      <div className="child-header-content" style={{ padding: "10px", paddingTop: "0px" }}>
        <Outlet />
      </div>
    </>
  );
}

export default ChildHeaderLayout;
