import { Button as MUIButton } from "@mui/material";
import React from "react";

const Button = (props) => {
  return (
    <>
      <MUIButton {...props}>{props.children}</MUIButton>
    </>
  );
};

export default Button;
