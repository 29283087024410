import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
const { default: apiClient } = require("lib/api-client");

const unArchiveSet = async ({ setId }) => {
  const response = await apiClient.post(`/api/v1/set/unarchiveSetById`, { setId });
  return response.data;
};

export const useUnarchiveSet = ({ onCompleted }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: unArchiveSet,
    onSuccess: (data) => {
      toast.success(data.message);
      queryClient.invalidateQueries("archives-sets");
      onCompleted();
    },
    onError: (error) => {
      toast.error("Failed to unarchive the set.");
    },
  });
};
