import { useInfiniteQuery } from "@tanstack/react-query";
import catalogClientAPIs from "../api/catalogClientAPIs";

export const useGetAllCatalogClinetActivityInfo = ({ limit = 10, catalogClinetId = "6744381c59b61af167182258" }) => {
  return useInfiniteQuery({
    queryKey: ["catalogClientsActivityInfoData", { limit: limit, catalogClinetId: catalogClinetId }],
    queryFn: ({ pageParam = 1 }) => catalogClientAPIs.getCatalogClientsActivity({ pageParam, queryKey: ["catalogClientsActivityInfoData", { limit: limit, catalogClinetId: catalogClinetId }] }),
    getNextPageParam: (lastPage) => {
      if (lastPage.nextPage < lastPage.totalPage) {
        return lastPage.nextPage + 1;
      }
      return undefined;
    },
    cacheTime: 200000,
    staleTime: 200000,
    refetchOnWindowFocus: true,
  });
};
