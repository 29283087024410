const { createTheme } = require("@mui/material");

const custumTheme = createTheme({
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: "#191818",
          color: "#FFFFFF",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Karla",
          fontWeight: 400,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Poppins",
          color: "#000000",
          fontSize: "14px",
          lineHeight: "22px",
          fontWeight: 500,
          height: "50px",
          borderRadius: "5px",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "#FFFFFF",
          },
          "&:disabled": {
            backgroundColor: "#F5F5F5", // Example disabled background color
            color: "#A9A9A9", // Example disabled text color
            cursor: "not-allowed", // Optional: Change cursor style for disabled state
          },
          backgroundColor: "#FFFFFF",
        },
      },
    },
  },
});

export default custumTheme;
