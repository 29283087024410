import React from "react";
import { Grid, Typography } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Card from "components/ui/Card";
import { encodeStr } from "utils/encodeDecodeStr";

const SubCategory = ({ subcategory }) => {
  const navigate = useNavigate();
  const { category } = useParams();
  const location = useLocation();
  const handleNavigate = (subcategory) => {
    const state = {
      ...location.state,
      name: [`${location.state.name[0]}`, `${subcategory.subCategory} - ${subcategory.count}`],
    };
    navigate(`/Category/${category}/${encodeStr(subcategory.subCategory)}`, { state });
  };

  return (
    <Card style={{ minWidth: 275, maxWidth: "317px", height: 62, borderRadius: "5px" }} onClick={() => handleNavigate(subcategory)}>
      <Typography mr={"auto"} fontSize={"20px"} fontWeight={400} fontFamily={"Karla"}>
        {subcategory.subCategory}
      </Typography>
      <Typography ml={"auto"} fontSize={"20px"} fontWeight={400} fontFamily={"Karla"}>
        {subcategory.count}
      </Typography>
    </Card>
  );
};

export default SubCategory;
