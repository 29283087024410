import { useInfiniteQuery } from "@tanstack/react-query";
const { default: apiClient } = require("lib/api-client");

const fetchSets = async ({ pageParam = 1, queryKey }) => {
  const [, { limit, sortBy, order, searchTerm }] = queryKey;

  const response = await apiClient.post("/api/v1/set/getAllSet", {
    pageNumber: pageParam,
    limit,
    sortBy,
    order,
    searchTerm,
  });

  if (response.status !== 200) {
    throw new Error("Error fetching categories");
  }

  return {
    sets: response.data.sets,
    nextPage: response.data.pagination.pageNumber + 1,
    totalPages: response.data.pagination.totalPages,
  };
};

export const useSets = ({ limit = 10, sortBy = "Set Date", order = "desc", searchTerm = "" }) => {
  return useInfiniteQuery({
    queryKey: ["sets", { limit, sortBy, order, searchTerm }],
    queryFn: ({ pageParam = 1 }) => fetchSets({ pageParam, queryKey: ["sets", { limit, sortBy, order, searchTerm }] }),
    getNextPageParam: (lastPage) => {
      if (lastPage.nextPage <= lastPage.totalPages) {
        return lastPage.nextPage;
      }
      return undefined;
    },
    staleTime: 5 * 60 * 1000,
    retry: 1,
  });
};
