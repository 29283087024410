import { styled } from "@mui/material";
import Button from "components/ui/Button";
import { useSettingContext } from "context/SettingContext";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const StyledButton = styled(Button)(({ theme, variant }) => ({
  width: variant === "cancel" ? "110px" : "160px",
  fontFamily: "karla",
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "22px",
  height: "42px",
  borderRadius: "5px",
  color: variant === "cancel" ? "#E77B7B" : "#FFF",
  border: `1px solid ${variant === "cancel" ? "#E77B7B" : "#fff"}`,
  textTransform: "none",
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: variant === "cancel" ? "#E77B7B" : "#fff",
    color: variant === "cancel" ? "#000" : "#000",
  },
}));

const EditUserChildHeader = ({}) => {
  const { setCancelEditUserModal, cancelEditChnages } = useSettingContext();
  const navigation = useNavigate();
  const location = useLocation();

  const handleOpenModal = () => {
    if (cancelEditChnages) {
      console.log(location.state);
      const state = { ...location.state, isEditUser: false, name: [`Users`] };
      console.log(state);
      navigation("/Setting/users", { state });
      setCancelEditUserModal(false);
    }
    setCancelEditUserModal(true);
  };
  return (
    <div style={{ display: "grid", gridAutoFlow: "column" }}>
      <div className="child-header-sub" style={{ marginLeft: "20px", marginRight: "20px" }}>
        <h7 style={{ color: "#fff", fontFamily: "poppins", fontSize: "24px", fontWeight: "400" }}>Edit User</h7>
      </div>
      <div className="child-header-sub" style={{ justifyContent: "flex-end", marginRight: "20px" }}>
        <StyledButton variant="cancel" onClick={handleOpenModal}>
          Cancel
        </StyledButton>
      </div>
    </div>
  );
};

export default EditUserChildHeader;
