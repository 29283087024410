import { useMutation, useQueryClient } from "@tanstack/react-query";
import usersAPI from "features/Setting/users/api/userApis";

const useDeleteUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: usersAPI.deleteUserInfo,
    onSuccess: (data) => {
      if (data?.data?.message === "User Profile Delete Successfully") {
        queryClient.setQueryData(["users-data"], (oldQueryData) => {
          return {
            ...oldQueryData,
            data: {
              ...oldQueryData.data,
              message: data?.data?.message,
              User: oldQueryData.data.User.filter((user) => user._id !== data?.data?.user?._id),
            },
          };
        });
      } else {
        console.error(data?.data?.message || "An unexpected error occurred");
      }
    },
    onError: (error) => {
      console.error("Delete User Error:", error?.response?.data?.message || error?.message);
    },
  });
};

export default useDeleteUser;
