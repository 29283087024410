import { useQueryClient, useMutation } from "@tanstack/react-query";
import catalogAPIs from "features/catalogue/api/catalogAPIs";
import { toast } from "react-toastify";

export const useCatalogArchiveToUnarchive = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: catalogAPIs.catalogArchiveToUnarchive,
    onSuccess: (data) => {
      if (data?.data?.status === 200) {
        queryClient.invalidateQueries("catalogData");
        toast.success(data.data.message || `Catalog updated successfully`, {
          position: "top-right",
          autoClose: 2000,
        });
      }
    },
    onError: (error) => {
      console.error("Mutation failed:", error);
      toast.error("An error occurred while updating the catalog", {
        position: "top-right",
        autoClose: 2000,
      });
    },
  });
};
