import React, { useEffect, useState } from "react";
import { Box, Drawer, IconButton, List } from "@mui/material";
import Divider from "@mui/material/Divider";
import "./style.css";
import { ReactComponent as Logo } from "assets/icons/logo.svg";

import { ReactComponent as Catlogue1 } from "assets/sidebar/enable/catalogue.svg";
import { ReactComponent as Category1 } from "assets/sidebar/enable/category.svg";
import { ReactComponent as Collection1 } from "assets/sidebar/enable/collection.svg";
import { ReactComponent as Sets1 } from "assets/sidebar/enable/sets.svg";
import { ReactComponent as Setting1 } from "assets/sidebar/enable/setting.svg";
import { ReactComponent as Catlogue2 } from "assets/sidebar/disable/catalogue.svg";
import { ReactComponent as Category2 } from "assets/sidebar/disable/category.svg";
import { ReactComponent as Collection2 } from "assets/sidebar/disable/collection.svg";
import { ReactComponent as Sets2 } from "assets/sidebar/disable/sets.svg";
import { ReactComponent as Setting2 } from "assets/sidebar/disable/setting.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { hasAccessPage, findSpecificPage, forwordRouteAccordingToRights } from "utils/checkUserRigths";
import { usePermissions } from "context/PermissionsContext";
import { getUserDetail } from "utils/getUserDetailsHelper";

const drawerWidth = 100;

function Sidebar() {
  const permissions = usePermissions();
  const [activeItem, setActiveItem] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  const siderBarIconData = [
    {
      name: "Catalog",
      pathName: "Catalog",
      enableicon: <Catlogue1 />,
      disableicon: <Catlogue2 />,
    },
    {
      name: "Category",
      pathName: "Category",
      enableicon: <Category1 />,
      disableicon: <Category2 />,
    },
    {
      name: "Collection",
      pathName: "Collection",
      enableicon: <Collection1 />,
      disableicon: <Collection2 />,
    },
    {
      name: "Sets",
      pathName: "Sets",
      enableicon: <Sets1 />,
      disableicon: <Sets2 />,
    },
  ];

  useEffect(() => {
    const path = location.pathname.split("/")[1];
    switch (path) {
      case "Catalog":
        setActiveItem("Catalog");
        break;
      case "CatalogSetting":
        setActiveItem("Catalog");
        break;
      case "Category":
        setActiveItem("Category");
        break;
      case "Collection":
        setActiveItem("Collection");
        break;
      case "Sets":
        setActiveItem("Sets");
        break;
      case "Setting":
        setActiveItem("Setting");
        break;
      default:
        setActiveItem("");
    }
  }, [location.pathname]);

  return (
    <Drawer variant="permanent" sx={{ width: drawerWidth, flexShrink: 0, [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box", backgroundColor: "#333", color: "#fff" } }}>
      <div className="sidebar-container">
        <Logo
          onClick={() => {
            let nextRoute = forwordRouteAccordingToRights(permissions);
            navigate(nextRoute);
          }}
          className="sidebar-logo"
        />
        <Box sx={{ display: "flex", justifyContent: "center", paddingTop: "20px" }} width={"100%"}>
          <Divider orientation="horizontal" flexItem style={{ borderColor: "#444444", width: "100%" }} />
        </Box>
        <List sx={{ marginTop: "6.5px" }}>
          <div className="sidebar-icons-space">
            {siderBarIconData.map((data, index) => {
              const findPage = hasAccessPage(findSpecificPage(data.name, permissions), "view");
              if (!findPage) {
                return null;
              }
              return (
                <Link to={"/" + data.pathName} className="sidebar-custom-link" key={index}>
                  <div className="custom-list-item" onClick={() => handleItemClick(data.name)}>
                    {activeItem === data.name ? data.enableicon : data.disableicon}

                    <span className={`sidebar-icon-name ${activeItem === data.name ? "sidebar-icon-name-active" : ""}`}>{data.name}</span>
                  </div>
                </Link>
              );
            })}
          </div>
        </List>

        {hasAccessPage(findSpecificPage("Settings", permissions), "view") && (
          <Link to="/Setting" className="sidebar-custom-link">
            <div className="custom-list-item" onClick={() => handleItemClick("Setting")}>
              {activeItem === "Setting" ? <Setting1 /> : <Setting2 />}

              <span className={`sidebar-icon-name ${activeItem === "Setting" ? "sidebar-icon-name-active" : ""}`}>Setting</span>
            </div>
          </Link>
        )}
      </div>
    </Drawer>
  );
}

export default Sidebar;
