import React, { Fragment, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Category from "./Category";
import SortContext from "context/SortContext";
import { useCategories } from "../api/get-categories";
import { useInView } from "react-intersection-observer";
import LoadingSmallCard from "components/ui/Skeleton/LoadingSmallCard";

const Categories = () => {
  const { selectedSortValue, sortOptions, search } = React.useContext(SortContext);
  const selectedOption = sortOptions.find((option) => option.id == selectedSortValue);
  const selectedSortBY = selectedOption?.sortBy;
  const selectedSortOrder = selectedOption?.order;

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isError, error } = useCategories({
    limit: 30,
    sortBy: selectedSortBY,
    order: selectedSortOrder,
    searchTerm: search,
  });

  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 1.0,
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  if (isLoading) return <LoadingSmallCard count={20} />;
  if (isError) return <div>Error: {error.message}</div>;

  return (
    <>
      <Grid
        container
        // spacing={2}
        // rowSpacing={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 3 }}
        // columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 3 }}
        // rowGap={{ lg: 1 }}
        // columnGap={{ lg: 1.2 }}
        // rowGap={{ lg: 4 }}
        // columnGap={{ lg: 4 }}
        // justifyContent="flex-start"
        // alignItems="center"
        className="Grid-padding"
        direction="row"
        justifyContent="flex-start"
        sx={{ flex: "1 1 1", display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "30px", rowGap: "32px" }}
      >
        {data?.pages?.map((page) =>
          page?.categories?.map((category, _ind) => (
            <Grid
              item
              // xs={12} sm={12} md={4} lg={3} xl={2.3}
              key={category.category}
            >
              <Category category={category} />
            </Grid>
          ))
        )}
      </Grid>

      {isFetchingNextPage && <p style={{ color: "red" }}>Loading.......</p>}

      <div ref={ref} style={{ height: "20px", marginTop: "10px" }} />
    </>
  );
};

export default Categories;
