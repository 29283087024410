import { Box, Divider, Grid } from "@mui/material";
import { ReactComponent as Background } from "assets/icons/bg.svg";
import Activity from "components/ui/Activity/Activity";
import { useParams } from "react-router-dom";
import React from "react";
import apiClient from "lib/api-client";

// const activityData = [
//   {
//     day: "Today",
//     data: [
//       {
//         activity: {
//           id: "A",
//           actionType: "Edited",
//           date: "July 27, 2024",
//           time: "11:00 PM",
//           user: "Sneh Patel",
//           action: "added",
//           whatDo: "four new designs to the catalog",
//         },
//       },
//     ],
//   },
//   {
//     day: "Yesterday",
//     data: [
//       {
//         activity: {
//           id: "B",
//           actionType: "Viewed",
//           date: "July 26, 2024",
//           time: "3:06 PM",
//           user: "+91 8856049691",
//           action: "access",
//           whatDo: "catalog",
//         },
//       },
//       {
//         activity: {
//           id: "C",
//           actionType: "Copied",
//           date: "July 26, 2024",
//           time: "10:06 AM",
//           user: "Sneh Patel",
//           action: "generated",
//           whatDo: "catalog link",
//         },
//       },
//     ],
//   },
//   {
//     day: "25 - 07 - 24",
//     data: [
//       {
//         activity: {
//           id: "D",
//           actionType: "Created",
//           date: "July 25, 2024",
//           time: "10:06 AM",
//           user: "Sneh Patel",
//           action: "added",
//           whatDo: "catalog #560894 ( Tanishq Catalog )",
//         },
//       },
//     ],
//   },
// ];

const generatedActivityString = (data) => {
  return (
    <div style={{ color: "#8B8B8B", fontSize: "16px", fontFamily: "Karla", fontWeight: 400, lineHeight: "18.7px" }}>
      <sapn style={{ color: "#FFFFFF" }}>{data.actionType}:</sapn> On {data.date} at {data.time},{data.user}
      <sapn style={{ color: "#FFFFFF" }}> {data.action}</sapn> the {data.whatDo}
    </div>
  );
};

const CatalogueActivity = ({ activityId }) => {
  const { id } = useParams();
  const [activityData, setActivityData] = React.useState([]);

  const fetchData = async () => {
    try {
      const res = await apiClient.get(`/api/v1/logs/catalog-log/${id}`);
      console.log("Fetched Data:", res?.data?.activityData);
      return res?.data?.activityData || [];
    } catch (error) {
      console.error("Error fetching activity data:", error);
      return [];
    }
  };

  React.useEffect(() => {
    const getLogs = async () => {
      const logsData = await fetchData();
      setActivityData(logsData);
    };
    getLogs();
  }, []);

  return (
    <>
      {activityData?.length > 0 ? (
        <Activity activityData={activityData} generatedActivityString={generatedActivityString} />
      ) : (
        <div style={{ display: "flex", textAlign: "center", padding: "40px", justifyContent: "center", alignItems: "center" }}>
          <Background />
        </div>
      )}
    </>
  );
};

export default CatalogueActivity;
