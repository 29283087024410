// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .product .right {
  width: 100%;
}

.product .content {
  display: flex;
  align-items: center;
  height: 40px;
}
.product .content .btn {
  margin-left: auto;
}

.product .left {
  position: relative;
  display: inline-block;
}
.product .left img {
  display: block;
  width: 150px;
  height: 153px;
}
.product .left button {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: "#FFFFFF";
  color: #50c878;
  font-weight: 500;
  font-size: 18px;
  text-transform: none;
  width: 110px;
  padding: 2px;
  border-radius: 5px;
  height: 40px;
}

.tray-heading {
  font-size: 22px;
  font-weight: 500;
  font-family: "Poppins";
  text-align: center;
  margin: 0;
  color: black;
} */
`, "",{"version":3,"sources":["webpack://./src/features/collection/components/CollectionProductScreen/collectionproduct.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GA6CG","sourcesContent":["/* .product .right {\n  width: 100%;\n}\n\n.product .content {\n  display: flex;\n  align-items: center;\n  height: 40px;\n}\n.product .content .btn {\n  margin-left: auto;\n}\n\n.product .left {\n  position: relative;\n  display: inline-block;\n}\n.product .left img {\n  display: block;\n  width: 150px;\n  height: 153px;\n}\n.product .left button {\n  position: absolute;\n  bottom: -10px;\n  left: 50%;\n  transform: translateX(-50%);\n  background-color: \"#FFFFFF\";\n  color: #50c878;\n  font-weight: 500;\n  font-size: 18px;\n  text-transform: none;\n  width: 110px;\n  padding: 2px;\n  border-radius: 5px;\n  height: 40px;\n}\n\n.tray-heading {\n  font-size: 22px;\n  font-weight: 500;\n  font-family: \"Poppins\";\n  text-align: center;\n  margin: 0;\n  color: black;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
