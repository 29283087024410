import React, { createContext, useState, useContext } from "react";

const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const [backendFilterValues, setBackendFilterValues] = useState({
    Labour: [],
    Class: [],
    "Diamond Pieces": [],
    "Diamond Weight": [],
    "Gold Gross-Weight": [],
    "Gold Net-Weight": [],
    "Design In Collection": [],
    "Design In Sets": [],
  });

  const [filterValues, setFilterValues] = useState({
    Labour: [],
    Class: [],
    "Diamond Pieces": [],
    "Diamond Weight": [],
    "Gold Gross-Weight": [],
    "Gold Net-Weight": [],
    "Design In Collection": [],
    "Design In Sets": [],
  });

  const [isFilter, setFilter] = useState(false);

  return <FilterContext.Provider value={{ filterValues, setFilterValues, isFilter, setFilter, backendFilterValues, setBackendFilterValues }}>{children}</FilterContext.Provider>;
};

export const useFilter = () => useContext(FilterContext);
