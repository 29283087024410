import request from "lib/axio-utilis";

const catalogReportsAPIs = {
  //clinet -report page API:-
  getCatalogReportStyles: async ({ queryKey, pageParam = 1 }) => {
    const { limit, sortBy, serarch, sortType, catalogId } = queryKey[1];
    const requestData = {
      page: pageParam,
      limit: limit,
      catalogId: catalogId,
      sortBy: sortBy,
      sortType: sortType,
      serarch: serarch,
    };

    const response = await request({ url: "/api/v1/catalogSetting/catalog-report", method: "post", data: requestData });

    if (response.status !== 200) {
      throw new Error("Error fetching Client Session information.");
    }

    return {
      reportStyles: response.data.catalogReports,
      nextPage: response.data.currentPage,
      totalPage: response.data.totalPages,
    };
  },

  getAllTotalTimeSpentOnCatalogByClient: async ({ queryKey, pageParam = 1 }) => {
    const { limit, catalogId } = queryKey[1];
    const requestData = {
      page: pageParam,
      limit: limit,
      catalogId: catalogId,
    };

    const response = await request({ url: "/api/v1/catalogSetting/catalog-report/getAllTotalTimeSpentOnCatalogByClient", method: "post", data: requestData });

    if (response.status !== 200) {
      throw new Error("Error fetching Catalog Time Report Information.");
    }

    return {
      report: response.data.clients,
      totalTimeSpent: response.data.totalTimeSpentOnCatalog,
      nextPage: response.data.currentPage,
      totalPage: response.data.totalPages,
    };
  },

  getCatalogReportKeyData: async ({ queryKey, pageParam = 1 }) => {
    const { limit, catalogId, styleId, key } = queryKey[1];
    const requestData = {
      page: pageParam,
      limit: limit,
      catalogId: catalogId,
      styleId: styleId,
      key: key,
    };

    const response = await request({ url: "/api/v1/catalogSetting/catalog-report/getFacetCatalogReportStylesTotalKeyCount", method: "post", data: requestData });

    if (response.status !== 200) {
      throw new Error(`Error fetching Catalog ${key} clinet data Information.`);
    }

    return {
      report: response.data.clients,
      totalCount: response.data[`total${key}s`],
      nextPage: response.data.currentPage,
      totalPage: response.data.totalPages,
    };
  },
};

export default catalogReportsAPIs;
