const capitalizeFirstLetter = (word) => {
  if (!word) return ""; // Handle empty or null values
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

const formatSubName = (word) => {
  let wordData = word.split(" ");
  let newWord = "";
  wordData.forEach((element, index) => {
    newWord += capitalizeFirstLetter(element);
    newWord += index === wordData.length - 1 ? "" : " ";
  });
  return newWord;
};

const formatOwenrWithDate = (catalog) => {
  const options = {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formattedDate = new Date(catalog.createdAt).toLocaleString("en-US", options);
  const replaceDate = formattedDate.replace(/\//g, "-");
  const ownerName = `${capitalizeFirstLetter(catalog.userDetails?.firstName) || ""} ${capitalizeFirstLetter(catalog.userDetails?.lastName) || ""}`.trim() || "Unknown Owner";
  return `${replaceDate}, ${ownerName}`;
  //   return "05-06-24, 1:56 PM, Sneh Patel";
};

export const returnCatalogCard = (catalog) => {
  return {
    id: catalog._id,
    number: `#${catalog.catalogNumber}`,
    subname: formatSubName(catalog.catalogName),
    owner: formatOwenrWithDate(catalog),
    isArchive: catalog.isArchive,
    profile: catalog?.userDetails?.profileImage || null,
    iconsData: [
      {
        title: "designCount",
        count: catalog.numberOfStyles,
      },
      {
        title: "linkCount",
        count: 0,
      },
      {
        title: "viewCount",
        count: catalog.views,
      },
    ],
  };
};
