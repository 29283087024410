const ordersDetail = [
  {
    _id: 1,
    designNo: "NS-00410",
    diamondQty: "VVS-EF",
    diamondWP: "300/0.995",
    metalQty_Color: "14-P",
    goldWeight: "6.59/6.25",
    size: "41 Cm",
    labour: "R",
  },
  {
    _id: 2,
    designNo: "NS-00410",
    diamondQty: "VVS-EF",
    diamondWP: "300/0.995",
    metalQty_Color: "14-P",
    goldWeight: "6.59/6.25",
    size: "41 Cm",
    labour: "R",
  },
  {
    _id: 3,
    designNo: "NS-00410",
    diamondQty: "VVS-EF",
    diamondWP: "300/0.995",
    metalQty_Color: "14-P",
    goldWeight: "6.59/6.25",
    size: "41 Cm",
    labour: "R",
  },
  {
    _id: 4,
    designNo: "NS-00410",
    diamondQty: "VVS-EF",
    diamondWP: "300/0.995",
    metalQty_Color: "14-P",
    goldWeight: "6.59/6.25",
    size: "41 Cm",
    labour: "R",
  },
];
export const getOrderDetails = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(ordersDetail);
    }, 2000);
  });
};
