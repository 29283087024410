import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import config from "configs/config";
import apiClient from "lib/api-client";

const craeteFun = (data) => {
  return apiClient.post(`${config.baseUrl}/api/v1/collection/createCollection`, data);
};

const useCreateCollection = () => {
  return useMutation({
    mutationFn: craeteFun,
  });
};

export default useCreateCollection;
