import { useMutation, useQueryClient } from "@tanstack/react-query";
import usersAPI from "features/Setting/users/api/userApis";

const useEditUser = () => {
  const quaryClient = useQueryClient();
  return useMutation({
    mutationFn: usersAPI.EditUserInfo,
    onSuccess: (data) => {
      if (data?.data?.message === "User Profile Updated Successfully") {
        quaryClient.setQueryData(["users-data"], (oldQueryData) => {
          return {
            ...oldQueryData,
            data: {
              ...oldQueryData.data,
              message: data?.data?.message,
              User: oldQueryData.data.User.map((user) => (user._id === data?.data?.user?._id ? data?.data?.user : user)),
            },
          };
        });
      } else {
        throw new Error(data?.data?.message || "An unexpected error occurred");
      }
    },
    onError: (error) => {
      throw new Error(error?.response?.data?.message || error?.message || "An unexpected error occurred");
    },
  });
};

export default useEditUser;
