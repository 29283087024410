import React from "react";

function SeparatorIcon() {
  return (
    <div style={{ padding: "0px 15px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
      <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L7 7L0.999999 13" stroke="#8B8B8B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
  );
}

export default SeparatorIcon;
