import bgLogo from "../assets/icons/bg_login.svg";
const { createTheme } = require("@mui/material");

// Define the custom theme
const customTheme = createTheme({
  spacing: 10,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundImage: `url(${bgLogo})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          height: "100vh",
          width: "100vw",
          margin: 0,
          padding: 0,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          width: "100%",
          display: "flex",
          justifyContent: "center",
          color: "white",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#D9D9D9", // Default color
          "&.Mui-checked": {
            color: "#D9D9D9", // Checked color
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: "#FFFFFF",
          fontFamily: "Poppins",
          fontWeight: 400,
          fontSize: "18px",
          lineHeight: "27px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFFFFF",
          color: "#000000",
          fontSize: "18px",
          lineHeight: "27px",
          fontWeight: 600,
          height: "48px",
          fontFamily: "Poppins",
          borderRadius: "10px",
          "&:hover": {
            backgroundColor: "#FFFFFF",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "--TextField-brandBorderColor": "#8B8B8B",
          "--TextField-brandBorderHoverColor": "#8B8B8B",
          "--TextField-brandBorderFocusedColor": "#8B8B8B",
          "& .MuiInput-underline:before": {
            borderBottom: "2px solid var(--TextField-brandBorderColor)",
          },
          "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
          },
          "& .MuiInput-underline:after": {
            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
          },
          "& .MuiInputLabel-root": {
            color: "#8B8B8B",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "27px",
            fontFamily: "Poppins",
          },
          "& .MuiInputLabel-root.Mui-focused, & .MuiInputLabel-root.MuiInputLabel-shrink": {
            display: "none",
          },
          "& .MuiInputBase-input": {
            color: "#FFFFFF",
            fontFamily: "Poppins",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "27px",
          },
          "& .MuiInputBase-input::placeholder": {
            color: "grey",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: "#E77B7B",
          fontFamily: "Poppins",
          fontWeight: 400,
          fontSize: "18px",
          lineHeight: "27px",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "#FFFFFF",
          fontWeight: 400,
          fontSize: "18px",
          lineHeight: "27px",
          padding: "10px",
          "&:hover": {
            color: "#FFFFFF",
          },
        },
      },
    },
  },
});

export default customTheme;
