import React from "react";
import Modal from "../Modal";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import Button from "../Button";

function RemoveModal({ open, setOpen, btnInfo, heading }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleClose = () => setOpen(false);
  return (
    <>
      <Modal open={open} handleClose={handleClose} style={{ width: isSmallScreen ? "auto" : "420px" }}>
        <Grid container gap={2} width="100%">
          <Grid item lg={12} width="100%" sx={{ textAlign: isSmallScreen ? "center" : "center" }}>
            <Typography sx={{ fontFamily: "Poppins", fontWeight: 500, fontSize: "18px", lineHeight: "22px" }}>{heading}</Typography>
          </Grid>
          <Grid item lg={12} width="100%">
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }} gap={6}>
              {btnInfo?.map((btn, index) => (
                <Box key={btn.btnName} width={"100%"}>
                  <Button
                    sx={{
                      width: "100%",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "22px",
                      height: "50px",
                      borderRadius: "5px",
                      color: "#000000",
                      marginTop: "20px",
                      textTransform: "none",
                      border: `1px solid ${btn.bgColor}`,
                      "&:hover": {
                        backgroundColor: btn.bgColor,
                      },
                      backgroundColor: btn.bgColor,
                    }}
                    onClick={btn.click}
                  >
                    {btn.btnName}
                  </Button>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}

export default RemoveModal;
