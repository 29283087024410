import { AppBar, Box, Divider, Toolbar } from "@mui/material";
import Header from "components/Header/Header";
import Sidebar from "components/Sidebar/Siderbar";
import BreadCrumbComponent from "components/ui/BreadCrumb";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import SettingChildHeader from "./SettingChildHeader";
import { usePermissions } from "context/PermissionsContext";
import { findSpecificPage, findSpecificSubPage, hasAccessPage, hasAccessSubPage } from "utils/checkUserRigths";
import { settingsRoutes } from "constants/constants";

const SettingLayoutOne = ({ icon }) => {
  console.log("----------- Inside Settting Layout ------------");
  const permissions = usePermissions();

  const [hasAccess, setHasAccess] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const basePath = location.pathname.split("/")[1];
    const subPath = location.pathname.split("/")[2];

    if (basePath === "Setting" && subPath) {
      const findPage = findSpecificPage("Settings", permissions);
      if (permissions && findPage) {
        const checkPath = settingsRoutes.find((item) => item.path === subPath)?.check;
        if (checkPath !== "Orders" && checkPath !== "Rights" && checkPath !== "Limits") {
          const accessGranted = hasAccessSubPage(findSpecificSubPage(findPage, checkPath), "view");
          setHasAccess(accessGranted);
        }
      }
    }
  }, [location.pathname, permissions, hasAccess]);

  useEffect(() => {
    return () => setHasAccess(true);
  }, [location.pathname, permissions]);

  return (
    <>
      <div style={{ position: "fixed", color: "white", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <div style={{ height: "100vh", width: "auto" }}>
          <Sidebar />
        </div>
        <div style={{ padding: "0.5rem", paddingLeft: "0rem", paddingRight: "0rem", paddingTop: "0.5rem", height: "100vh", width: "100%" }}>
          <div style={{ height: "auto" }}>
            <div position="fixed" elevation={0} sx={{ backgroundColor: "#000" }}>
              <Toolbar></Toolbar>
            </div>
          </div>

          {hasAccess ? (
            <div>
              <div style={{ height: "auto" }}>
                <SettingChildHeader icon={icon} />
              </div>
              <div
                style={{
                  height: "auto",
                  paddingLeft: "15px",
                  paddingRight: "10px",
                  paddingTop: "0px",
                }}
              >
                <Outlet />
              </div>
            </div>
          ) : (
            <p style={{ color: "red" }}>Access Denide.</p>
          )}
        </div>
      </div>

      {/* <div className="main-layout">
        <AppBar position="fixed" elevation={0} sx={{ backgroundColor: "#000", padding: "0px 0px 6px 0px" }}>
          <Toolbar></Toolbar>
        </AppBar>
        <SettingChildHeader icon={icon} />
        <div className="main-body">
          <Sidebar />
          <div className="content" style={{ padding: "0px 0px 0px 10px" }}>
            <Outlet />
          </div>
        </div>
      </div> */}
    </>
  );
};

export default SettingLayoutOne;
