// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.splash-container {
  display: none;
}

.logo {
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
  height: 100vh; /* Full viewport height */
  text-align: center;
}

.logo img {
  max-width: 100%;
  height: auto;
  width: 400px;
}

.logo svg {
  max-width: 100%;
  height: auto;
  width: 400px;
}
`, "",{"version":3,"sources":["webpack://./src/features/auth/styles/splashScreen.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,uBAAuB,EAAE,wBAAwB;EACjD,mBAAmB,EAAE,sBAAsB;EAC3C,aAAa,EAAE,yBAAyB;EACxC,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,YAAY;AACd","sourcesContent":[".splash-container {\n  display: none;\n}\n\n.logo {\n  display: flex;\n  justify-content: center; /* Horizontally center */\n  align-items: center; /* Vertically center */\n  height: 100vh; /* Full viewport height */\n  text-align: center;\n}\n\n.logo img {\n  max-width: 100%;\n  height: auto;\n  width: 400px;\n}\n\n.logo svg {\n  max-width: 100%;\n  height: auto;\n  width: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
