import SortModal from "components/ui/SortModal";
import SortContext from "context/SortContext";
import React, { useEffect } from "react";

const list = [
  { id: 1, name: "Design views (Low to High)", sortBy: "view", sortType: "asc" },
  { id: 2, name: "Design Views (High to Low)", sortBy: "view", sortType: "desc" },
  { id: 3, name: "Design Orders (Low to High)", sortBy: "cart", sortType: "asc" },
  { id: 4, name: "Design Orders (High to Low)", sortBy: "cart", sortType: "desc" },
  { id: 5, name: "Design Cart Removal (Low to High)", sortBy: "removeCart", sortType: "asc" },
  { id: 6, name: "Design Cart Removal (High to Low)", sortBy: "removeCart", sortType: "desc" },
  { id: 7, name: "Time Spend on Design (Low to High)", sortBy: "clock", sortType: "asc" },
  { id: 8, name: "Time Spend on Design (High to Low)", sortBy: "clock", sortType: "desc" },
];

const CatalogClinetSortModal = ({ open, setOpen }) => {
  const { sortOptions, setSortOptions } = React.useContext(SortContext);

  useEffect(() => {
    setSortOptions(list);
  }, []);

  return <SortModal open={open} handleClose={() => setOpen(false)} title="Sort By" list={sortOptions || []} />;
};

export default CatalogClinetSortModal;
