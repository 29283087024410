import React from "react";

function NotificationIcon({ color }) {
  return (
    <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.7502 19.6667V21C14.7502 23.2091 13.0713 25 11.0002 25C8.92907 25 7.25011 23.2091 7.25011 21V19.6667M14.7502 19.6667H7.25011M14.7502 19.6667H19.2384C19.7166 19.6667 19.9568 19.6667 20.1505 19.597C20.5203 19.4639 20.8097 19.1542 20.9344 18.7597C21 18.5524 21 18.2953 21 17.7813C21 17.5563 20.9998 17.4438 20.9833 17.3366C20.9521 17.1339 20.8783 16.9418 20.766 16.775C20.7067 16.6868 20.6313 16.6064 20.4826 16.4478L19.9957 15.9284C19.8386 15.7608 19.7503 15.5335 19.7503 15.2965V10.3333C19.7503 5.17867 15.8328 0.999987 11.0002 1C6.16761 1.00001 2.25002 5.17869 2.25002 10.3333V15.2966C2.25002 15.5336 2.16158 15.7608 2.00448 15.9284L1.51759 16.4477C1.36845 16.6068 1.29381 16.6867 1.23438 16.775C1.12207 16.9418 1.04768 17.1339 1.0165 17.3366C1 17.4438 1 17.5563 1 17.7813C1 18.2953 1 18.5523 1.06557 18.7597C1.19032 19.1541 1.48101 19.4639 1.85084 19.597C2.04447 19.6667 2.28379 19.6667 2.76198 19.6667H7.25011"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default NotificationIcon;
