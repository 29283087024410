// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes vibrate {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-2px);
  }
  40% {
    transform: translateX(2px);
  }
  60% {
    transform: translateX(-2px);
  }
  80% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0);
  }
}

.vibrate {
  animation: vibrate 0.5s linear infinite;
}
`, "",{"version":3,"sources":["webpack://./src/layouts/SettingLayout/stylechild.css"],"names":[],"mappings":"AAAA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,2BAA2B;EAC7B;EACA;IACE,0BAA0B;EAC5B;EACA;IACE,2BAA2B;EAC7B;EACA;IACE,0BAA0B;EAC5B;EACA;IACE,wBAAwB;EAC1B;AACF;;AAEA;EACE,uCAAuC;AACzC","sourcesContent":["@keyframes vibrate {\n  0% {\n    transform: translateX(0);\n  }\n  20% {\n    transform: translateX(-2px);\n  }\n  40% {\n    transform: translateX(2px);\n  }\n  60% {\n    transform: translateX(-2px);\n  }\n  80% {\n    transform: translateX(2px);\n  }\n  100% {\n    transform: translateX(0);\n  }\n}\n\n.vibrate {\n  animation: vibrate 0.5s linear infinite;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
