import SortContext from "context/SortContext";
import React from "react";
import CollectionScreen from "features/collection/components/CollectionScreen/CollectionScreen";

function Collection() {
  const { setSortOptions, setSelectedSortValue } = React.useContext(SortContext);

  React.useEffect(() => {
    setSortOptions([
      { id: 1, name: "Alphabetical Order (A to Z) " },
      { id: 2, name: "Alphabetical Order (Z to A)" },
      { id: 3, name: "Design Quantity (High to Low)" },
      { id: 4, name: "Design Quantity (Low to High)" },
      { id: 5, name: "Collection Date (Oldest to Newest)" },
      { id: 6, name: "Collection Date (Newest to Oldest)" },
    ]);

    return () => {
      setSortOptions([]);
      setSelectedSortValue(null);
    };
  }, []);

  return <>{<CollectionScreen />}</>;
}

export default Collection;
