import { ReactComponent as GoldIcon } from "assets/filter/gold.svg";
import { ReactComponent as SilverIcon } from "assets/filter/silver.svg";
import { ReactComponent as BronzeIcon } from "assets/filter/bronze.svg";

const renderIcon = (item) => {
  switch (item) {
    case "Gold":
      return <GoldIcon />;
    case "Silver":
      return <SilverIcon />;
    case "Bronze":
      return <BronzeIcon />;
    default:
      return null;
  }
};

export default renderIcon;
