const ordersData = [
  {
    _id: 1,
    orderNumber: "#000001",
    date: "04/08/24 - 05:38:56 PM",
    mob: "+91 8856049691",
    clientName: "dholakiya jewels",
    catalogNo: "#560852",
    catalogName: "Tanshiq Catalog",
    desings: 34,
  },
  {
    _id: 2,
    orderNumber: "#000001",
    date: "04/08/24 - 05:38:56 PM",
    mob: "+91 8856049691",
    clientName: "dholakiya jewels",
    catalogNo: "#560852",
    catalogName: "Tanshiq Catalog",
    desings: 34,
  },
  {
    _id: 3,
    orderNumber: "#000001",
    date: "04/08/24 - 05:38:56 PM",
    mob: "+91 8856049691",
    clientName: "dholakiya jewels",
    catalogNo: "#560852",
    catalogName: "Tanshiq Catalog",
    desings: 34,
  },
  {
    _id: 4,
    orderNumber: "#000001",
    date: "04/08/24 - 05:38:56 PM",
    mob: "+91 8856049691",
    clientName: "dholakiya jewels",
    catalogNo: "#560852",
    catalogName: "Tanshiq Catalog",
    desings: 34,
  },
];
export const getOrders = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(ordersData);
    }, 2000);
  });
};
