const { createTheme, colors } = require("@mui/material");

const customThems = createTheme({
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {
          color: "black",
          gap: "10px",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        Input: {
          color: "#FFFFFF",
          fontFamily: "Poppins",
          fontWeight: 400,
          fontSize: "18px",
          lineHeight: "27px",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: "Poppins",
          fontWeight: 400,
          fontSize: "20px",
          lineHeight: "22px",
          color: "#000000",
          marginBottom: "15px",
          "&.Mui-focused ": {
            color: "#000000", // Border color when focused
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          display: "none",
        },
        select: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid #000000",
          borderRadius: "5px",
          width: "100%",
          padding: "15px 32px", // Adjust padding as needed
        },
        root: {
          textAlign: "center",
          color: "#000000",
          border: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontFamily: "Karla",
          fontSize: "18px",
          lineHeight: "22px",
          fontWeight: 400,
          "&.Mui-focused": {
            borderColor: "#000000",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          border: "1px solid #000000",
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          height: "50px",
        },
        notchedOutline: {
          borderColor: "transparent",
        },
        input: {
          fontFamily: "Poppins",
          fontWeight: 400,
          fontSize: "18px",
          lineHeight: "27px",
          color: "#000000",
          textAlign: "center",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Karla",
          fontWeight: 400,
          fontSize: "18px",
          lineHeight: "22px",
          height: "50px",
          borderRadius: "5px",
          color: "#000000",
          marginTop: "20px",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "#50C878",
          },
          backgroundColor: "#50C878",
        },
      },
    },
  },
});

export default customThems;
