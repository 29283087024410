import { useGlobalContext } from "context/GlobalContext";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MenuItem, Select } from "@mui/material";
import Button from "components/ui/Button";

function CreateBtn({ location }) {
  const { createBtn, creatBtnName, createBtnOption } = useGlobalContext();
  const [selectValue, setSelectValue] = useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const findBackPath = (path) => {
    if (!path) return "";

    const data = path.split("/");
    const isPresent = "/" + data.filter((item) => item === "Catalog" || item === "Sets" || item === "Collection")[0];

    return isPresent;
  };

  const handleChageFun = (event, isBtn = false) => {
    const option = event.target.value || event.target.getAttribute("value");
    if (!isBtn) {
      setSelectValue(option);
    }
    let state = {
      innerChnages: true,
      backPath: location.pathname,
    };

    switch (findBackPath(location?.pathname)) {
      case "/Catalog": {
        if (location?.state?.edit) {
          state = {
            innerChnages: true,
            backPath: location?.pathname,
            backName: location?.state?.name,
            catalogueName: location?.state?.catalogueName,
            openCart: false,
            cataloguUpdate: true,
            collection: false,
          };
        } else {
          state = {
            ...state,
            newName: "New Catalog",
            collection: false,
          };
        }
        break;
      }
      case "/Collection": {
        state = {
          ...state,
          newName: "New Collection",
          collection: true,
        };
        break;
      }
      case "/Sets": {
        state = {
          ...state,
          newName: "New Set",
          collection: false,
        };
        break;
      }
      default: {
        state = {
          ...location.state,
          openCart: false,
        };
      }
    }
    navigate(option, { state });
  };

  useEffect(() => {
    setSelectValue("");
  }, [creatBtnName]);

  return createBtn && createBtnOption?.length > 1 && createBtnOption.some((item) => item.view) ? (
    <div className="child-header-create-btn">
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        fullWidth
        value={selectValue}
        onChange={(e) => handleChageFun(e, false)}
        displayEmpty
        renderValue={(selected) => {
          if (selected === "") {
            return <span>{creatBtnName}</span>;
          }
          return selected;
        }}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        open={open}
        MenuProps={{
          PaperProps: {
            sx: {
              marginLeft: creatBtnName === "Add Design" ? (location?.state?.edit ? "-17px" : "-12.5px") : "none",
              padding: "0px 5px",
              marginTop: "3px",
              backgroundColor: "#FFFFFF",
              color: "#000000",
              borderRadius: "5px",
              "& .MuiList-root": {
                "& .MuiMenuItem-root": {
                  margin: "2px",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  "&:hover": {
                    backgroundColor: "#444444",
                    color: "#FFFFFF",
                    borderRadius: "5px",
                    margin: "2px",
                  },
                },
              },
            },
          },
        }}
        sx={{
          fontFamily: "Poppins",
          fontSize: "14px",
          lineHeight: "22px",
          fontWeight: 500,
          height: "41px",
          borderRadius: "5px",
          textTransform: "none",
          color: open ? "#000000" : "#FFFFFF", // Change text color based on open state
          backgroundColor: open ? "#FFFFFF" : "#000000",
          "&:hover": {
            backgroundColor: "#FFFFFF",
            color: "#000000",
          },
          border: "1px solid #FFFFFF",
          width: creatBtnName === "Add Design" ? "auto" : "160px",
          padding: creatBtnName === "Add Design" ? "none" : "0px 10px",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          "& .MuiSelect-select": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            padding: "10px !important",
          },
          "& .MuiSelect-icon": {
            display: "none",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&:focus": {
            backgroundColor: "transparent",
          },
        }}
      >
        {createBtnOption?.map(
          (item) =>
            item.view && (
              <MenuItem key={item.option} value={item.link}>
                {item.option}
              </MenuItem>
            )
        )}
      </Select>
    </div>
  ) : (
    createBtn && createBtnOption.some((item) => item.view) && (
      <div className="child-header-create-btn">
        <Button
          sx={{
            fontFamily: "Poppins",
            fontSize: "14px",
            lineHeight: "22px",
            fontWeight: 500,
            height: "41px",
            width: creatBtnName === "Add Design" ? "auto" : "160px",
            padding: creatBtnName === "Add Design" ? "none" : "0px 10px",

            borderRadius: "5px",
            textTransform: "none",
            color: open ? "#000000" : "#FFFFFF", // Change text color based on open state
            backgroundColor: open ? "#FFFFFF" : "#000000",
            border: "1px solid #FFFFFF",

            "&:hover": {
              backgroundColor: "#FFFFFF",
              color: "#000000",
            },
          }}
          onClick={(e) => handleChageFun(e, true)}
          value={createBtnOption?.[0]?.link}
        >
          {creatBtnName}
        </Button>
      </div>
    )
  );
}

export default CreateBtn;
