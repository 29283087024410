import { useMutation, useQuery } from "@tanstack/react-query";
import apiClient from "lib/api-client";
import { getUserDetail } from "utils/getUserDetailsHelper";
import { toast } from "react-toastify";

const changePassword = async ({ oldPassword, newPassword }) => {
  const user = getUserDetail("_id");
  const body = {
    userId: user,
    oldPassword: oldPassword,
    newPassword: newPassword,
    confirmPassword: newPassword,
  };

  const response = await apiClient.post(`/api/v1/users/changePassword`, body);
  return response.data;
};

export const useChangePassword = ({ onCompleted }) => {
  return useMutation({
    mutationFn: changePassword,
    onSuccess: (data) => {
      if (data.status === 200) {
        toast.success(data.message);
        onCompleted();
      } else {
        toast.error(data.message);
      }
    },
    onError: (error) => {
      console.error("Error in changing password", error.message);
    },
  });
};
