import React, { useCallback, useEffect, useState } from "react";
import { Grid, Divider, Box } from "@mui/material";
import Modal from "../ui/Modal";
import ProductCard from "./ProductCard";
import { useLocation, useNavigate } from "react-router-dom";
import { useGlobalContext } from "context/GlobalContext";
import { useGetDesigns } from "features/set/api/get-designs";
import { transformDataForTrayModal } from "utils/transformdata";
import { useRemoveDesings } from "features/set/hooks/useRemoveDesigns";
import RemoveModal from "./RemoveModal/RemoveModal";
import DropdownBtn from "features/set/components/DropdownBtn";

const cardStyle = {
  backgroundColor: "#fff",
  color: "#000",
  boxShadow: "none",
  padding: "0px",
};

function TrayModal({ cardId, open, setOpen, isView, innerChnages, isAdded, currentSet }) {
  const location = useLocation();
  const { setCountCart } = useGlobalContext();
  const [selectedCartProducts, setSelectedCartProducts] = useState({});
  const [btnTiltes, setBtnTitles] = useState({});
  const [presentInCatalog, setPresentInCatalog] = useState({});
  const [removeDesignModal, setRemoveDesignModal] = useState(false);
  const [modalBtnInfo, setModalBtnInfo] = React.useState([]);

  const { handleRemoveDesign } = useRemoveDesings();

  //remove the design from set in the react query cache
  const openRemoveModal = useCallback(
    (designId, setId) => {
      const dynamicBtnInfo = [
        {
          btnName: "Remove",
          bgColor: "#E77B7B",
          click: () => {
            handleRemoveDesign(designId, setId);
            setRemoveDesignModal(false);
          },
        },
        {
          btnName: "Keep",
          bgColor: "#50C878",
          click: () => setRemoveDesignModal(false),
        },
      ];

      setModalBtnInfo(dynamicBtnInfo);
      setRemoveDesignModal(true); // Open the modal
    },
    [handleRemoveDesign]
  );

  const addSetToCart = (set) => {
    setSelectedCartProducts(set);
    setBtnTitles((preTitle) => ({
      ...preTitle,
      [set._id]: "Added",
    }));
  };

  //chetan nagmoti
  const removeSetToCart = (set) => {
    setBtnTitles((preTitle) => ({
      ...preTitle,
      [set._id]: "Add",
    }));
    let getCartData = JSON.parse(sessionStorage.getItem("CartData")) || [];
    getCartData = getCartData.filter((item) => (item.id || item._id) !== set._id);
    sessionStorage.setItem("CartData", JSON.stringify(getCartData));
    setCountCart((prevCount) => prevCount - 1);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //fetch the products
  const { data, error, isLoading } = useGetDesigns(cardId.id);

  //chetan nagmoti
  // useEffect(() => {
  //   let getCartData = JSON.parse(sessionStorage.getItem("CartData")) || [];
  //   if (currentSet && currentSet.length > 0) {
  //     currentSet?.forEach((item) => {
  //       const isAdded = getCartData.some((cartItem) => (cartItem._id || cartItem.id) === (item.id || item._id));

  //       setBtnTitles((prevTitles) => ({
  //         ...prevTitles,
  //         [item._id]: isAdded ? "Added" : "Add",
  //       }));
  //     });
  //   }
  // }, [currentSet, open]);

  // useEffect(() => {
  //   const updateCatalogProductsData = JSON.parse(sessionStorage.getItem("updateProductsData")) || [];
  //   if (updateCatalogProductsData) {
  //     if (currentSet && currentSet.length > 0) {
  //       currentSet?.forEach((item) => {
  //         const isAdded = updateCatalogProductsData.some((cartItem) => (cartItem._id || cartItem.id) === (item.id || item._id));

  //         setBtnTitles((prevTitles) => ({
  //           ...prevTitles,
  //           [item._id]: isAdded ? "Added" : "Add",
  //         }));

  //         setPresentInCatalog((pre) => ({
  //           ...pre,
  //           [item._id]: isAdded ? true : false,
  //         }));
  //       });
  //     }
  //   }
  // }, []);

  useEffect(() => {
    let getCartData = JSON.parse(sessionStorage.getItem("CartData")) || [];
    const updateCatalogProductsData = JSON.parse(sessionStorage.getItem("updateProductsData")) || [];

    if (currentSet && currentSet.length > 0) {
      currentSet?.forEach((item) => {
        const isAddedToCart = getCartData.some((cartItem) => (cartItem._id || cartItem.id) === (item.id || item._id));
        const isAddedToCatalog = updateCatalogProductsData.some((catalogItem) => (catalogItem._id || catalogItem.id) === (item.id || item._id));

        setBtnTitles((prevTitles) => ({
          ...prevTitles,
          [item._id]: isAddedToCart || isAddedToCatalog ? "Added" : "Add",
        }));

        setPresentInCatalog((prevCatalogStatus) => ({
          ...prevCatalogStatus,
          [item._id]: isAddedToCatalog,
        }));
      });
    }
  }, [currentSet, open]);

  if (isLoading) return <p style={{ color: "black" }}>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  // function handleNavigate(url) {
  //   navigate(`/${url}`, {
  //     state: {
  //       innerChnages: true,
  //       catalogueName: `SET - ${data?.setNumber}`, //catalogueName same as SETNAME
  //       setId: cardId.id,
  //       setUpdate: true,
  //       backPath: location?.pathname,
  //       backName: location?.state?.name,
  //       openCart: false,
  //     },
  //   });
  //   const desingsIds = data?.designs?.map((desing) => transformData(desing));
  //   sessionStorage.setItem("updateProductsData", JSON.stringify(desingsIds));
  // }

  return (
    <Modal open={open} handleClose={handleClose} style={{ width: "430px", bgcolor: "#fff" }}>
      <Grid container spacing={2} sx={{ position: "sticky", top: 0, zIndex: 1, bgcolor: "#fff" }}>
        <Grid item width="70%">
          <h7 className="tray-heading" style={{ fontSize: "23.5px", fontWeight: "500", fontFamily: "Poppins", textAlign: "center", margin: "0", color: "black" }}>
            Designs of SET-{data?.setNumber}
          </h7>
        </Grid>
        <Grid item width="30%">
          {!isView ? (
            <Grid className="tray-heading" container spacing={2} justifyContent="end">
              <Grid item>
                <DropdownBtn data={data} cardId={cardId} location={location} />
              </Grid>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Grid>

      <Box
        mt={2}
        sx={{
          height: "580px",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "10px",
            height: "0px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "transparent",
          },
        }}
      >
        {data &&
          data?.designs?.map((set, index) => {
            const transformedProduct = transformDataForTrayModal(set);
            return (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="product" key={set._id} width={"100%"}>
                <Box sx={{ padding: "30px 0" }}>
                  <ProductCard
                    data={transformedProduct}
                    addtoCart={() => addSetToCart(set)}
                    removeCart={
                      innerChnages ? () => removeSetToCart(set) : () => openRemoveModal(cardId.id, set._id)
                      // handleRemoveDesing(cardId.id, set._id)
                    }
                    selectedCartProducts={set}
                    cardStyle={cardStyle}
                    isBtn={!isView || innerChnages}
                    btnTilte={innerChnages ? (btnTiltes[set._id] && btnTiltes[set._id] == "Added" ? "Added" : "Add") : "Remove"}
                    presentInCatalog={presentInCatalog[set._id] === true ? true : false}
                    variant="black"
                  />
                </Box>

                {index !== data?.designs?.length - 1 && <Divider orientation="horizontal" flexItem style={{ borderColor: "#444444", width: "100%", height: "10px" }} />}
              </Grid>
            );
          })}
      </Box>

      <RemoveModal open={removeDesignModal} setOpen={setRemoveDesignModal} btnInfo={modalBtnInfo} heading="Do you want to Remove the Design?" />
    </Modal>
  );
}

export default TrayModal;
