const encodeStr = (str) => {
  return str
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w\-]+/g, "");
};

const decodeStr = (str) => {
  return str.replace(/-/g, " ").toLowerCase();
};

export { encodeStr, decodeStr };
