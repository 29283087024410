import React from "react";
import RenderCategories from "features/Category/category/components/Categeries";
import SortContext from "context/SortContext";

const Categories = () => {
  const { setSortOptions, setSelectedSortValue } = React.useContext(SortContext);

  React.useEffect(() => {
    setSortOptions([
      { id: 1, name: "Alphabetical Order (A to Z)", sortBy: "alphabetical", order: "asc" },
      { id: 2, name: "Alphabetical Order (Z to A)", sortBy: "alphabetical", order: "desc" },
      { id: 3, name: "Design Quantity (High to Low)", sortBy: "quantity", order: "desc" },
      { id: 4, name: "Design Quantity (Low to High)", sortBy: "quantity", order: "asc" },
    ]);

    return () => {
      setSortOptions([]);
      setSelectedSortValue(null);
    };
  }, []);

  return <RenderCategories />;
};

export default Categories;
