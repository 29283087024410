const { createTheme } = require("@mui/material");

const customTheme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#000000",
          fontFamily: "Poppins",
          fontWeight: 500,
          fontSize: "20px",
          lineHeight: "30px",
          "&.Mui-focused": {
            color: "#000000",
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          "& .MuiFormControlLabel-label": {
            color: "#000000",
            fontFamily: "Karla",
            fontSize: "18px",
            fontWeight: 400,
            lineHeight: "21.04px",
          },
          gap: "25px",
          padding: "10px",
          margin: "0px",
          paddingLeft: "0px",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          width: "20px",
          height: "20px",
          borderRadius: "6px",
          border: "1.5px solid black", // Ensure the border is prominent like a radio button
          padding: "1px",
          "& svg": {
            display: "none", // Hide the default checkbox icon
          },
          "&:before": {
            content: '"\\2714"', //right tick ark
            color: "white",
            fontSize: "12px",
          },
          "&.Mui-checked": {
            backgroundColor: "#50C878", // Background color for checked state
            border: "1.5px solid black",
            // "&:before": {
            //   border: "1px solid white", // Inner border for checked state
            // },
          },
          "&.Mui-focusVisible": {
            outline: "none",
            boxShadow: "none",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          width: "100%",
          fontFamily: "Poppins",
          fontWeight: 500,
          fontSize: "18px",
          lineHeight: "27px",
          height: "50px",
          borderRadius: "5px",
          color: "#000000",
          marginTop: "20px",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "red",
          },
          backgroundColor: "#50C878",
        },
      },
    },
  },
});

export default customTheme;
