import React from "react";
import styled from "styled-components";

const TimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  position: relative;
  max-width: 10000px;
  margin: 0 auto;
  color: #fff;
`;

const TimelineItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;

  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: -15px;
    top: 0;
    width: 12px;
    height: 12px;
    background-color: ${(props) => (props.isToday ? "#00ff00" : "#00ccff")}; /* Green dot for today, blue otherwise */
    border-radius: 50%;
    z-index: 1;
  }

  &:after {
    content: "";
    position: absolute;
    left: -11px;
    top: 13px;
    height: 1000%;
    width: 2px;
    background-color: #444;
    z-index: 0;
  }

  &:last-child:after {
    display: none;
  }
`;

const TimelineDate = styled.div`
  font-size: 14px;
  color: ${(props) => (props.isToday ? "#00ff00" : "#00ccff")}; /* Green for today, blue otherwise */
  margin-bottom: 8px;
  border: 1px solid grey;
  width: 100px;
  height: 25px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-left: none;
  padding-left: 10px;
`;

const TimelineContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -8.5px;
`;

const TimelineHeader = styled.div`
  font-weight: bold;
  color: #50c878;
  font-size: 16px;
  display: flex;
  gap: 10px;
  align-items: center;
  padding-left: 10px;
`;

const TimelineSubText = styled.div`
  color: #bbb;
  font-size: 14px;
  line-height: 1.5;
`;

const TimelineDivider = styled.hr`
  border: 1px solid #444;
  width: 100%;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: -8.5px;
`;

const items = [
  {
    date: "Today",
    header: "Edited :",
    description: "On July 27, 2024, Sneh Patel Added 4 New Designs in the catalog",
    isToday: true,
  },
  {
    date: "Yesterday",
    header: "Viewed :",
    description: "On July 27, 2024, 3:06 PM +91 8856049691 has 4 views of the catalog",
    isToday: false,
  },
  {
    date: "25-07-24",
    header: "Created :",
    description: "On July 25, 2024, Sneh Patel has Added the #560894 (Tanishq Catalog)",
    isToday: false,
  },
];

const ActivityPage = () => {
  return (
    <TimelineContainer>
      {items.map((item, index) => (
        <>
          <TimelineItem isToday={item.isToday}>
            <TimelineContent>
              <TimelineDate isToday={item.isToday}>{item.date}</TimelineDate>
              <TimelineHeader>
                {item.header}
                <TimelineSubText>{item.description}</TimelineSubText>
              </TimelineHeader>
            </TimelineContent>
          </TimelineItem>
          {index < items.length - 1 && <TimelineDivider />}
        </>
      ))}
    </TimelineContainer>
  );
};

export default ActivityPage;
