import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast as myToast } from "react-toastify";
import catalogClientAPIs from "../api/catalogClientAPIs";

export const useRevokeAccessCatalogClient = () => {
  const quaryClient = useQueryClient();
  return useMutation({
    mutationFn: catalogClientAPIs.revokeAccess,
    onSuccess: (response) => {
      const { data } = response;
      if (data?.status === 200) {
        quaryClient.invalidateQueries("catalogClientsActivityInfoData");
        myToast.success(data?.message || "Client Access Revoked Successfully in Catalog!", {
          position: "top-right",
          autoClose: 2000,
        });
      } else {
        myToast.error(`Error: ${data?.message || "Failed to Update Client Access Revoked in Catalog"}`, {
          position: "top-right",
          autoClose: 2000,
        });
      }
    },
    onError: (error) => {
      let errorMessage = error.response?.data?.message || error.message || "An error occurred";
      return myToast.error(`Error: ${errorMessage}`, {
        position: "top-right",
        autoClose: 2000,
      });
    },
  });
};
