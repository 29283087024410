import React from "react";

const ClinetTableRowSkeleton = ({ TableRow, Skeleton, StyledTableHeaderCell }) => {
  return (
    <TableRow>
      {Array.from({ length: 7 }, (_, colIndex) => (
        <StyledTableHeaderCell>
          <Skeleton variant="text" margin="auto" width="100%" height="auto" />
        </StyledTableHeaderCell>
      ))}
    </TableRow>
  );
};

export default ClinetTableRowSkeleton;
