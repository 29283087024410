import { IconButton } from "@mui/material";
import React from "react";
import { getIcon } from "utils/iconsConfig";

const GoToTop = () => {
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    isVisible && (
      <IconButton onClick={scrollToTop} sx={{ position: "fixed", bottom: "30px", right: "20px", zIndex: 1000 }}>
        {getIcon("GoToTop")}
      </IconButton>
    )
  );
};

export default GoToTop;
