import React, { useState } from "react";
import { Box, Button, Checkbox, Container, FormControlLabel, Grid, IconButton, InputAdornment } from "@mui/material";
import "../styles/loginForm.css";
import { Link, useNavigate } from "react-router-dom";
import CustomButton from "components/ui/Button";
import CustomTextField from "components/ui/TextField";
// import { ReactComponent as Logo } from "assets/icons/main_logo.svg";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlined from "@mui/icons-material/VisibilityOffOutlined";
import config from "configs/config";
import { forwordRouteAccordingToRights } from "utils/checkUserRigths";
import { toast } from "react-toastify";

// const navObject = [
//   { key: "Catalog", value: "/Catalog" },
//   { key: "Category", value: "/Category" },
//   { key: "Collection", value: "/Collection" },
//   { key: "Sets", value: "/Sets" },
//   { key: "Settings", value: "/Setting" },
// ];

// const forwordRouteAccordingToRights = (role) => {
//   if (!role?.isAdmin) {
//     let viewAccessArrya = role?.rights
//       .map((item) => {
//         if (navObject?.map((item) => item.key).includes(item.menu) && item.view) {
//           return {
//             menu: item.menu,
//             view: item.view,
//           };
//         }
//         return false;
//       })
//       .filter((item) => item);
//     let nextRoute = navObject?.find((item) => item.key === viewAccessArrya[0]?.menu)?.value || null;
//     return nextRoute;
//   }

//   return "/Catalog";
// };

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [forgotError, setForgotError] = useState(false);
  const navigate = useNavigate();

  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
    rememberMe: false,
  });

  const [error, setError] = useState({
    usernameError: "",
    passwordError: "",
  });

  const handleOnChangeEvent = (e) => {
    const { name, value, type, checked } = e.target;
    if (name == "password") {
      let password = loginData.password;
      if (!password) {
        setShowPassword(false);
      }
    }
    setError({
      usernameError: "",
      passwordError: "",
    });
    setForgotError(false);
    setLoginData((preData) => ({
      ...preData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleReset = () => {
    setError({
      usernameError: "",
      passwordError: "",
    });

    setForgotError(false);

    setLoginData({
      username: "",
      password: "",
      rememberMe: false,
    });
  };

  const handleSumbit = async () => {
    if (loginData.username === "") {
      setError((error) => ({
        ...error,
        usernameError: "Invalid Username",
      }));
    }
    if (loginData.password === "") {
      setError((error) => ({
        ...error,
        passwordError: "Invalid Password",
      }));
    }

    if (loginData.username !== "" && loginData.password !== "") {
      try {
        const res = await fetch(`${config.baseUrl}/api/v1/users/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(loginData),
        });
        const result = await res.json();
        if (result.status === 404) {
          return setError((error) => ({
            ...error,
            usernameError: "Invalid Username",
            // passwordError: "Invalid Password",
            passwordError: "",
          }));
        } else if (result.status === 401) {
          return setError((error) => ({
            ...error,
            passwordError: "Invalid Password",
          }));
        } else {
          handleReset();
          localStorage.setItem("userData", JSON.stringify(result));
          sessionStorage.setItem("userData", JSON.stringify(result));
          let nextRoute = forwordRouteAccordingToRights(result?.role);
          if (!nextRoute) {
            return toast.error(`Please Contact Admin`, {
              position: "top-right",
              autoClose: 2000,
            });
          }
          navigate(nextRoute);
          // window.location.assign("/Catalog");
        }
      } catch (error) {
        return setError((error) => ({
          ...error,
          usernameError: "Something Wrong",
          passwordError: "Something Wrong",
        }));
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSumbit();
    }
  };

  const handleForgotPassword = () => {
    setForgotError(true);
  };

  return (
    <Container maxWidth="xl">
      <Box className="login-box">
        {/* <Box className="logo-box">{<img src="/Logo.png" alt="Logo" />}</Box> */}
        <Box className="login-form" onKeyDown={handleKeyDown}>
          <Box className="custom-width" sx={error.usernameError ? { marginBottom: 0 } : { marginBottom: 2.5 }}>
            <CustomTextField
              fullWidth
              id="username"
              name="username"
              size="small"
              label="Username"
              variant="standard"
              placeholder="Enter Your Username"
              helperText={error.usernameError ? error.usernameError : ""}
              value={loginData.username}
              onChange={handleOnChangeEvent}
              autoComplete="off"
            />
          </Box>
          <Box className="custom-width" sx={error.passwordError ? { marginBottom: 0, marginTop: 2.5 } : { marginBottom: 2.5, marginTop: 2.5 }}>
            <CustomTextField
              fullWidth
              id="password"
              name="password"
              size="small"
              label="Password"
              variant="standard"
              placeholder="Enter Your Password"
              helperText={error.passwordError ? error.passwordError : ""}
              value={loginData.password}
              onChange={handleOnChangeEvent}
              type={showPassword ? "text" : "password"}
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton disableRipple disableFocusRipple aria-label="toggle password visibility" onClick={handleTogglePasswordVisibility} edge="end">
                      {loginData.password === "" ? "" : showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box className="custom-width" sx={{ marginBottom: 2.5, marginTop: 3 }}>
            <Grid container alignItems="center">
              <Grid item lg={6} display="flex" alignItems="center">
                <FormControlLabel
                  disableRipple
                  disableFocusRipple
                  control={<Checkbox name="rememberMe" checked={loginData.rememberMe} onChange={handleOnChangeEvent} disableRipple disableFocusRipple />}
                  label="Remember Me"
                />
              </Grid>
              <Grid item lg={6} display="flex" justifyContent="flex-end">
                <button type="button" className="custom-forgot-password" onClick={handleForgotPassword}>
                  Forget Password?
                </button>
              </Grid>
              {forgotError && <Grid className="forgot-password-error">Please contact the administrator to reset your password.</Grid>}
            </Grid>
          </Box>

          <Box className="custom-width" sx={{ marginTop: forgotError ? 3 : 3 }}>
            <CustomButton type="button" variant="contained" fullWidth disableElevation onClick={handleSumbit}>
              Log-In
            </CustomButton>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginForm;
