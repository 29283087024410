// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-layout {
  /* border: 2px solid rgb(17, 255, 0); */
  margin-top: 70px;
  margin-left: 103px;
}

.main-layout-left {
  /* border: 2px solid rgb(17, 255, 0); */
  margin-top: 70px;
}

.main-body {
  flex-grow: 1;
  padding: 0.5rem;
  padding-top: 0;
  /* border: 1px solid blue; */
}

.content {
  flex-grow: 1;
}
`, "",{"version":3,"sources":["webpack://./src/layouts/main-layout-style.css"],"names":[],"mappings":"AAAA;EACE,uCAAuC;EACvC,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,uCAAuC;EACvC,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,cAAc;EACd,4BAA4B;AAC9B;;AAEA;EACE,YAAY;AACd","sourcesContent":[".main-layout {\n  /* border: 2px solid rgb(17, 255, 0); */\n  margin-top: 70px;\n  margin-left: 103px;\n}\n\n.main-layout-left {\n  /* border: 2px solid rgb(17, 255, 0); */\n  margin-top: 70px;\n}\n\n.main-body {\n  flex-grow: 1;\n  padding: 0.5rem;\n  padding-top: 0;\n  /* border: 1px solid blue; */\n}\n\n.content {\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
