import { useMutation } from "@tanstack/react-query";
import apiClient from "lib/api-client";
import { toast } from "react-toastify";

const updateSet = async (data) => {
  const response = await apiClient.post(`/api/v1/set/removeDesignsFromSet`, data);
  return response.data;
};

export const useUpdateSet = ({ onCompleted }) => {
  return useMutation({
    mutationFn: updateSet,
    onSuccess: (data) => {
      onCompleted();
      toast.success("Set Updated Successfully");
    },
    onError: (error) => {
      console.log("Error in Edit profile", error.message);
    },
  });
};
