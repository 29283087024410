import config from "configs/config";
import apiClient from "lib/api-client";

export const removeCollections = async (collectionIds) => {
  let Payload = {
    collectionId: collectionIds,
  };
  let response = await apiClient.put(`${config.baseUrl}/api/v1/collection/deleteCollections`, Payload);
  return response.data;
};
