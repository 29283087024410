import React from "react";

const Activity = ({ activityData, generatedActivityString }) => {
  let count = 0;
  const findCount = () => {
    count = activityData.reduce((acc, cur) => {
      return acc + cur.data.length;
    }, 0);

    return count;
  };

  const totalCount = (index, subIndex) => {
    const total = findCount();
    let itemsBefore = 0;
    for (let i = 0; i < index; i++) {
      itemsBefore += activityData[i].data.length;
    }
    itemsBefore += subIndex;
    const remainingCount = total - itemsBefore - 1;

    return remainingCount + 1;
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column", overflowX: "hidden" }}>
      {activityData.map((curAtivity, index) => {
        return (
          <div key={`subDiv-${curAtivity.day}-${index}`} style={{ height: "100%" }}>
            {/* Day Container */}
            <div key={`first-div-${curAtivity.day}-${index}`} style={{ height: "100%", display: "flex", alignItems: "center", position: "relative" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "34px",
                  position: "relative",
                  zIndex: 1,
                  marginLeft: "120px",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    borderLeft: "1px solid #444444",
                    height: "22px",
                    boxSizing: "border-box",
                    backgroundColor: "transparent",
                    position: "relative",
                  }}
                >
                  {index == 0 && (
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "-3px",
                        width: "5px",
                        height: "5px",
                        borderRadius: "50%",
                        backgroundColor: "#444444",
                      }}
                    />
                  )}
                </div>

                <div
                  style={{
                    borderLeft: "1px solid #444444",
                    height: "22px",
                    boxSizing: "border-box",
                    backgroundColor: "transparent",
                  }}
                />
              </div>
              <div
                style={{
                  border: "1px solid #444444",
                  borderRadius: "46px",
                  width: "250px",
                  height: "34px",
                  boxSizing: "border-box",
                  clipPath: "polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%)",
                  backgroundColor: "transparent",
                  position: "absolute",
                  zIndex: 2,
                  marginLeft: "-5px",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  padding: "10px",
                }}
              >
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "50%" }}>
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      backgroundColor: "#50C878",
                      border: "2px solid #50C878",
                      marginBottom: "auto",
                      marginTop: "auto",
                    }}
                  />
                  <div style={{ fontFamily: "Poppins", marginTop: "1px", fontWeight: 400, fontSize: "14px" }}>{curAtivity.day}</div>
                </div>
              </div>
            </div>

            {/* Iterate through each activity data */}
            {curAtivity.data.map((curData, subIndex) => {
              return (
                <div key={`${index}-activity-${subIndex}`} style={{ height: "100%", display: "flex", alignItems: "center", position: "relative" }}>
                  {/* First square div */}
                  <div style={{ height: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "34px",
                        position: "relative",
                        zIndex: 1,
                        marginLeft: "120px",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          borderLeft: "1px solid #444444",
                          height: "22px",
                          boxSizing: "border-box",
                          backgroundColor: "transparent",
                        }}
                      ></div>
                      <div
                        style={{
                          borderLeft: "1px solid #444444",
                          height: "22px",
                          boxSizing: "border-box",
                          backgroundColor: "transparent",
                          position: "relative",
                        }}
                      >
                        {activityData?.length - 1 == index && curAtivity?.data?.length - 1 == subIndex && (
                          <div
                            style={{
                              position: "absolute",
                              bottom: "0",
                              left: "-3px",
                              width: "5px",
                              height: "5px",
                              borderRadius: "50%",
                              backgroundColor: "#444444",
                            }}
                          />
                        )}
                      </div>
                    </div>

                    {/* Second half-clipped div positioned relative to the first div */}
                    <div
                      style={{
                        border: "1px solid #444444",
                        borderRadius: "46px",
                        width: "150px",
                        height: "34px",
                        boxSizing: "border-box",
                        clipPath: "polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%)",
                        backgroundColor: "transparent",
                        position: "absolute",
                        zIndex: 2,
                        color: "white",
                        marginLeft: "46px",
                        display: "flex",
                        alignItems: "center",
                        padding: "10px",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px", lineHeight: "21px", marginLeft: "10px" }}>{totalCount(index, subIndex)}</div>
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          backgroundColor: "#8B8B8B",
                          border: "2px solid #8B8B8B",
                          marginLeft: "15px",
                        }}
                      />
                    </div>
                    <div style={{ marginLeft: "25px", display: "flex", flexDirection: "column", overflowX: "hidden" }}>
                      <div
                        style={{
                          color: "white",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          paddingBottom: curAtivity.data.length - 1 === subIndex ? "0" : "30px",
                          paddingTop: curAtivity.data.length - 1 === subIndex ? "0" : "28px",
                        }}
                      >
                        {generatedActivityString(curData)}
                      </div>
                      {curAtivity.data.length - 1 === subIndex ? null : (
                        <div style={{ color: "white", width: "100%" }}>
                          <div style={{ borderBottom: "1px solid #444444", width: "100vw" }} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default Activity;
