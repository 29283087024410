import request from "lib/axio-utilis";
import { getUserDetail } from "utils/getUserDetailsHelper";

const catalogAPIs = {
  createCatalog: (data) => {
    return request({ url: "/api/v1/catalog/createCatalog", method: "post", data: data });
  },
  editCatalog: (data) => {
    return request({ url: "/api/v1/catalog/updateCatalogDesignWithArchive", method: "post", data: data });
  },
  getAllCatalogs: async ({ queryKey, pageParam = 1 }) => {
    const { limit, sortBy, search, isArchive } = queryKey[1];
    const userId = sortBy === "createdByMe" ? getUserDetail("_id") : null;
    const requestData = {
      page: pageParam,
      limit: limit,
      sortBy: sortBy,
      search: search,
      userId: userId,
      isArchive: isArchive,
    };
    let response;
    if (!isArchive) {
      response = await request({ url: "/api/v1/catalog/getAllCatalogs", method: "post", data: requestData });
    } else {
      response = await request({ url: "/api/v1/catalog/getAllArchiveCatalogsForWeb", method: "post", data: requestData });
    }

    if (response.status !== 200) {
      throw new Error("Error fetching categories");
    }

    return {
      // catalogs: response.data.catalogs.filter((item) => item.isArchive === isArchivefilter),
      catalogs: response.data.catalogs,
      nextPage: response.data.pageNumber,
      totalPage: response.data.totalPages,
    };
  },
  getSingleCatalogProducts: async ({ queryKey, pageParam = 1 }) => {
    const { catalogId, limit, search, isArchive } = queryKey[1];
    const requestData = {
      catalogId: catalogId,
      page: pageParam,
      limit: limit,
      search: search,
    };
    
     let response;
     if (!isArchive) {
       response = await request({ url: "/api/v1/catalog/getDesignsByCatalogId", method: "post", data: requestData });
     } else {
       response = await request({ url: "/api/v1/catalog/getDesignsByArchiveCatalogId", method: "post", data: requestData });
     }
  
    if (response.status !== 200) {
      throw new Error("Error fetching categories");
    }

    return {
      catalogProducts: response.data.designs,
      nextPage: response.data.currentPage,
      totalPage: response.data.totalPages,
      totalDesigns: response.data.totalDesigns,
    };
  },
  catalogArchiveToUnarchive: (data) => {
    return request({ url: "/api/v1/catalog/catalogArchiveToUnarchive", method: "post", data: data });
  },
  catalogDelete: (data) => {
    return request({ url: "/api/v1/catalog/deleteCatalog", method: "post", data: data });
  },
};

export default catalogAPIs;
