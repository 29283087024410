import request from "lib/axio-utilis";
import { getUserDetail } from "utils/getUserDetailsHelper";

const catalogClientAPIs = {
  //Catalog-Clinet API :-
  getAllCatalogsClients: async ({ queryKey, pageParam = 1 }) => {
    const { limit, sortBy, serarch, sortType, catalogId } = queryKey[1];
    const userId = getUserDetail("_id");
    const requestData = {
      page: pageParam,
      limit: limit,
      sortBy: sortBy,
      serarch: serarch,
      sortType: sortType,
      userId: "",
      catalogId: catalogId,
    };

    const response = await request({ url: "/api/v1/catalogSetting/catalog-client/getCatalogClientDetails", method: "post", data: requestData });

    if (response.status !== 200) {
      throw new Error("Error fetching categories");
    }

    return {
      catalogClinets: response.data.catalogClinets,
      nextPage: response.data.currentPage,
      totalPage: response.data.totalPages,
    };
  },

  createCatalogClient: (data) => {
    const userId = getUserDetail("_id");
    return request({ url: "/api/v1/catalogSetting/catalog-client", method: "post", data: { ...data, userId } });
  },

  editCatalogClient: (data) => {
    const userId = getUserDetail("_id");
    return request({ url: "/api/v1/catalogSetting/catalog-client/update", method: "post", data: data });
  },

  // Clinet Session API:-
  getCatalogClientsActivity: async ({ queryKey, pageParam = 1 }) => {
    const { limit, catalogClinetId } = queryKey[1];
    const requestData = {
      page: pageParam,
      limit: limit,
      catalogClinetId: catalogClinetId,
    };

    const response = await request({ url: "/api/v1/catalogSetting/client-activity-session", method: "post", data: requestData });

    if (response.status !== 200) {
      throw new Error("Error fetching Client Session information.");
    }

    return {
      sessionInfo: response.data.sessionInfo,
      nextPage: response.data.currentPage,
      totalPage: response.data.totalPages,
    };
  },

  revokeAccess: (data) => {
    return request({ url: "/api/v1/catalogSetting/client-activity-session/revokeAccess", method: "post", data: data });
  },

  createNewRenewAccess: (data) => {
    return request({ url: "/api/v1/catalogSetting/client-activity-session/createNewRenewAccess", method: "post", data: data });
  },
};

export default catalogClientAPIs;
