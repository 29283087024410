export const covertingDateAndTime = (oldDate) => {
  if (!oldDate) {
    return null;
  }

  const formatedDate = new Date(oldDate);

  const date = formatedDate.toLocaleDateString("en-GB"); // Format: DD/MM/YY
  const time = formatedDate.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });

  return `${date} ${time}`;
};
