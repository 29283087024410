import CatalogLinkUser from "features/catalogue/components/Settings/Activity/componets/CatalogLinkUser";
import React from "react";

export const CatalogSetting = () => {
  return (
    <div style={{ color: "#FFFFFF", fontFamily: "Poppins", fontWeight: 400, fontSize: "24px", lineHeight: "36px" }} className="Grid-padding">
      <CatalogLinkUser />
    </div>
  );
};
