import { Box, styled } from "@mui/material";
import axios from "axios";
import Button from "components/ui/Button";
import RemoveModal from "components/ui/RemoveModal/RemoveModal";
import { useGlobalContext } from "context/GlobalContext";
import { useEditCatalog } from "features/catalogue/hooks/useEditCatalog";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast as myToast } from "react-toastify";

import { isChanges } from "utils/checkExitCatalogUpdate";
import { updateCatalogData } from "utils/updateCatalogData";

const StyledButton = styled(Button)(({ theme, bgcolor }) => ({
  width: "100%",
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "22px",
  height: "42px",
  borderRadius: "5px",
  color: bgcolor,
  border: `1px solid ${bgcolor} `,
  textTransform: "none",
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: bgcolor,
    color: "black",
  },
  "&.Mui-disabled": {
    backgroundColor: "transparent",
    color: "#8B8B8B",
    border: "1px solid #8B8B8B",
  },
}));

function CatalogueEditHeader({ Search, StyledInputBase, catalogueName }) {
  const [open, setOpen] = useState(false);
  const [newName, setNewName] = useState("");
  const navigate = useNavigate();
  const { isArchive, setIsArchive, checkCatalogBeforeNavigate, setCheckCatalogBeforeNavigate, newNameCatalog, setNewNameCatalog } = useGlobalContext();

  const handleDiscardChanges = useCallback(() => {
    setNewName(catalogueName);
    setOpen(false);
    setIsArchive(false);
    setCheckCatalogBeforeNavigate(false);
    navigate("/Catalog");
  }, [catalogueName]);

  const modalBtnInfo = useMemo(
    () => [
      {
        btnName: "Discard",
        bgColor: "#E77B7B",
        click: handleDiscardChanges,
      },
      {
        btnName: "Keep",
        bgColor: "#50C878",
        click: () => setOpen(false),
      },
    ],
    [handleDiscardChanges]
  );

  const { mutate: updateCatalog } = useEditCatalog();

  const handleEditCatalog = () => {
    const data = updateCatalogData();
    updateCatalog({ ...data, catalogName: newName, isArchive: isArchive });
  };

  const btnInfo = useMemo(
    () => [
      {
        btnName: "Save Changes",
        bgColor: "#50C878",
        click: handleEditCatalog,
      },
      {
        btnName: "Cancel",
        bgColor: "#E77B7B",
        click: () => {
          !isChanges(catalogueName, newName, isArchive) ? handleDiscardChanges() : setOpen(true);
        },
      },
    ],
    [handleEditCatalog]
  );

  const checkCatalogUpdatInfo = useMemo(
    () => [
      {
        btnName: "Don’t Save",
        bgColor: "#E77B7B",
        click: handleDiscardChanges,
      },
      {
        btnName: "Save",
        bgColor: "#50C878",
        click: handleEditCatalog,
      },
    ],
    [handleEditCatalog]
  );

  const handleInputChange = useCallback((e) => {
    const newValue = e.target.value;
    setNewName(newValue);
    setNewNameCatalog(newValue);
  }, []);

  useEffect(() => {
    setNewName(catalogueName);
    setNewNameCatalog(catalogueName);
  }, [catalogueName]);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
        <Search>
          <StyledInputBase onChange={handleInputChange} value={newName} inputProps={{ "aria-label": "search" }} />
        </Search>
        {btnInfo &&
          btnInfo.map((btn) => (
            <Box key={btn.btnName}>
              <StyledButton bgcolor={btn.bgColor} onClick={btn.click} disabled={btn.btnName === "Save Changes" ? !isChanges(catalogueName, newName, isArchive) : false}>
                {btn.btnName}
              </StyledButton>
            </Box>
          ))}
        {/* <MyToastContainer /> */}
      </Box>
      <RemoveModal open={open} setOpen={setOpen} btnInfo={modalBtnInfo} heading="Do you want to cancel the changes?" />
      <RemoveModal open={checkCatalogBeforeNavigate} setOpen={setCheckCatalogBeforeNavigate} btnInfo={checkCatalogUpdatInfo} heading="Do you want to Save the Changes?" />
    </>
  );
}

export default CatalogueEditHeader;
