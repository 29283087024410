export const updateCatalogData = () => {
  const catalogData = JSON.parse(sessionStorage.getItem("catalogWithProduct")) || {};
  const originalCatalogProducts = catalogData.productData || [];
  const updateCatalogProducts = JSON.parse(sessionStorage.getItem("updateProductsData")) || [];

  const addDesigns = updateCatalogProducts
    .filter((updateProduct) => {
      return !originalCatalogProducts.some((originProduct) => {
        return originProduct._id === updateProduct._id || originProduct._id === updateProduct.id;
      });
    })
    .map((product) => product.id || product._id);

  const removeDesigns = originalCatalogProducts
    .filter((originalProduct) => {
      return !updateCatalogProducts.some((updatedProduct) => {
        return updatedProduct._id === originalProduct._id || updatedProduct.id === originalProduct._id;
      });
    })
    .map((product) => product._id);

  return {
    addDesigns,
    removeDesigns,
    catalogId: catalogData?.itemId?.id,
    isArchive: false,
  };
};
