// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-container {
  display: grid;
  grid-auto-flow: row;
  justify-items: center;
  grid-template-rows: 0fr 0.08fr 2fr;
  height: 100%;
  background-color: #101010;
  color: #fff;
  padding: 10px 10px;
}

.custom-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: inherit;
  padding-top: 20px;
  gap: 5px;
  cursor: pointer;
}

.custom-list-item .MuiListItemIcon-root {
  min-width: auto;
  color: inherit;
}

.sidebar-icon-name {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 18px;
  color: #444444;
}

.sidebar-icon-name-active {
  color: #fff;
}

.custom-list-item .MuiListItemText-root {
  margin-top: 10px;
}

.sidebar-icons-space {
  display: flex;
  flex-direction: column;
}

.sidebar-logo {
  cursor: pointer;
  height: "40px";
  width: "32px";
}

.sidebar-custom-link {
  text-decoration: none;
  color: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB,kCAAkC;EAClC,YAAY;EACZ,yBAAyB;EACzB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,cAAc;EACd,iBAAiB;EACjB,QAAQ;EACR,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,kCAAkC;EAClC,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,aAAa;AACf;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB","sourcesContent":[".sidebar-container {\n  display: grid;\n  grid-auto-flow: row;\n  justify-items: center;\n  grid-template-rows: 0fr 0.08fr 2fr;\n  height: 100%;\n  background-color: #101010;\n  color: #fff;\n  padding: 10px 10px;\n}\n\n.custom-list-item {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  color: inherit;\n  padding-top: 20px;\n  gap: 5px;\n  cursor: pointer;\n}\n\n.custom-list-item .MuiListItemIcon-root {\n  min-width: auto;\n  color: inherit;\n}\n\n.sidebar-icon-name {\n  font-size: 12px;\n  font-family: \"Poppins\", sans-serif;\n  font-weight: 500;\n  line-height: 18px;\n  color: #444444;\n}\n\n.sidebar-icon-name-active {\n  color: #fff;\n}\n\n.custom-list-item .MuiListItemText-root {\n  margin-top: 10px;\n}\n\n.sidebar-icons-space {\n  display: flex;\n  flex-direction: column;\n}\n\n.sidebar-logo {\n  cursor: pointer;\n  height: \"40px\";\n  width: \"32px\";\n}\n\n.sidebar-custom-link {\n  text-decoration: none;\n  color: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
