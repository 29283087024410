import { Grid, Typography } from "@mui/material";
import { Card } from "components/ui";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const archives = [
  { name: "Catalog", path: "Catalog" },
  { name: "Sets", path: "Sets" },
];

const Archives = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let preState = location.state;

  const handleClick = (name, path) => {
    // const state = { ...preState, name: [...preState.name, `${name}`] };
    const state = { ...preState, name: [preState.name && preState.name[0], `${name}`] };

    navigate(`/Setting/archives/${path}`, { state });
  };

  const style = {
    justifyContent: "center",
    fontFamily: "Karla",
    fontWeight: "400",
    fontSize: "20px",
    color: "white",
  };

  return (
    <div>
      <Grid
        container
        className="Grid-padding"
        sx={{ paddingLeft: "18px", paddingRight: "18px" }}
        rowSpacing={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 3 }}
        columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 3 }}
        justifyContent="space-beetween"
        alignItems="center"
      >
        {archives.map((el) => {
          const { name, path } = el;
          return (
            <Grid item xs={12} sm={12} md={4} lg={3} xl={2.3} key={name}>
              <Card style={style} onClick={() => handleClick(name, path)}>
                <Typography fontSize={"20px"} fontFamily={"Karla"}>
                  {name}
                </Typography>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default Archives;
