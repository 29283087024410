import Default from "assets/img/defulat.png";
import { convertingSecondIntoHoursFormat } from "./convertingSecondIntoHoursFormat";

const transformData = (design) => {
  const obj = {
    id: design._id ? design._id : design.id,
    labour: design.makeType ? design.makeType : design.labour,
    date: design.styleDate ? new Date(design.styleDate).toLocaleDateString("en-GB") : design.date,
    // gold: design.styleNumber ? design.styleNumber : design.gold,
    // diamond: design.diaPcs ? `${design.diaPcs}/${design.diaWeight}` : design.diamond,
    diamond: design.diaPcs != null && design.diaWeight != null ? `${design.diaPcs}/${design.diaWeight}` : design.diamond,
    weight: design.grossWeight ? `${design.grossWeight}/${design.netWeight}` : design.weight,
    collectionDetails: design.collectionDetails || [],
    setDetails: design.setDetails || [],
    img: design?.imageUrl,
  };

  if (design.designClass) {
    const key = design.designClass.toLowerCase();
    obj[key] = design.styleNumber || null;
  }

  return obj;
};

const transformSetData = (set) => {
  const defaultImageUrl = Default;

  // Create an array of images, slicing the first 4 and filling missing with the default image
  const designsImgs = [...set?.designImages?.slice(0, 4).map((design) => ({ image: design.imageUrl })), ...Array(Math.max(0, 4 - set.designImages.length)).fill({ image: defaultImageUrl })];

  // for the max makeType calculation
  let makeTypes = set?.designs[0];
  const map = new Map();
  for (let i = 0; i < makeTypes.length; i++) {
    const type = makeTypes[i].makeType;

    if (map.has(type)) {
      map.set(type, map.get(type) + 1);
    } else {
      map.set(type, 1);
    }
  }
  const mostFrequentMakeType = [...map.entries()].reduce(
    (max, [type, count]) => {
      return count > max.count ? { type, count } : max;
    },
    { type: null, count: 0 }
  );

  // for the max metal type
  const MetalTypes = set?.designs[0]?.reduce((acc, design, ind) => {
    const metalType = design?.designClass?.toLowerCase();
    if (!metalType) return acc;
    acc[metalType] = (acc[metalType] || 0) + 1;
    return acc;
  }, {});

  const maxMetal = Object.entries(MetalTypes).reduce(
    (acc, [key, val]) => {
      return val > acc.value ? { key, value: val } : acc;
    },
    { key: null, value: -Infinity }
  );

  return {
    _id: set._id,
    setNumber: set.setNumber,
    totalDiamonds: `${set.totalDiaPcs}/${set.totalDiaWeight.toFixed(2)}`,
    goldWeights: `${set.totalGoldGrossWeight.toFixed(2)}/${set.totalGoldWeight.toFixed(2)}`,
    numberOfStyles: set.numberOfStyles,
    date: new Date(set.createdAt).toLocaleDateString("en-GB"),
    designsImgs,
    labour: mostFrequentMakeType.type,
    maxMetalType: maxMetal?.key,
  };
};

const transformArchivesSetData = (set) => {
  const defaultImageUrl = Default;

  // Create an array of images, slicing the first 4 and filling missing with the default image
  // const designsImgs = [...set?.designImages?.slice(0, 4).map((design) => ({ image: design.imageUrl })), ...Array(Math.max(0, 4 - set.designImages.length)).fill({ image: defaultImageUrl })];

  const designsImgs = [
    ...set.designs
      .flat()
      .slice(0, 4)
      .map((design) => ({
        image: design?.images?.[0]?.imageUrl || defaultImageUrl,
      })),
    ...Array(Math.max(0, 4 - set.designs.flat().length)).fill({ image: defaultImageUrl }),
  ];

  return {
    _id: set._id,
    setNumber: set.setNumber,
    totalDiamonds: `${set.totalDiaPcs}/${set.totalDiaWeight.toFixed(2)}`,
    goldWeights: `${set.totalGoldGrossWeight.toFixed(2)}/${set.totalGoldWeight.toFixed(2)}`,
    numberOfStyles: set.numberOfStyles,
    date: new Date(set.createdAt).toLocaleDateString("en-GB"),
    designsImgs,
    labour: "H",
  };
};

//for the set tray modal
const transformDataForTrayModal = (design) => {
  const obj = {
    id: design._id ? design._id : design.id,
    labour: design.makeType ? design.makeType : design.labour,
    date: design.styleDate ? new Date(design.styleDate).toLocaleDateString("en-GB") : design.date,
    // gold: design.styleNumber ? design.styleNumber : design.gold,
    diamond: design.diaPcs ? `${design.diaPcs}/${design.diaWeight}` : design.diamond,
    weight: design.grossWeight ? `${design.grossWeight}/${design.netWeight}` : design.weight,
    collectionDetails: design.collectionDetails || [],
    setDetails: design.setDetails || [],
    img: design.images ? design?.images[0]?.imageUrl : design?.img ? design?.img : design?.imageUrl,
  };

  if (design.designClass) {
    const key = design.designClass.toLowerCase();
    obj[key] = design.styleNumber || null;
  }

  return obj;
};

const transformClinet = (design) => {
  const obj = {
    _id: design?.style?._id || design._id,
    view: design.view || 0,
    cart: design.cart || 0,
    clock: convertingSecondIntoHoursFormat(design.clock || 0) || "00:00:00",
    // gold: design?.style?.styleNumber ? design?.style?.styleNumber : design?.style?.gold,
    // diamond: design.diaPcs ? `${design.diaPcs}/${design.diaWeight}` : design.diamond,
    removeCart: design.removeCart || 0,
    img: design?.imageUrl,
    labour: design?.style?.makeType ? design?.style?.makeType : design?.style?.labour,
    date: design?.style?.styleDate ? new Date(design?.style?.styleDate).toLocaleDateString("en-GB") : design?.style?.date,
    // diamond: design.diaPcs ? `${design.diaPcs}/${design.diaWeight}` : design.diamond,
    diamond: design?.style?.diaPcs != null && design?.style?.diaWeight != null ? `${design?.style?.diaPcs}/${design?.style?.diaWeight}` : design?.style?.diamond,
    weight: design?.style?.grossWeight ? `${design?.style?.grossWeight}/${design?.style?.netWeight}` : design?.style?.weight,
  };

  if (design?.style?.designClass) {
    const key = design?.style?.designClass.toLowerCase();
    obj[key] = design?.style?.styleNumber || null;
  }

  return obj;
};

export { transformData, transformSetData, transformArchivesSetData, transformDataForTrayModal, transformClinet };
