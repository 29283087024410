import { useInfiniteQuery } from "@tanstack/react-query";
import catalogReportsAPIs from "../api/catalogReportsAPIs";

export const useGetAllTotalTimeSpentOnCatalogByClient = ({ limit = 10, catalogId }) => {
  return useInfiniteQuery({
    queryKey: ["AllTotalTimeSpentOnCatalogByClient", { limit: limit, catalogId: catalogId }],
    queryFn: ({ pageParam = 1 }) =>
      catalogReportsAPIs.getAllTotalTimeSpentOnCatalogByClient({
        pageParam,
        queryKey: ["AllTotalTimeSpentOnCatalogByClient", { limit: limit, catalogId: catalogId }],
      }),
    getNextPageParam: (lastPage) => {
      if (lastPage.nextPage < lastPage.totalPage) {
        return lastPage.nextPage + 1;
      }
      return undefined;
    },
    enabled: !!catalogId,
    cacheTime: 200000,
    staleTime: 200000,
    refetchOnWindowFocus: true,
  });
};
