import { useCatalogSettingContext } from "context/CatalogSettingContext";
import React, { useEffect, useState } from "react";
import CatalogTimeSpentModal from "./CatalogTimeSpentModal";
import withReportData from "../../utils/withReportData";
import ReportDatas from "../../utils/ReportDatas";
import CatalogSortModal from "./CatalogSortModal";
import CatalogViewCartModal from "./CatalogViewCartModal";
import { useParams } from "react-router-dom";
import { useGetCatalogStylesReports } from "../hooks/useGetCatalogClientStylesReports";
import clientReportsAPIs from "../../Report/api/clientReportsAPIs";
import SortContext from "context/SortContext";

const catalogProductsReports = [
  {
    id: 1,
    bronze: "NS-00410",
    view: 23,
    cart: 20,
    removeCart: 3,
    clock: "00:15:23",
  },
  {
    id: 2,
    silver: "NS-00560",
    view: 26,
    cart: 15,
    removeCart: 6,
    clock: "00:20:23",
  },
  {
    id: 3,
    gold: "NS-00603",
    view: 30,
    cart: 10,
    removeCart: 8,
    clock: "00:10:23",
  },
];

const catalogProductsDetails = [
  {
    id: 1,
    bronze: "NS-00410",
    diamond: "500/1.495",
    weight: "6.59/6.25",
    labour: "X",
    date: "15/02/2022",
  },
  {
    id: 2,
    silver: "NS-00560",
    diamond: "300/0.995",
    weight: "9.09/8.05",
    labour: "R",
    date: "17/02/2022",
  },
  {
    id: 3,
    gold: "NS-00603",
    diamond: "400/1.095",
    weight: "10.59/9.25",
    labour: "H",
    date: "19/02/2022",
  },
];

const CatalogReportScreen = ({ btnTitles, setBtnTitles, reportData, setReportData, handleHideDetails, ...props }) => {
  const { timeSpentCatalogOpen, setTimeSpentCatalogOpen, sortModal, setSortModal, viewLinkModal, setViewLinkModal, serarch, setSerarch } = useCatalogSettingContext();
  const { selectedSortValue, sortOptions, setSortOptions, setSelectedSortValue } = React.useContext(SortContext);
  const sort = sortOptions.find((item) => item.id == selectedSortValue);
  const [productDetails, setProductDetails] = useState();
  const [viewCartType, setViewCartType] = useState("");
  const handleViewCartDetails = (data, icon) => {
    setViewLinkModal(true);
    setProductDetails(data);
    setViewCartType(icon);
  };

  const { id } = useParams();
  const { data, isLoading, fetchNextPage, hasNextPage, isError, isFetching, isFetchingNextPage, error } = useGetCatalogStylesReports({
    catalogId: id,
    sortBy: sort?.sortBy,
    serarch: serarch || "",
    sortType: sort?.sortType,
  });

  const handleShowDetails = async (_id) => {
    setBtnTitles((prev) => ({ ...prev, [_id]: "Hide Details" }));
  };

  useEffect(() => {
    return () => {
      setSortOptions([]);
      setSelectedSortValue("");
      setSerarch("");
    };
  }, []);

  return (
    <ReportDatas
      {...props}
      btnTitles={btnTitles}
      reportData={data || []}
      isLoading={isLoading}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isError={isError}
      isFetching={isFetching}
      isFetchingNextPage={isFetchingNextPage}
      error={error}
      handleShowDetails={handleShowDetails}
      handleHideDetails={handleHideDetails}
      handleViewCartDetails={handleViewCartDetails}
      timeSpentCatalogModal={<CatalogTimeSpentModal open={timeSpentCatalogOpen} setOpen={setTimeSpentCatalogOpen} />}
      sortCatalogModal={<CatalogSortModal open={sortModal} setOpen={setSortModal} />}
      viewIconCatalogModal={<CatalogViewCartModal open={viewLinkModal} setOpen={setViewLinkModal} productDetails={productDetails} viewCartType={viewCartType} />}
    />
  );
};

export default withReportData(CatalogReportScreen, catalogProductsDetails, catalogProductsReports, "Catalog Report");
