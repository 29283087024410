import React, { useCallback, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { ProductCard } from "components/ui";
import { useParams } from "react-router-dom";
import { useGetSingleCataloProducts } from "features/catalogue/hooks/useGetSingleCatalogProducts";
import { transformData, transformDataForTrayModal } from "utils/transformdata";
import CatalogueCardSkeleton from "features/catalogue/components/CatalogueCardSkeleton";
import { useInView } from "react-intersection-observer";
import { ReactComponent as Background } from "assets/icons/bg.svg";

const CatalogProductArchiveScreen = () => {
  const { id } = useParams();
  const { data: newData, isLoading, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } = useGetSingleCataloProducts({ catalogId: id, limit: 10, search: "", isArchive:true });
  const { ref, inView } = useInView({
    threshold: 1.0,
  });

  const isFetchNextPage = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  useEffect(() => {
    if (inView) {
      isFetchNextPage();
    }
  }, [inView, isFetchNextPage]);

  if (isLoading) {
    return (
      <Grid
        container
        className="Grid-padding"
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ flex: "1 1 1", display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "32px", rowGap: "32px" }}
      >
        {Array.from({ length: 4 }, (_, index) => (
          <CatalogueCardSkeleton key={index} item={{ id: index }} />
        ))}
      </Grid>
    );
  }

  return (
    <>
      {newData?.pages[0]?.catalogProducts.length > 0 ? (
        <Grid
          container
          className="Grid-padding"
          direction="row"
          justifyContent="space-between"
          sx={{
            paddingLeft: "18px",
            paddingRight: "18px",
            maxHeight: "78vh",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              width: "0px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "transparent",
            },
            scrollbarWidth: "none", // For Firefox
            flex: "1 1 1",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "32px",
            rowGap: "32px",
          }}
        >
          {newData?.pages.map((page) =>
            page?.catalogProducts?.map((design, index) => {
              // const curElement = transformData(design);
              let curElement = transformDataForTrayModal(design);
              return (
                <Grid key={index + "-" + curElement.id} item className="product">
                  <ProductCard key={index} data={curElement} style={{ maxWidth: "430px", minWidth: "430px" }} />
                </Grid>
              );
            })
          )}
          {isFetchingNextPage && <div style={{ color: "red" }} />}
          {isFetchingNextPage && Array.from({ length: 1 }, (_, index) => <CatalogueCardSkeleton key={index} item={{ id: index }} />)}
          <div ref={ref} />
        </Grid>
      ) : (
        <div
          style={{
            display: "flex",
            textAlign: "center",
            // padding: "40px",
            margin: "auto",
            padding: "auto",
            justifyContent: "center",
            alignItems: "center",
            height: "78vh",
          }}
        >
          <Background />
        </div>
      )}
    </>
  );
};

export default CatalogProductArchiveScreen;
