const { createTheme, colors } = require("@mui/material");

const customThems = createTheme({
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {
          color: "black",
          //   gap: "10px",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        Input: {
          color: "#FFFFFF",
          fontFamily: "Poppins",
          fontWeight: 400,
          fontSize: "20px",
          lineHeight: "22px",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: "Poppins",
          fontWeight: 400,
          fontSize: "20px",
          lineHeight: "22px",
          color: "#FFFFFF",
          marginBottom: "20px",
          "&.Mui-focused ": {
            color: "#FFFFFF", // Border color when focused
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          //   display: "flex",
          color: "white",
          marginRight: "8px",
          opacity: "0.7",
        },

        select: {
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          border: "1px solid rgba(255, 255, 255, 0.4)",
          borderRadius: "5px",
          width: "100%",
          fontFamily: "Poppins",
          fontSize: "18px",
          lineHeight: "22px",
          fontWeight: 400,
          padding: "12.5px 13px",
        },
        root: {
          textAlign: "left",
          color: "#fff",
          border: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          textTransform: "none",
          fontFamily: "Poppins",
          fontSize: "18px",
          lineHeight: "22px",
          fontWeight: 400,
          "&.Mui-focused": {
            // borderColor: "#fff",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          border: "1px solid rgba(255, 255, 255, 0.4)",
          //   opacity: "0.7",
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          height: "50px",
        },
        notchedOutline: {
          borderColor: "transparent",
        },
        input: {
          fontFamily: "Poppins",
          fontWeight: 400,
          fontSize: "18px",
          lineHeight: "27px",
          color: "#fff",
          textAlign: "left",
          borderRadius: "5px",
          //   opacity: "1",
          "&.Mui-disabled": {
            color: "rgba(255, 255, 255, 0.7)",
            "-webkit-text-fill-color": "rgba(255, 255, 255, 0.7)", // Change webkit text fill color when disabled
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          width: "auto",
          fontFamily: "Poppins",
          fontWeight: 400,
          fontSize: "20px",
          lineHeight: "22px",
          height: "42px",
          borderRadius: "5px",
          color: "#50C878",
          backgroundColor: "transparent",
          border: "1px solid #50C878",
          textTransform: "none",
          paddingLeft: "20px",
          paddingRight: "20px",
          "&:hover": {
            backgroundColor: "#50C878",
            color: "#000000",
          },
          "&.Mui-disabled": {
            backgroundColor: "transparent",
            color: "#444444",
            border: "1px solid #444444",
          },
        },
      },
    },
  },
});

export default customThems;
