import config from "configs/config";
import apiClient from "lib/api-client";
import { createContext, useContext, useEffect, useState } from "react";
import { getUserDetail } from "utils/getUserDetailsHelper";

const PermissionsContext = createContext();

export const PermissionsProvider = ({ children }) => {
  const [permissions, setPermissions] = useState();
  const userId = getUserDetail("_id") || null;

  useEffect(() => {
    apiClient.get(`${config.baseUrl}/api/v1/role/permissions/${userId}`).then((res) => {
      setPermissions(res?.data?.role);
    });
  }, []);

  return <PermissionsContext.Provider value={permissions}>{children}</PermissionsContext.Provider>;
};

export const usePermissions = () => useContext(PermissionsContext);
