// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collection-data {
  font-family: "karla", sans-serif;
  font-size: 19px;
  font-weight: 300;
  /* border: "1px soolid red"; */
}

.skeleton-container {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}

.shimmer {
  position: absolute;
  top: 0;
  left: -150%;
  width: 250%;
  height: 100%;
  background: linear-gradient(150deg, rgba(255, 255, 255, 0) 10%, rgba(134, 131, 131, 0.4) 50%, rgba(255, 255, 255, 0) 100% /* Transparent at the end */);
  animation: shimmer-animation 1.3s infinite linear;
}

@keyframes shimmer-animation {
  0% {
    left: -150%; /* Start further off-screen */
  }
  100% {
    left: 150%; /* Move further off-screen */
  }
}
`, "",{"version":3,"sources":["webpack://./src/features/collection/styles/collectionScreen.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,eAAe;EACf,gBAAgB;EAChB,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,WAAW;EACX,WAAW;EACX,YAAY;EACZ,uJAAuJ;EACvJ,iDAAiD;AACnD;;AAEA;EACE;IACE,WAAW,EAAE,6BAA6B;EAC5C;EACA;IACE,UAAU,EAAE,4BAA4B;EAC1C;AACF","sourcesContent":[".collection-data {\n  font-family: \"karla\", sans-serif;\n  font-size: 19px;\n  font-weight: 300;\n  /* border: \"1px soolid red\"; */\n}\n\n.skeleton-container {\n  position: relative;\n  overflow: hidden;\n  border-radius: 5px;\n}\n\n.shimmer {\n  position: absolute;\n  top: 0;\n  left: -150%;\n  width: 250%;\n  height: 100%;\n  background: linear-gradient(150deg, rgba(255, 255, 255, 0) 10%, rgba(134, 131, 131, 0.4) 50%, rgba(255, 255, 255, 0) 100% /* Transparent at the end */);\n  animation: shimmer-animation 1.3s infinite linear;\n}\n\n@keyframes shimmer-animation {\n  0% {\n    left: -150%; /* Start further off-screen */\n  }\n  100% {\n    left: 150%; /* Move further off-screen */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
