import SortContext from "context/SortContext";
import CatalogueScreen from "features/catalogue/components/CatalogueScreen";
import React from "react";

function Catalogue() {
  return (
    <>
      <CatalogueScreen />
    </>
  );
}

export default Catalogue;
