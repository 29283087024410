import apiClient from "lib/api-client";
import { useQuery } from "@tanstack/react-query";

const getProfile = async () => {
  try {
    const response = await apiClient.post(`/api/v1/users/getUserProfileByIdOrToken`, {});
    const { firstName, lastName, username, profileImage, role } = response.data.user;
    return { firstName, lastName, username, profileImage, role };
  } catch (error) {
    throw new Error(error.response?.data?.message || "Error fetching profile");
  }
};

export const useGetProfile = () => {
  return useQuery({
    queryKey: ["profile"],
    queryFn: () => getProfile(),
    staleTime: 120000,
  });
};
