import React, { useEffect, useState } from "react";
import { Typography, Button, Checkbox, IconButton } from "@mui/material";
import Image from "assets/img/prod2.png";
import Box from "@mui/material/Box";
import Card from "components/ui/Card";
import { useGlobalContext } from "context/GlobalContext";
import { getIcon } from "utils/iconsConfig";
import ViewDetails from "./ViewDetails/ViewDetails";
import ImageModal from "./ImageModal";
import { AsyncImage } from "loadable-image";

const ProductCard = ({
  data,
  addtoCart,
  cardStyle = { borderRadius: "10px", padding: "20px" },
  btnTilte,
  isBtn,
  selectedCartProducts,
  removeCart,
  hoverEffect,
  checkedItems,
  handleCheckboxChange,
  variant = "default",
  presentInCatalog,
  style,
  btnWidth,
  handleShowDetails,
  handleHideDetails,
  handleViewCartDetails,
  addSetToCart,
}) => {
  const { setCountCart } = useGlobalContext();
  let name, icon;

  const [details, setDetails] = useState([]);
  const [collectionModal, setCollectionModal] = useState(false);
  const [setModal, setSetModal] = useState(false);

  //image modal data
  const [imageModal, setImageModal] = React.useState({ isOpen: false, imgUrl: null, designNo: null });

  const handleOpenImageModal = React.useCallback((imgUrl, designNo) => {
    setImageModal({ isOpen: true, imgUrl, designNo });
  }, []);

  const handleCloseImageModal = React.useCallback(() => {
    setImageModal({ isOpen: false, imgUrl: null, designNo: null });
  }, []);

  //end img modal code

  const handleAddToCartBtn = () => {
    let getCartData = JSON.parse(sessionStorage.getItem("CartData")) || [];
    getCartData.push(selectedCartProducts);
    sessionStorage.setItem("CartData", JSON.stringify(getCartData));
    setCountCart((prevCount) => prevCount + 1);
    addtoCart();
  };

  //swithc case
  switch (true) {
    case data.gold !== undefined:
      name = data.gold;
      icon = "gold";
      break;

    case data.bronze !== undefined:
      name = data.bronze;
      icon = "bronze";
      break;

    case data.silver !== undefined:
      name = data.silver;
      icon = "silver";
      break;

    default:
      name = null;
      icon = null;
  }

  //showDetails
  const showDetails = [
    { label: name, Icon: getIcon(icon, variant) },
    { label: data.diamond, Icon: getIcon("diamond", variant) },
    { label: data.weight, Icon: getIcon("goldbar", variant) },
    { label: data.labour, Icon: getIcon("labour", variant) },
    { label: data.date, Icon: getIcon("date", variant) },
  ];

  const hideDetails = [
    { label: name, Icon: getIcon(icon, variant) },
    { label: data.view, Icon: getIcon("catalogView", variant), click: handleViewCartDetails ? () => handleViewCartDetails(data, "view") : null },
    { label: data.cart, Icon: getIcon("catalogCart", variant), click: handleViewCartDetails ? () => handleViewCartDetails(data, "cart") : null },
    { label: data.removeCart, Icon: getIcon("catalogRemoveCart", variant), click: handleViewCartDetails ? () => handleViewCartDetails(data, "removeCart") : null },
    { label: data.clock, Icon: getIcon("catalogClock", variant), click: handleViewCartDetails ? () => handleViewCartDetails(data, "clock") : null },
  ];

  const toggelDetails = () => {
    if (btnTilte === "Show Details") {
      handleShowDetails();
    } else {
      handleHideDetails();
    }
  };

  //Chetan Code:-
  useEffect(() => {
    if (btnTilte === "Show Details") {
      setDetails(hideDetails);
    } else {
      setDetails(showDetails);
    }
  }, [btnTilte]);

  return (
    <>
      <Card width="auto" height="auto" style={{ ...cardStyle, ...style }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }} width={"100%"}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }} width={"100%"}>
            <Box className="right">
              {details.map((detail, index) => (
                <Box key={index} className={`content`}>
                  {detail.click ? (
                    <IconButton disableFocusRipple disableRipple sx={{ p: 0 }} onClick={detail.click}>
                      {detail.Icon}
                    </IconButton>
                  ) : (
                    detail.Icon
                  )}
                  <Typography className={`content-typo content-typo-${index}`} ml={1}>
                    {detail.label}
                  </Typography>
                </Box>
              ))}
            </Box>

            <Box className="middle">
              {(data?.collectionDetails || data?.setDetails) && (
                <>
                  {data?.collectionDetails?.length > 0 && (
                    <>
                      <Box key={data.id + "-collection"} className="newIcon">
                        <IconButton disableRipple disableFocusRipple sx={{ padding: "0px", margin: "0px" }} onClick={() => setCollectionModal(true)}>
                          {getIcon("collection", variant)}
                        </IconButton>
                      </Box>
                      <ViewDetails
                        open={collectionModal}
                        setOpen={setCollectionModal}
                        list={
                          data?.collectionDetails?.map((data) => {
                            return {
                              _id: data._id,
                              name: data.collectionName,
                            };
                          })
                          // data?.collectionDetails
                        }
                        heading={`${details[0]?.label} Collection`}
                        btnName="View Collection"
                      />
                    </>
                  )}
                  {data?.setDetails?.length > 0 && (
                    <>
                      <Box key={data.id + "-set"} className="newIcon">
                        <IconButton disableRipple disableFocusRipple sx={{ padding: "0px", margin: "0px" }} onClick={() => setSetModal(true)}>
                          {getIcon("set", variant)}
                        </IconButton>
                      </Box>
                      <ViewDetails
                        open={setModal}
                        setOpen={setSetModal}
                        list={
                          data?.setDetails?.map((data) => {
                            return {
                              _id: data._id,
                              name: `SET - ${data.setNumber}`,
                            };
                          })
                          // data?.setDetails
                        }
                        heading={`${details[0]?.label} Set`}
                        btnName="View Set"
                      />
                    </>
                  )}
                </>
              )}
            </Box>
          </Box>
          <Box className={`left ${hoverEffect ? "hover-enabled" : ""}`} sx={{ width: "195px", height: "195px" }}>
            {/* <img src={data.img ? data.img : Image} loading="lazy" alt="designImg" style={{ height: isBtn ? "90%" : "100%", borderRadius: "5px" }} onClick={() => handleOpenImageModal(Image, name)} /> */}

            <AsyncImage
              onClick={() => handleOpenImageModal(data.img ? data.img : Image, name)}
              src={data.img ? data.img : Image}
              style={{ display: "block", height: isBtn ? "90%" : "100%", width: "100%", objectFit: "fill", borderRadius: "5px" }}
              loader={<div style={{ background: "#888" }} />}
              error={<div style={{ background: "#eee" }} />}
            />

            {isBtn && !hoverEffect ? (
              <Button
                variant="contained"
                disabled={presentInCatalog ? presentInCatalog : false}
                sx={{
                  backgroundColor: btnTilte === "Add" || btnTilte === "Remove" || btnTilte === "Show Details" || btnTilte === "Hide Details" ? "#FFFFFF" : "#50C878",
                  color: btnTilte === "Add" || btnTilte === "Show Details" ? "#50C878 !important" : btnTilte === "Remove" || btnTilte === "Hide Details" ? "#E77B7B !important" : "#ffffff !important",
                  // pointerEvents: btnTilte === "Add" || btnTilte === "Remove" ? "auto" : "none !important",
                  border: btnTilte === "Remove" || btnTilte === "Hide Details" ? "1px solid #E77B7B" : btnTilte === "Add" || btnTilte === "Show Details" ? "1px solid #50C878" : "none",
                  "&:hover": {
                    backgroundColor: btnTilte === "Add" || btnTilte === "Remove" || btnTilte === "Show Details" || btnTilte === "Hide Details" ? "#FFFFFF" : "#50C878",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#FFFFFF !important",
                    color: "#444444 !important",
                    border: "1px solid #444444 !important",
                  },
                  width: btnWidth ? btnWidth : "110px !important",
                  height: "40px !important",
                  padding: "0px 20px !important",
                  boxShadow: "0",
                  fontFamily: "poppins",
                }}
                onClick={btnTilte === "Add" ? handleAddToCartBtn : btnTilte === "Show Details" || btnTilte === "Hide Details" ? toggelDetails : () => removeCart(selectedCartProducts)}
              >
                {btnTilte ? btnTilte : "Add"}
              </Button>
            ) : (
              ""
            )}
            {/* {hoverEffect && <input type="checkbox" className="hoverCheck" onChange={handleCheckboxChange(data.id)} checked={!!checkedItems[data.id]} />} */}
            {hoverEffect && (
              <Checkbox
                className="hoverCheck"
                disableFocusRipple
                disableRipple
                onChange={handleCheckboxChange(data.id)}
                checked={!!checkedItems[data.id]}
                sx={{
                  p: 0,
                  "& .MuiSvgIcon-root": {
                    fontSize: 24,
                    borderRadius: "5px",
                    border: "1px solid #000",
                    boxSizing: "border-box",
                  },
                  "&.Mui-checked .MuiSvgIcon-root": {
                    color: "#E77B7B",
                    transform: "scale(1.3)", // Scale effect when checked
                    border: "none",
                  },
                }}
              />
            )}
          </Box>
        </Box>
      </Card>
      <ImageModal open={imageModal.isOpen} handleClose={handleCloseImageModal} data={imageModal} />
    </>
  );
};

export default ProductCard;
