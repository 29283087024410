export const settingsRoutes = [
  { name: "Main Activity", path: "main-activity", check: "Main Activity" },
  { name: "Orders", path: "orders", check: "Orders" },
  { name: "Users", path: "users", check: "User" },
  { name: "Roles", path: "roles", check: "Roles" },
  { name: "Rights", path: "rights", check: "Rights" },
  { name: "Profile", path: "profile", check: "Profile" },
  { name: "Archives", path: "archives", check: "Archives" },
  { name: "Limits", path: "limits", check: "Limits" },
];
