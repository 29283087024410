import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUserDetail } from "utils/getUserDetailsHelper";

const ProtectedRoute = ({ children }) => {
  const isLogin = getUserDetail("_id");
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLogin) {
      navigate("/login");
    }
  }, [isLogin, navigate]);

  if (isLogin) {
    return children;
  }

  return null;
};

export default ProtectedRoute;
