import { Grid, createTheme, useMediaQuery, useTheme } from "@mui/material";
import { ProductCard } from "components/ui";
import React, { useCallback, useEffect } from "react";
import { transformClinet } from "utils/transformdata";
import { useInView } from "react-intersection-observer";
import { toast } from "react-toastify";
import ProductSkeleton from "components/ui/Skeleton/ProductSkeleton";
import { ReactComponent as Background } from "assets/icons/bg.svg";
import ReportProductSkeleton from "components/ui/Skeleton/ReportProductSkeleton";
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xll: 1800, // Custom breakpoint
    },
  },
});
const ReportDatas = ({ reportData, handleHideDetails, handleShowDetails, btnTitles, ...props }) => {
  // const theme = useTheme();
  const ismd = useMediaQuery(theme.breakpoints.up("md")); // Matches screens md and larger
  const islg = useMediaQuery(theme.breakpoints.up("lg")); // Matches screens lg and larger
  const isXl = useMediaQuery(theme.breakpoints.up("xl")); // Matches screens xl and larger
  const isXll = useMediaQuery(theme.breakpoints.up("xll")); // Matches screens xl and larger

  // console.log({ ismd, islg, isXl });

  const { ref, inView } = useInView({
    threshold: 1.0,
  });

  const fetchNextPageData = useCallback(() => {
    if (props?.hasNextPage && !props?.isFetchingNextPage) {
      props?.fetchNextPage();
    }
  }, [props?.hasNextPage, props?.isFetchingNextPage]);

  useEffect(() => {
    if (inView) {
      fetchNextPageData();
    }
  }, [inView, props?.hasNextPage, props?.isFetchingNextPage, props?.fetchNextPage]);

  const isreportDataPresent = (data) => {
    if (data && data.pages && data.pages.length > 0) {
      return data.pages.some((page) => page.reportStyles && page.reportStyles.length > 0);
    }
    return false;
  };

  if (props?.isError) {
    toast.error(`${props?.error}`, {
      position: "top-right",
      autoClose: 2000,
    });
  }

  if (props?.isLoading) return <ProductSkeleton count={10} />;

  return (
    <>
      {isreportDataPresent(reportData) ? (
        <>
          <Grid
            container
            className="Grid-padding"
            direction="row"
            sx={{
              flex: "1 1 1",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "32px",
              rowGap: "32px",
              // paddingBottom: "30px",
              maxHeight: islg && !isXl ? "72vh" : isXl ? "80vh" : isXll ? "95vh" : "98vh",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                width: "0px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "transparent",
              },
              scrollbarWidth: "none", // For Firefox
            }}
          >
            {reportData?.pages?.map((page, index) => {
              return page?.reportStyles?.map((curElemt, index) => {
                let newData = transformClinet(curElemt);
                return (
                  <Grid key={index + "-" + newData._id} item className="product">
                    <ProductCard
                      key={index}
                      data={newData}
                      handleHideDetails={() => handleHideDetails(newData._id)}
                      handleShowDetails={() => handleShowDetails(newData._id)}
                      handleViewCartDetails={props.handleViewCartDetails || null}
                      isBtn={true}
                      btnTilte={btnTitles[newData._id] || "Show Details"}
                      btnWidth="85% !important"
                      style={{ maxWidth: "430px", minWidth: "430px" }}
                    />
                  </Grid>
                );
              });
            })}
            {props?.isFetchingNextPage && <ReportProductSkeleton count={10} />}
            <div ref={ref} />
          </Grid>
          {props.timeSpentCatalogModal}
          {props.sortCatalogModal}
          {props.viewIconCatalogModal ? props.viewIconCatalogModal : null}
        </>
      ) : (
        <div
          style={{
            display: "flex",
            textAlign: "center",
            margin: "auto",
            padding: "auto",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <Background />
        </div>
      )}
    </>
  );
};

export default ReportDatas;
