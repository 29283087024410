import axios from "axios";
import config from "configs/config";
import apiClient from "lib/api-client";
import { getUserDetail } from "utils/getUserDetailsHelper";

//--------------- Get All Roles ---------------------\\
export const getAllRoles = async () => {
  const loginUser = getUserDetail("_id");
  console.log(loginUser);
  let response = await apiClient.get(`${config.baseUrl}/api/v1/role/getAllRolesWithRights/${loginUser}`);
  console.log("getAllRoles -->", response);
  return response.data;
};

//--------------- Create Roles ------------------------\\

export const createRoles = async (RoleName) => {
  let response = await apiClient.post(`${config.baseUrl}/api/v1/role/createRole`, {
    roleName: RoleName?.trim(),
  });
  console.log("createRoles -->", response);
  return response.data;
};

//-------------- Edit/Delete Roles -----------------------------\\

export const removeRoles = async (rolesToBeRemoved, isDelete) => {
  let response = await apiClient.post(`${config.baseUrl}/api/v1/role/editRoles`, {
    roleIds: rolesToBeRemoved,
    isDelete: isDelete,
  });
  console.log("removeRoles -->", response);
  return response.data;
};

//------------- Before Remove Check Whether this Role is assign to  any User or Not ------------\\
export const checkUserWithRole = async (rolesToBeRemoved) => {
  let response = await apiClient.post(`${config.baseUrl}/api/v1/role/roleAssignToUser`, {
    roleId: rolesToBeRemoved,
  });
  console.log("checkUserWithRole -->", response);
  return response.data;
};
