import Modal from "components/ui/Modal";
import "./profile.css";
import { useEffect, useMemo, useState } from "react";
import { ChangePassword, EditProfile, ViewProfile } from "./profile-view";
import RemoveModal from "components/ui/RemoveModal/RemoveModal";

export const ProfileModal = ({ open, close }) => {
  const [view, setView] = useState("");
  const [discardChanges, setDiscardChanges] = useState(false);

  const [discardChangesEdit, setDiscardChangesEdit] = useState(false);

  const handleCancel = () => {
    setDiscardChanges(false);
    setView("");
  };

  const modalBtnInfo = useMemo(
    () => [
      {
        btnName: "Discard",
        bgColor: "#E77B7B",
        click: handleCancel,
      },
      {
        btnName: "Keep",
        bgColor: "#50C878",
        // click: () => setRemoveModal(false),
      },
    ],
    []
  );

  const modalBtnInfoEdit = useMemo(
    () => [
      {
        btnName: "Discard",
        bgColor: "#E77B7B",
        click: () => {
          setDiscardChangesEdit(false);
          setView("");
        },
      },
      {
        btnName: "Keep",
        bgColor: "#50C878",
        // click: () => setRemoveModal(false),
      },
    ],
    []
  );

  useEffect(() => {
    let timerID;
    if (!open) {
      timerID = setTimeout(() => {
        setView("");
      }, 500);
    }
    return () => {
      return () => clearTimeout(timerID);
    };
  }, [open]);

  const renderView = () => {
    switch (view) {
      case "edit":
        return <EditProfile onCancel={setDiscardChangesEdit} changeView={setView} />;

      case "changePass":
        return <ChangePassword onCancel={setDiscardChanges} changeView={setView} />;

      default:
        return <ViewProfile changeView={setView} />;
    }
  };

  return (
    <>
      <Modal open={open} handleClose={close} style={{ width: "430px" }}>
        <div className="profile-modal">{renderView()}</div>
      </Modal>

      <RemoveModal open={discardChanges} setOpen={setDiscardChanges} btnInfo={modalBtnInfo} heading="Do you want to Discard Changed Password?" />

      <RemoveModal open={discardChangesEdit} setOpen={setDiscardChangesEdit} btnInfo={modalBtnInfoEdit} heading="Do you want to Discard All Changes?" />
    </>
  );
};
