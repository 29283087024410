import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, IconButton } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material"; // For arrow icons

import { Checkbox } from "@mui/material";
import { Check } from "@mui/icons-material";
import { useSettingContext } from "context/SettingContext";
import RemoveModal from "components/ui/RemoveModal/RemoveModal";
import { getAllRoles } from "../Role/Apis/get-role";
import { convertBackednResponseToLocalStorageFormat, convertPayload, convertSavedDataToPayload, convertSavedRightsToPayload } from "./hooks/convertRights";
import { saveRights } from "./api/save-rights";
import { toast } from "react-toastify";
import { findSpecificPage, findSpecificSubPage, hasAccessSubPage } from "utils/checkUserRigths";
import { usePermissions } from "context/PermissionsContext";

const pageInfo = [
  {
    parentPage: "Catalog",
    parentRights: ["Add", "Edit", "View", "Archive"],
    childPages: [
      {
        childPageName: "Share Catalog",
        childPageRights: ["Add", "View"],
      },
      {
        childPageName: "Catalog Activity",
        childPageRights: ["View"],
      },
    ],
  },
  {
    parentPage: "Catalog Setting",
    parentRights: ["View"],
    childPages: [
      {
        childPageName: "Add Mobile No.",
        childPageRights: ["Add", "Edit", "View"],
      },
      {
        childPageName: "Bulk Mobile No. List",
        childPageRights: ["Add", "View"],
      },
      {
        childPageName: "Catalog Report",
        childPageRights: ["View"],
      },
      {
        childPageName: "Client Report",
        childPageRights: ["View"],
      },
    ],
  },
  {
    parentPage: "Client Activity",
    parentRights: ["View"],
    childPages: [
      {
        childPageName: "Renew & Revoke Access",
        childPageRights: ["Add", "View", "Archive"],
      },
    ],
  },
  {
    parentPage: "Category",
    parentRights: ["View"],

    // childPages: [
    //   {
    //     childPageName: "Design Collection",
    //     childPageRights: ["View"],
    //   },
    //   {
    //     childPageName: "Design Sets",
    //     childPageRights: ["View"],
    //   },
    // ],
  },
  {
    parentPage: "Collection",
    parentRights: ["Add", "Edit", "View", "Archive"],
    // childPages: [
    //   {
    //     childPageName: "Design Sets",
    //     childPageRights: ["View"],
    //   },
    // ],
  },
  {
    parentPage: "Sets",
    parentRights: ["Add", "Edit", "View", "Archive"],
    // childPages: [
    //   {
    //     childPageName: "Design Collection",
    //     childPageRights: ["View"],
    //   },
    // ],
  },
  {
    parentPage: "Settings",
    parentRights: ["View"],
    childPages: [
      {
        childPageName: "Roles",
        childPageRights: ["Add", "Edit", "View", "Archive"],
      },
      {
        childPageName: "User",
        childPageRights: ["Add", "Edit", "View", "Archive"],
      },
      {
        childPageName: "Main Activity",
        childPageRights: ["View"],
      },
      {
        childPageName: "Profile",
        childPageRights: ["Edit", "View"],
      },
      {
        childPageName: "Archives",
        childPageRights: ["Add", "View"],
      },
      {
        childPageName: "Rights",
        childPageRights: ["Edit", "View"],
      },
      {
        childPageName: "Limits",
        childPageRights: ["Edit", "View"],
      },
      {
        childPageName: "Orders",
        childPageRights: ["View"],
      },
    ],
  },
];

//-------------------------------------- Style ------------------------------------\\

const StyledTableContainer = styled(TableContainer)(({}) => ({
  backgroundColor: "transparent",
  border: "1px solid #fff",
  borderRadius: "5px",
  maxHeight: "71vh",
  overflow: "auto",

  "&::-webkit-scrollbar": {
    width: 0,
    height: 0,
  },
  "&": {
    scrollbarWidth: "none",
    msOverflowStyle: "none",
  },
}));

const StyledTable = styled(Table)(({}) => ({
  "& th, & td": {
    fontSize: "18px",
    fontFamily: "karla",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#FFFFFF",
    textAlign: "center",
  },
  "& th": {
    backgroundColor: "#444444",
  },
}));

const StyledTableHeaderCell = styled(TableCell)(({}) => ({
  fontFamily: "Poppins",
  padding: "10px",
  border: "none",
  fontSize: "14px !important",
  fontWeight: "400 !important",
  backgroundColor: "#fff",

  "&:last-child": {
    paddingRight: "25px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: "1px solid #fff",

  "& td": {
    paddingTop: "0px ",
    paddingBottom: "0px ",
  },
}));

const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  fontFamily: "Karla",
  padding: "10px",
  paddingLeft: "15px",
  paddingRight: "15px",
  border: "none", // Remove border to ensure only row borders are visible
  "&::first-of-type": {
    paddingLeft: "5px",
  },
  "&:last-child": {
    paddingRight: "20px",
  },
}));

const CustomUncheckedIcon = styled("span")({
  width: 24,
  height: 24,
  borderRadius: 5, // Rounded corners
  backgroundColor: "transparent", // Background color for unchecked state
  border: "1px solid #ccc", // Border for unchecked state
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const CustomCheckedIcon = styled("span")(({ theme, disabled }) => ({
  width: 24,
  height: 24,
  borderRadius: 5,
  backgroundColor: disabled ? "#A9A9A9" : "#50C878",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#fff",
  opacity: disabled ? 0.6 : 1,
}));

//-------------------------------------- Style ------------------------------------\\

function RightPage() {
  const [expandedPage, setExpandedPage] = useState(null);
  const [checkedRights, setCheckedRights] = useState([]);
  const [newcheckedRights, setNewCheckedRights] = useState([]);

  const [isAdmin, setIsAdmin] = useState(false);
  const { setIsRightPage, setSelectRoleOptions, setEnableSaveRightsBtn, selectRoleBtnName, setVibrateButton, setSelectRoleBtnName, saveRightModalOpen, setSaveRightModalOpen, selectRoleOptions } =
    useSettingContext();

  const permission = usePermissions();

  const hasAccessEditRights = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Settings", permission), "Rights"), "edit");
  console.log("check isAdmin and hasAccessEditRights--->", isAdmin, hasAccessEditRights);

  const handleCheckboxChange = (page, right, isParent = true) => {
    setCheckedRights((prevState) => {
      const updatedRights = [...prevState];

      const pageIndex = updatedRights.findIndex((item) => item.page === page);
      const newRightState = pageIndex !== -1 ? !updatedRights[pageIndex].rights[right] : true;

      if (pageIndex !== -1) {
        updatedRights[pageIndex] = {
          ...updatedRights[pageIndex],
          rights: {
            ...updatedRights[pageIndex].rights,
            [right]: newRightState,
          },
        };
      } else {
        updatedRights.push({
          page,
          rights: {
            [right]: true,
          },
        });
      }

      if (isParent) {
        const parentPageData = findParentPageData(page);
        if (parentPageData) {
          parentPageData?.childPages?.forEach((child) => {
            const childPageIndex = updatedRights.findIndex((item) => item.page === child.childPageName);
            if (child?.childPageRights.includes(right)) {
              if (childPageIndex !== -1) {
                updatedRights[childPageIndex] = {
                  ...updatedRights[childPageIndex],
                  rights: {
                    ...updatedRights[childPageIndex].rights,
                    [right]: newRightState,
                  },
                };
              } else {
                updatedRights.push({
                  page: child?.childPageName,
                  rights: {
                    [right]: newRightState,
                  },
                });
              }
            }
          });
        } else {
          // Mark the parent page checked if this is a child page
          const parentPageData = findParentPageDataByChild(page); // Create this utility to find parent by child

          if (parentPageData) {
            const parentPageIndex = updatedRights.findIndex((item) => item.page === parentPageData.parentPage);
            if (parentPageIndex !== -1) {
              updatedRights[parentPageIndex] = {
                ...updatedRights[parentPageIndex],
                rights: {
                  ...updatedRights[parentPageIndex].rights,
                  [right]: true, // Ensure the parent's right is checked
                },
              };
            } else {
              updatedRights.push({
                page: parentPageData.parentPage,
                rights: {
                  [right]: true,
                },
              });
            }
          }
        }
      }

      const shouldEnableSaveRightsBtn = selectRoleBtnName !== "Select Role" && updatedRights.some((pageItem) => Object.values(pageItem.rights).some((rightChecked) => rightChecked));
      setEnableSaveRightsBtn(shouldEnableSaveRightsBtn);

      // Save to localStorage
      const savedRights = JSON.parse(localStorage.getItem("savedRights")) || [];
      const roleIndex = savedRights.findIndex((role) => role.RoleName === selectRoleBtnName);

      if (roleIndex !== -1) {
        savedRights[roleIndex].CheckedRights = updatedRights;
      } else {
        savedRights.push({
          RoleName: selectRoleBtnName,
          CheckedRights: updatedRights,
        });
      }

      localStorage.setItem("savedRights", JSON.stringify(savedRights));

      return updatedRights;
    });
  };

  const findParentPageDataByChild = (childPageName) => {
    return pageInfo.find((page) => page.childPages?.some((child) => child.childPageName === childPageName));
  };

  // Function to find parent page data based on the page name
  const findParentPageData = (pageName) => {
    return pageInfo.find((page) => page.parentPage === pageName);
  };

  // Utility function to determine if a right is checked
  const isRightChecked = (page, right) => {
    // Find the page in the checkedRights array
    const pageRights = checkedRights.find((item) => item.page === page);
    // Return true if the page and right are checked
    return pageRights?.rights?.[right] || false;
  };

  // Render the checkbox
  const renderCheckboxOrNA = (page, right, allowedRights) => {
    const isChecked = isRightChecked(page, right);

    // Function to handle the vibration trigger
    const handleCheckboxClick = () => {
      if (selectRoleBtnName === "Select Role") {
        setVibrateButton(true);

        return;
      }
    };

    return allowedRights.includes(right) ? (
      <Checkbox
        disableRipple
        disabled={isAdmin || !hasAccessEditRights}
        checked={isAdmin ? true : isChecked}
        onChange={() => {
          if (selectRoleBtnName !== "Select Role") {
            handleCheckboxChange(page, right);
          } else {
            handleCheckboxClick();
          }
        }}
        icon={<CustomUncheckedIcon />}
        checkedIcon={
          <CustomCheckedIcon disabled={isAdmin || !hasAccessEditRights}>
            <Check style={{ fontSize: 18 }} />
          </CustomCheckedIcon>
        }
      />
    ) : (
      "N/A"
    );
  };

  useEffect(() => {
    const fetchRoles = async () => {
      setIsRightPage(true);
      try {
        const roles = await getAllRoles();
        setSelectRoleOptions(roles.data);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchRoles();

    return () => {
      setIsRightPage(false);
      setSelectRoleOptions([]);
      setSelectRoleBtnName("");
      setCheckedRights([]);
      setEnableSaveRightsBtn(false);
      setVibrateButton(false);
      localStorage.removeItem("savedRights");
    };
  }, []);

  // Handle accordion expand/collapse
  const toggleAccordion = (page) => {
    setExpandedPage(expandedPage === page ? null : page); // Toggle between expanded and collapsed state
  };

  const handleDiscardRights = async () => {
    const roles = await getAllRoles();

    setSelectRoleOptions(roles.data);
    setEnableSaveRightsBtn(false);
    setSaveRightModalOpen(false);

    const apiRole = roles.data.find((role) => role.roleName === selectRoleBtnName);
    let checkConvertedData = convertBackednResponseToLocalStorageFormat(apiRole, pageInfo);

    if (checkConvertedData) {
      setCheckedRights(checkConvertedData.CheckedRights);
      setIsAdmin(checkConvertedData.isAdmin);
    }

    localStorage.removeItem("savedRights");
  };

  const handleSaveRights = async () => {
    const rightsToSave = [
      {
        RoleName: selectRoleBtnName,
        CheckedRights: checkedRights.map((item) => ({
          page: item.page,
          rights: item.rights,
        })),
      },
    ];

    const existingRights = JSON.parse(localStorage.getItem("savedRights")) || [];

    const existingRoleIndex = existingRights.findIndex((role) => role.RoleName === selectRoleBtnName);

    if (existingRoleIndex !== -1) {
      // If the role exists, update its rights
      existingRights[existingRoleIndex] = rightsToSave[0];
    } else {
      // If the role doesn't exist, add the new role to the array
      existingRights.push(rightsToSave[0]);
    }

    console.log("------ two -----------");

    // Save the updated list to localStorage
    localStorage.setItem("savedRights", JSON.stringify(existingRights));

    //---------------- here after saving this to localstorage , convert that stored data in required format and then send it to payload call api ------------\\
    // Convert the saved rights to the payload format
    const convertedPayload = convertSavedRightsToPayload(pageInfo);

    //--------------

    const selectedRoleData = convertedPayload.find((role) => role.roleName === selectRoleBtnName);

    if (selectedRoleData) {
      let response = await saveRights(selectedRoleData);
      if (response.status === 200) {
        setEnableSaveRightsBtn(false);
        setSaveRightModalOpen(false);
        localStorage.removeItem("savedRights");
        toast.success(response.message, {
          autoClose: 1000,
          hideProgressBar: false,
        });
      } else {
        alert("Something went wrong !");
      }
    } else {
      console.error("Role not found in convertedPayload for selected role:", selectRoleBtnName);
    }
  };

  const btnInfo1 = [
    {
      btnName: "Discard Rights",
      bgColor: "#E77B7B",
      click: () => {
        handleDiscardRights();
      },
    },
    {
      btnName: "Save Rights",
      bgColor: "#50c878",
      click: () => {
        handleSaveRights();
      },
    },
  ];

  useEffect(() => {
    const fetchRoles1 = async () => {
      try {
        // const savedRights = JSON.parse(localStorage.getItem("savedRights")) || [];

        // const roleData = savedRights.find((role) => role.RoleName === selectRoleBtnName);

        const roles = await getAllRoles();

        // You can implement logic here to handle the data fetched from API
        const apiRole = roles.data.find((role) => role.roleName === selectRoleBtnName);

        let checkConvertedData = convertBackednResponseToLocalStorageFormat(apiRole, pageInfo);

        if (checkConvertedData) {
          setCheckedRights(checkConvertedData.CheckedRights);
          setIsAdmin(checkConvertedData.isAdmin);
        } else {
          setCheckedRights([]);
          setIsAdmin(false);
        }
      } catch (error) {
        console.error("Error fetching roles from API", error);
        setCheckedRights([]);
        setIsAdmin(false);
      }
    };

    // Call the function
    fetchRoles1();
  }, [selectRoleBtnName]);

  //Note :- Need to remove all rights of taht respective role from local storage when user clcik on Discard (Not Mentioned yet )

  return (
    <>
      <div className="Grid-padding" style={{ marginTop: "15px", marginLeft: "6px", marginRight: "11.5px" }}>
        {pageInfo.length ? (
          <StyledTableContainer>
            <StyledTable>
              <TableHead sx={{ position: "sticky", top: "0", zIndex: "1" }}>
                <TableRow>
                  <StyledTableHeaderCell style={{ width: "10px" }}></StyledTableHeaderCell>
                  <StyledTableHeaderCell style={{ width: "220px" }}>Menu</StyledTableHeaderCell>
                  <StyledTableHeaderCell>Add</StyledTableHeaderCell>
                  <StyledTableHeaderCell>Edit</StyledTableHeaderCell>
                  <StyledTableHeaderCell>View</StyledTableHeaderCell>
                  <StyledTableHeaderCell>Archive</StyledTableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pageInfo.map((page, index) => (
                  <React.Fragment key={`parent-${index}`}>
                    <StyledTableRow>
                      <StyledTableBodyCell>
                        {page.childPages ? (
                          <IconButton disableRipple onClick={() => toggleAccordion(page.parentPage)}>
                            {expandedPage === page.parentPage ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                          </IconButton>
                        ) : (
                          ""
                        )}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell>{page.parentPage}</StyledTableBodyCell>
                      <StyledTableBodyCell>{renderCheckboxOrNA(page.parentPage, "Add", page.parentRights)}</StyledTableBodyCell>
                      <StyledTableBodyCell>{renderCheckboxOrNA(page.parentPage, "Edit", page.parentRights)}</StyledTableBodyCell>
                      <StyledTableBodyCell>{renderCheckboxOrNA(page.parentPage, "View", page.parentRights)}</StyledTableBodyCell>
                      <StyledTableBodyCell>{renderCheckboxOrNA(page.parentPage, "Archive", page.parentRights)}</StyledTableBodyCell>
                    </StyledTableRow>

                    {/* Render Child Pages when Accordion is expanded */}
                    {expandedPage === page.parentPage &&
                      page.childPages &&
                      page.childPages.map((childPage, childIndex) => (
                        <StyledTableRow key={`child-${index}-${childIndex}`}>
                          <StyledTableBodyCell></StyledTableBodyCell>
                          <StyledTableBodyCell sx={{ padding: "2px" }}>{childPage.childPageName}</StyledTableBodyCell>
                          <StyledTableBodyCell>{renderCheckboxOrNA(childPage.childPageName, "Add", childPage.childPageRights)}</StyledTableBodyCell>
                          <StyledTableBodyCell>{renderCheckboxOrNA(childPage.childPageName, "Edit", childPage.childPageRights)}</StyledTableBodyCell>
                          <StyledTableBodyCell>{renderCheckboxOrNA(childPage.childPageName, "View", childPage.childPageRights)}</StyledTableBodyCell>
                          <StyledTableBodyCell>{renderCheckboxOrNA(childPage.childPageName, "Archive", childPage.childPageRights)}</StyledTableBodyCell>
                        </StyledTableRow>
                      ))}
                  </React.Fragment>
                ))}
              </TableBody>
            </StyledTable>
          </StyledTableContainer>
        ) : (
          <div style={{ textAlign: "center", padding: "35px" }}>Home</div>
        )}
      </div>

      {saveRightModalOpen && <RemoveModal open={saveRightModalOpen} setOpen={setSaveRightModalOpen} btnInfo={btnInfo1} heading="Confirm granting this role these rights?" />}
    </>
  );
}

export default RightPage;
