import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiClient from "lib/api-client";
import { toast } from "react-toastify";

export const useUploadBulkClients = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ file, catalogId }) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("catalogId", catalogId);

      const response = await apiClient.post("/api/v1/catalogSetting/catalog-client/bulk-list", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries("catalogClientsData");
      toast.success(data?.message || "Bulk clients uploaded successfully!", {
        position: "top-right",
        autoClose: 2000,
      });
    },
    onError: (error) => {
      const errorMessage = error?.response?.data?.message || error.message || "An error occurred";
      toast.error(`Error: ${errorMessage}`, {
        position: "top-right",
        autoClose: 2000,
      });
    },
  });
};
