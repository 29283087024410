// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.setting-data {
  font-family: "karla", sans-serif;
  font-size: 19px;
  font-weight: 300;
  border: "1px solid red";
}
`, "",{"version":3,"sources":["webpack://./src/features/Setting/settingscreen.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,eAAe;EACf,gBAAgB;EAChB,uBAAuB;AACzB","sourcesContent":[".setting-data {\n  font-family: \"karla\", sans-serif;\n  font-size: 19px;\n  font-weight: 300;\n  border: \"1px solid red\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
