import React, { useEffect, useState } from "react";
import { IconButton, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled } from "@mui/material";
import { getIcon } from "utils/iconsConfig";
import { getOrders } from "../apis/get-orders";
import { useLocation, useNavigate } from "react-router-dom";
const StyledTableContainer = styled(TableContainer)(({}) => ({
  backgroundColor: "transparent",
  maxHeight: "76.5vh",
  position: "relative",
  marginBottom: "0px",
  paddingBottom: "5px",

  // Hide scrollbars for Webkit browsers
  "&::-webkit-scrollbar": {
    width: "0px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "transparent",
  },

  // Hide scrollbars for Firefox
  scrollbarWidth: "none", // For Firefox
}));

const StyledTable = styled(Table)(({}) => ({
  "& thead": {
    "& th": {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#FFFFFF",
      textAlign: "center",
      fontFamily: "Poppins",
    },
  },
  "& tbody": {
    "& td": {
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#FFFFFF",
      textAlign: "center",
      fontFamily: "Poppins",
    },
  },
  "& th": {
    backgroundColor: "#444444",
    height: "42px !important",
  },
}));

const StyledTableHeaderCell = styled(TableCell)(({}) => ({
  fontFamily: "Poppins !important",
  position: "sticky",
  top: 0,
  padding: "10px",
  paddingLeft: "15px",
  paddingRight: "15px",
  border: "none",
  zIndex: 1,
  "&:first-child": {
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
  },
  "&:last-child": {
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
  },
}));

const StyledTableBodyCell = styled(TableCell)(({ color }) => ({
  fontFamily: "Karla !important",
  padding: "10px",
  paddingLeft: "5px",
  paddingRight: "5px",
  border: "none",
  color: `${color} !important`,
  "&:first-child": {},
  "&:last-child": {},
}));

const StyledTableRow = styled(TableRow)(({}) => ({
  "& td": {
    padding: "20px",
    paddingTop: "0px",
  },
  "&:first-of-type td": {
    padding: "20px",
    paddingTop: "15px",
  },
}));

const TableSkeleton = ({ columns, rows = 5 }) => (
  <>
    {Array.from(new Array(rows)).map((_, rowIndex) => (
      <StyledTableRow key={`skeleton-${rowIndex}`}>
        {Array.from(new Array(columns)).map((_, cellIndex) => (
          <StyledTableBodyCell key={`skeleton-cell-${cellIndex}`}>
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} animation="wave" width={"80%"} height={"90%"} />
          </StyledTableBodyCell>
        ))}
      </StyledTableRow>
    ))}
  </>
);

const columns = [
  { id: "orderNumber", label: "Order Number" },
  { id: "date", label: "Date & Time" },
  { id: "mob", label: "Client Number" },
  { id: "clientName", label: "Client Name" },
  { id: "catalogNo", label: "Catalog Number" },
  { id: "catalogName", label: "Catalog Nickname" },
  { id: "desings", label: "No. of Design" },
  { id: "view", label: "View Order" },
];

const Orders = () => {
  const [loading, setLoading] = useState(true);
  const [ordersData, setOrdersData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  let preState = location.state;
  {
    /* const handleClick = (name, path) => {
    // const state = { ...preState, name: [...preState.name, `${name}`] };
    const state = { ...preState, name: [preState.name && preState.name[0], `${name}`] };

    navigate(`/Setting/archives/${path}`, { state });
  }; */
  }

  const handleNavigate = (orderNo) => {
    const state = { ...preState, name: [...preState.name, `${orderNo}`] };
    navigate(`/Setting/orders/${1}`, { state });
  };
  useEffect(() => {
    getOrders()
      .then((res) => {
        setOrdersData(res);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="Grid-padding">
      <StyledTableContainer>
        <StyledTable>
          {/* header */}
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableHeaderCell key={column.id}>{column.label}</StyledTableHeaderCell>
              ))}
            </TableRow>
          </TableHead>
          {/* end header */}

          <TableBody>
            {loading ? (
              <TableSkeleton columns={8} rows={5} />
            ) : (
              ordersData?.map((order, _ind) => {
                const { _id, orderNumber, date, mob, catalogNo, catalogName, clientName, desings } = order;
                return (
                  <StyledTableRow key={_id}>
                    <StyledTableBodyCell>{orderNumber}</StyledTableBodyCell>
                    <StyledTableBodyCell>{date}</StyledTableBodyCell>
                    <StyledTableBodyCell>{mob}</StyledTableBodyCell>
                    <StyledTableBodyCell>{clientName}</StyledTableBodyCell>
                    <StyledTableBodyCell>{catalogNo}</StyledTableBodyCell>
                    <StyledTableBodyCell>{catalogName}</StyledTableBodyCell>
                    <StyledTableBodyCell>{desings}</StyledTableBodyCell>

                    <StyledTableBodyCell>
                      <IconButton onClick={() => handleNavigate(orderNumber)}>{getIcon("eye")}</IconButton>
                    </StyledTableBodyCell>
                  </StyledTableRow>
                );
              })
            )}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
    </div>
  );
};

export default Orders;
