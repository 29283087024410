import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Card, CardActions, CardContent, Grid, Skeleton } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import "../styles/catalogueCard.css";

function CatalogueCardSkeleton({ item }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Grid item key={"catalogue-grid" + item.id}>
        <Card key={"catalogue-card" + item.id} sx={{ padding: "12px", maxWidth: "433px", minWidth: "433px" }}>
          <CardContent sx={{ padding: "0px" }}>
            <Box className={`catalague-card-flex-between-center ${isSmallScreen ? "catalague-card-flexDirection-column" : "catalague-card-flexDirection-row"} catalague-card-witdh-100`}>
              <Box className={`catalague-card-flex-between-center ${isSmallScreen ? "catalague-card-witdh-100" : "catalague-card-witdh-auto"}`}>
                <Skeleton variant="text" className="catelogue-card-number" width={100} height={30} />
                <Skeleton variant="text" className="catelogue-card-subheading" width={100} height={30} sx={{ marginLeft: "10px" }} />
              </Box>
              {item.isArchive ? (
                ""
              ) : (
                <Box className={`${isSmallScreen ? "catalague-card-witdh-100" : "catalague-card-witdh-20"}`} mt={isSmallScreen ? 1 : 0} mb={isSmallScreen ? 1 : 0}>
                  <Grid container className="catalague-card-flex-between-center catalague-card-witdh-100">
                    <Grid item xs={6} sm={4} className={`${isSmallScreen ? "catalague-card-flex-center-center" : "catalague-card-flex-between-center"}`}>
                      <Skeleton variant="circular" width={48} height={30} />
                    </Grid>
                    <Grid item xs={6} sm={4} className={`${isSmallScreen ? "catalague-card-flex-center-center" : "catalague-card-flex-between-center"}`}>
                      <Skeleton variant="circular" width={48} height={30} />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>
            <Box>
              <Grid container className={`${isSmallScreen ? "catalague-card-witdh-100" : "catalague-card-witdh-50"}`} pl={isSmallScreen ? 0 : 0.5} mt={1}>
                {Array.from(new Array(3)).map((_, index) => (
                  <Grid item xs={4} key={index} sm={4} gap={0.35} className="catalague-card-flex-between-center">
                    <Skeleton variant="rectangular" className="catalague-card-flex-align-center" width={32} height={32} sx={{ borderRadius: "5px" }} />
                    <Skeleton variant="rectangular" className="catalogue-card-count" width={20} height={20} sx={{ borderRadius: "5px" }} />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box display={"flex"} justifyContent={"right"} mt={3}>
              <Skeleton variant="text" className="catalogue-card-owner" width={"50%"} />
            </Box>
          </CardContent>
          {!item.isArchive ? (
            <CardActions
              sx={{
                gap: isSmallScreen ? 0 : 3.5,
                padding: "0px",
                paddingTop: "12px",
                mt: 0.5,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Skeleton variant="rectangular" width={"50%"} height={45} sx={{ borderRadius: "10px" }} />
              <Skeleton variant="rectangular" width={"50%"} height={45} sx={{ borderRadius: "10px" }} />
            </CardActions>
          ) : (
            <CardActions
              sx={{
                padding: "0px",
                paddingTop: "12px",
                mt: 0.5,
              }}
            >
              <Skeleton variant="rectangular" width={"50%"} height={45} sx={{ borderRadius: "10px" }} />
            </CardActions>
          )}
        </Card>
      </Grid>
    </>
  );
}

export default CatalogueCardSkeleton;
