import React, { useEffect, useState } from "react";
import TimeSpentModal from "../../utils/TimeSpentModal";
import { useParams } from "react-router-dom";
import { useGetCatalogReportKeyData } from "../hooks/useGetCatalogReportKeyData";

const viewsData = {
  totalCount: 23,
  subData: [
    {
      id: 1,
      clientName: "Siddhesh Kotkar",
      mobileNumber: "12396 89654",
      count: 5,
    },
    {
      id: 2,
      clientName: "Anil Dyavanpelli",
      mobileNumber: "12396 89654",
      count: 10,
    },
    {
      id: 3,
      clientName: "Adi Patil",
      mobileNumber: "12396 89654",
      count: 3,
    },
    {
      id: 4,
      clientName: "Sneh Patel",
      mobileNumber: "12396 89654",
      count: 4,
    },
    {
      id: 5,
      clientName: "Vadhi G",
      mobileNumber: "12396 89654",
      count: 2,
    },
  ],
};

const cartsData = {
  totalCount: 20,
  subData: [
    {
      id: 1,
      clientName: "Siddhesh Kotkar",
      mobileNumber: "12396 89654",
      count: 5,
    },
    {
      id: 2,
      clientName: "Anil Dyavanpelli",
      mobileNumber: "12396 89654",
      count: 10,
    },
    {
      id: 3,
      clientName: "Adi Patil",
      mobileNumber: "12396 89654",
      count: 3,
    },
    {
      id: 4,
      clientName: "Sneh Patel",
      mobileNumber: "12396 89654",
      count: 4,
    },
    {
      id: 5,
      clientName: "Vadhi G",
      mobileNumber: "12396 89654",
      count: 2,
    },
  ],
};

const removeCartsData = {
  totalCount: 3,
  subData: [
    {
      id: 1,
      clientName: "Siddhesh Kotkar",
      mobileNumber: "12396 89654",
      count: 1,
    },
    {
      id: 2,
      clientName: "Anil Dyavanpelli",
      mobileNumber: "12396 89654",
      count: 2,
    },
  ],
};

const clocksData = {
  totalCount: "00:20:23",
  subData: [
    {
      id: 1,
      clientName: "Siddhesh Kotkar",
      mobileNumber: "12396 89654",
      count: "00:05:15",
    },
    {
      id: 2,
      clientName: "Anil Dyavanpelli",
      mobileNumber: "12396 89654",
      count: "00:01:08",
    },
    {
      id: 3,
      clientName: "Adi Patil",
      mobileNumber: "12396 89654",
      count: "00:01:05",
    },
    {
      id: 4,
      clientName: "Sneh Patel",
      mobileNumber: "12396 89654",
      count: "00:18:21",
    },
    {
      id: 5,
      clientName: "Vadhi G",
      mobileNumber: "12396 89654",
      count: "00:25:45",
    },
  ],
};

const findModalHeading = (productDetails, type) => {
  const heading = {
    view: {
      mainHeading: `Total Views on ${productDetails?.bronze || productDetails?.silver || productDetails?.gold}`,
      title: "Total View By Each Client",
      totalCountTitle: "Total View",
    },

    cart: {
      mainHeading: `Total Orders of ${productDetails?.bronze || productDetails?.silver || productDetails?.gold}`,
      title: "Total Order By Each Client",
      totalCountTitle: "Total Orders",
    },

    removeCart: {
      mainHeading: `Total Cart Removal of ${productDetails?.bronze || productDetails?.silver || productDetails?.gold}`,
      title: "Total Cart Removal By Each Client",
      totalCountTitle: "Total Cart Removal",
    },
    clock: {
      mainHeading: `Total Time Spent on ${productDetails?.bronze || productDetails?.silver || productDetails?.gold}`,
      title: "Total Time Spent By Each Client",
      totalCountTitle: `Total Time Spent on ${productDetails?.bronze || productDetails?.silver || productDetails?.gold}`,
    },
  };

  return heading[type];
};

const CatalogViewCartModal = ({ open, setOpen, productDetails, viewCartType }) => {
  const { id } = useParams();
  const [totalData, setTotalData] = useState([]);
  const [headings, setHeadings] = useState({});

  const {
    data,
    isLoading: timeIsLoding,
    isError: timeIsError,
    error: timeError,
    hasNextPage,
    fetchNextPage,
    isFetchNextPage,
    isFetching,
  } = useGetCatalogReportKeyData({ catalogId: id, styleId: productDetails?._id, key: viewCartType ? viewCartType : "view" });

  useEffect(() => {
    // if (viewCartType == "view") {
    //   setTotalData(data);
    // } else if (viewCartType == "cart") {
    //   setTotalData(data);
    // } else if (viewCartType == "removeCart") {
    //   setTotalData(data);
    // } else if (viewCartType == "clock") {
    //   setTotalData(data);
    // } else {
    //   setTotalData([]);
    // }

    setHeadings(findModalHeading(productDetails, viewCartType));
  }, [productDetails, viewCartType, id]);

  return (
    <TimeSpentModal
      open={open}
      setOpen={setOpen}
      data={data || []}
      timeIsLoding={timeIsLoding}
      timeIsError={timeIsError}
      timeError={timeError}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
      isFetchNextPage={isFetchNextPage}
      isFetching={isFetching}
      title={headings?.title}
      mainHeading={headings?.mainHeading}
      totalCountTitle={headings?.totalCountTitle}
      viewCartType={viewCartType || ""}
    />
  );
};

export default CatalogViewCartModal;
