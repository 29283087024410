import React from "react";

function CartIcon({ color }) {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5843 14.9813L25.2966 16.4902L25.2952 16.4977L25.2951 16.4977C25.1449 17.254 24.7334 17.9335 24.1327 18.417C23.5347 18.8984 22.7872 19.1551 22.0198 19.1429H10.9287C10.1614 19.1551 9.41383 18.8984 8.81584 18.417C8.21537 17.9336 7.80398 17.2545 7.65361 16.4985L7.65345 16.4977L5.74364 6.95578C5.73642 6.92668 5.73048 6.89707 5.72588 6.86703L4.75174 2H1C0.447715 2 0 1.55228 0 1C0 0.447715 0.447715 0 1 0H5.57143C6.04806 0 6.45844 0.336385 6.55198 0.803743L7.53482 5.71429H16.0044C16.0015 5.80916 16 5.90441 16 6C16 6.58621 16.056 7.15932 16.1631 7.71429H7.93512L9.61484 16.1066L9.61513 16.108C9.67391 16.404 9.83492 16.6699 10.07 16.8591C10.305 17.0483 10.5991 17.1488 10.9008 17.143L10.92 17.1429H22.0286L22.0477 17.143C22.3494 17.1488 22.6435 17.0483 22.8786 16.8591C23.1128 16.6706 23.2734 16.406 23.3328 16.1114L23.5664 14.8864C24.0332 14.9612 24.5121 15 25 15C25.1963 15 25.3911 14.9937 25.5843 14.9813ZM10 23.8571C10 23.7782 10.064 23.7143 10.1429 23.7143C10.2218 23.7143 10.2857 23.7782 10.2857 23.8571C10.2857 23.936 10.2218 24 10.1429 24C10.064 24 10 23.936 10 23.8571ZM10.1429 21.7143C8.95939 21.7143 8 22.6737 8 23.8571C8 25.0406 8.95939 26 10.1429 26C11.3263 26 12.2857 25.0406 12.2857 23.8571C12.2857 22.6737 11.3263 21.7143 10.1429 21.7143ZM22.5714 23.8571C22.5714 23.7782 22.6354 23.7143 22.7143 23.7143C22.7932 23.7143 22.8571 23.7782 22.8571 23.8571C22.8571 23.936 22.7932 24 22.7143 24C22.6354 24 22.5714 23.936 22.5714 23.8571ZM22.7143 21.7143C21.5308 21.7143 20.5714 22.6737 20.5714 23.8571C20.5714 25.0406 21.5308 26 22.7143 26C23.8978 26 24.8571 25.0406 24.8571 23.8571C24.8571 22.6737 23.8978 21.7143 22.7143 21.7143Z"
        fill={color}
      />
    </svg>
  );
}

export default CartIcon;
