import React, { useEffect, useState } from "react";
import { MenuItem, Select } from "@mui/material";
import Button from "components/ui/Button";
import "./stylechild.css";

import { useSettingContext } from "context/SettingContext";

function SelectRoleBtn({ name }) {
  const [selectValue, setSelectValue] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [open, setOpen] = useState(false);

  const {
    selectRoleBtnName,
    setSelectRoleBtnName,
    isRightPage,
    selectRoleOptions,
    setSelectRoleOptions,
    vibrateButton,
    setVibrateButton,
    selectUserBtnName,
    setSelectUserBtnName,
    setEnableSaveRightsBtn,
  } = useSettingContext();

  const originalRoleOptions = selectRoleOptions;

  //------------------ Functions ------------------\\
  const handleChageFun = (e) => {
    const selectedRole = e.target.value;
    setVibrateButton(false);
    setEnableSaveRightsBtn(false);
    setSelectRoleBtnName(selectedRole);
    setSelectValue(selectedRole);

    let remainingRoles = originalRoleOptions.filter((role) => role.roleName !== selectedRole); //role.roleName
    setRoleList(remainingRoles);
  };

  useEffect(() => {
    setSelectRoleBtnName(selectRoleBtnName);
    setSelectValue(selectRoleBtnName);
    setRoleList(originalRoleOptions.filter((role) => role.roleName !== selectRoleBtnName));
  }, [name, selectRoleOptions]);

  const creatBtnName = selectRoleBtnName;

  return (
    <div className="child-header-create-btn">
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        fullWidth
        value={selectValue}
        onChange={(e) => handleChageFun(e)}
        displayEmpty
        className={vibrateButton ? "vibrate" : ""}
        renderValue={(selected) => {
          if (selected === "") {
            return <span>{creatBtnName}</span>;
          }
          return selected;
        }}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        open={open}
        MenuProps={{
          PaperProps: {
            sx: {
              padding: "0px 5px",
              marginTop: "3px",
              backgroundColor: "#FFFFFF",
              color: "#000000",
              borderRadius: "5px",
              maxHeight: "180px",
              maxWidth: "200px",
              overflowX: "scroll",
              "&::-webkit-scrollbar": {
                width: "0px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "transparent",
              },
              scrollbarWidth: "none", // For Firefox
              "& .MuiList-root": {
                "& .MuiMenuItem-root": {
                  margin: "2px",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  overflow: "visible",
                  whiteSpace: "normal",
                  paddingLeft: "10px",
                  paddingRight: "5px",

                  "&:hover": {
                    backgroundColor: "#444444",
                    color: "#FFFFFF",
                    borderRadius: "5px",
                    margin: "2px",
                    paddingLeft: "10px",
                    paddingRight: "5px",
                  },
                },
              },
            },
          },
        }}
        sx={{
          fontFamily: "karla",
          fontSize: "18px",
          lineHeight: "22px",
          fontWeight: 500,
          height: "41px",
          borderRadius: "5px",
          textTransform: "none",

          color: open ? "#000000" : "#FFFFFF", // Change text color based on open state
          backgroundColor: open ? "#FFFFFF" : "#000000",
          "&:hover": {
            backgroundColor: "#FFFFFF",
            color: "#000000",
          },
          border: "1px solid #FFFFFF",

          width: "200px",
          padding: "0px 10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          "& .MuiSelect-select": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            padding: "10px !important",
          },
          "& .MuiSelect-icon": {
            display: "none",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&:focus": {
            backgroundColor: "transparent",
          },
        }}
      >
        {roleList?.map((item, index) => (
          <MenuItem
            key={item.roleName}
            value={item.roleName}
            sx={{
              fontSize: "18px",
              fontFamily: "karla",
              fontWeight: "400",
              justifyContent: "center", // Center content horizontally
              alignItems: "center", // Center content vertically
              textAlign: "center", // Center text
            }}
          >
            {item.roleName}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

export default SelectRoleBtn;
