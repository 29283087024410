import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { getUserDetail } from "utils/getUserDetailsHelper";
import { useNavigate } from "react-router-dom";
import SplashScreen from "features/auth/components/SplashScreen";
import LoginForm from "features/auth/components/LoginForm";
import apiClient from "lib/api-client";
import config from "configs/config";
import { forwordRouteAccordingToRights } from "utils/checkUserRigths";

// Styled container for the entire page
const PageContainer = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

// Styled container for the logo with transition
const LogoContainer = styled.div`
  position: absolute;
  top: ${(props) => (props.logoUp ? "20%" : "50%")};

  transform: translateY(-50%);
  transition: top 1.5s ease;
`;

// Styled container for the login form with transition
const FormContainer = styled.div`
  position: absolute;
  bottom: ${(props) => (props.formVisible ? "0%" : "-50%")};
  opacity: ${(props) => (props.formVisible ? 1 : 0)};
  transition:
    bottom 1.5s ease,
    opacity 1.5s ease;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Login = () => {
  const [logoUp, setLogoUp] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const userId = getUserDetail("_id") || null;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPermissions = async () => {
      if (getUserDetail("token")) {
        try {
          const res = await apiClient.get(`${config.baseUrl}/api/v1/role/permissions/${userId}`);
          const userPermissions = res?.data?.role;
          const nextRoute = forwordRouteAccordingToRights(userPermissions);
          navigate(nextRoute);
        } catch (error) {
          console.error("Error fetching permissions:", error);
        }
      }
    };

    fetchPermissions();
  }, [userId, navigate]);


  useEffect(() => {
    const logoTimeout = setTimeout(() => {
      setLogoUp(true); // Move logo up
    }, 2000); // Wait 2 seconds before moving the logo up

    const formTimeout = setTimeout(() => {
      setFormVisible(true); // Show the login form
    }, 2000); // Wait 3 seconds before showing the login form

    return () => {
      clearTimeout(logoTimeout);
      clearTimeout(formTimeout);
    };
  }, []);

  return (
    <PageContainer>
      <LogoContainer logoUp={logoUp}>
        <SplashScreen />
      </LogoContainer>
      <FormContainer formVisible={formVisible}>
        <LoginForm />
      </FormContainer>
    </PageContainer>
  );
};

export default Login;
