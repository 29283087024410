import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast as myToast } from "react-toastify";
import catalogClientAPIs from "../api/catalogClientAPIs";

export const useCreateCatalogClient = () => {
  const quaryClient = useQueryClient();
  return useMutation({
    mutationFn: catalogClientAPIs.createCatalogClient,
    onSuccess: (response) => {
      const { data } = response;
      if (data?.status === 200) {
        quaryClient.invalidateQueries("catalogClientsData");
        myToast.success(data?.message || "Catalog Client created successfully!", {
          position: "top-right",
          autoClose: 2000,
        });
      } else {
        myToast.error(`Error: ${data?.message || "Failed to create catalog client"}`, {
          position: "top-right",
          autoClose: 2000,
        });
      }
    },
    onError: (error) => {
      const errorMessage = error.response?.data?.message || error.message || "An error occurred";
      myToast.error(`Error: ${errorMessage}`, {
        position: "top-right",
        autoClose: 2000,
      });
    },
  });
};
