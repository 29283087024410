import React, { useContext, useEffect, useState } from "react";

import "../../../collection/styles/collectionScreen.css";

import Home from "pages/Home";
import Card from "components/ui/Card";

import { Grid, Typography } from "@mui/material";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import SortContext from "context/SortContext";
import config from "configs/config";
import axios from "axios";
import useGetCollectionCategory from "hooks/useGetCollectionCategory";
import { encodeStr } from "utils/encodeDecodeStr";
import { GlobalContext, useGlobalContext } from "context/GlobalContext";
import LoadingSmallCard from "components/ui/Skeleton/LoadingSmallCard";
import { transformData } from "utils/transformdata";
import { findSpecificPage, hasAccessPage } from "utils/checkUserRigths";
import { usePermissions } from "context/PermissionsContext";
// let SubCollectionData = [
//   {
//     Collection_Item: "Necklace",
//     Count: 2230,
//     link: "1",
//   },
//   {
//     Collection_Item: "Earrings",
//     Count: 2230,
//     link: "2",
//   },
//   {
//     Collection_Item: "Rings",
//     Count: 2230,
//     link: "3",
//   },
//   {
//     Collection_Item: "Pendants",
//     Count: 2230,
//     link: "4",
//   },
//   {
//     Collection_Item: "Bracelets",
//     Count: 2230,
//     link: "5",
//   },
// ];

function SubCollection() {
  console.log("====================== Inside Sub Collection ===================");
  const { itemId, subcategoryId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const catalogUpdate = location?.state?.cataloguUpdate;

  let [subcollectionData, setSubCollectionData] = useState([]);
  const { setSortOptions, setSelectedSortValue, search, selectedSortValue, setIsEdit } = React.useContext(SortContext);
  const { setAddDesignBtn, addDesignBtn } = useGlobalContext();
  const [loading, setLoading] = useState(true);

  let preState = location.state;
  let collectioCreateOrUpdate = location?.state?.collection;
  const permission = usePermissions();

  const hasAccessEditCollection = hasAccessPage(findSpecificPage("Collection", permission), "edit");

  useEffect(() => {
    setSortOptions([
      { id: 1, name: "Alphabetical Order (A to Z) " },
      { id: 2, name: "Alphabetical Order (Z to A)" },
      { id: 3, name: "Design Quantity (High to Low)" },
      { id: 4, name: "Design Quantity (Low to High)" },
    ]);

    if (hasAccessEditCollection) {
      setAddDesignBtn(collectioCreateOrUpdate);
    } else {
      setAddDesignBtn(false);
    }

    return () => {
      setSortOptions([]);
      setSelectedSortValue(null);
      setAddDesignBtn(false);
    };
  }, [collectioCreateOrUpdate]);

  const payload = {
    pageNumber: 1,
    limit: 100,
    searchTerm: preState.name[0], //Winter Collection
    sortBy: selectedSortValue == "1" || selectedSortValue == "2" ? "alphabetical" : selectedSortValue == "3" || selectedSortValue == "4" ? "quantity" : "", //alphabetical //quantity
    order: selectedSortValue == "1" || selectedSortValue == "4" ? "asc" : "desc", //asc //desc
  };
  const { data: subcollections, isLoading, error, isError } = useGetCollectionCategory(payload);

  useEffect(() => {
    if (subcollections?.collections && subcollections?.collections.length === 0) {
      navigate("/Collection");
    } else if (subcollections?.collections && Array.isArray(subcollections.collections) && subcollections.collections.length > 0) {
      let presentCategories = subcollections.collections[0]?.categories || [];
      let allCategoryCount = subcollections.collections[0]?.collectionDetails.numberOfStyles || 0;
      let allCategory = { category: "All", count: allCategoryCount };
      setSubCollectionData([allCategory, ...presentCategories]);

      setLoading(false);

      //------------ saving data in session storage -----------\\
      const desingsIds = subcollections?.collections[0]?.designs?.map((desing) => transformData(desing));

      sessionStorage.setItem("collectionsProductsData", JSON.stringify(desingsIds));
      sessionStorage.setItem("collectionIdOfProducts", JSON.stringify(subcollections?.collections[0]?.collectionDetails?._id));
    }
  }, [subcollections, addDesignBtn, navigate]);

  useEffect(() => {
    if (subcollectionData.length > 0) {
      setLoading(false);
    }
  }, [subcollectionData]);

  const handleClick = (ele) => {
    const state = { ...preState, name: [`${preState.name[0]}`, `${ele.category} - ${ele.count}`] };
    navigate(`/Collection/${itemId}/${encodeStr(ele.category)}`, { state });
  };

  if (isLoading) return <LoadingSmallCard count={24} />;
  if (isError) return <div>Error: {error.message}</div>;

  return (
    <>
      {!loading && subcollectionData && subcollectionData.length > 0 ? (
        <Grid
          container
          className="Grid-padding"
          // rowSpacing={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 3 }}
          // columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 3 }}
          // justifyContent="space-beetween"
          // alignItems="center"
          // rowGap={"16px"}
          direction="row"
          justifyContent="flex-start"
          sx={{ flex: "1 1 1", display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "30px", rowGap: "32px" }}
        >
          {subcollectionData &&
            subcollectionData.map((ele, i) => (
              <Grid
                item
                //  xs={12} sm={12} md={4} lg={3} xl={2.3}
                key={i}
                onClick={() => handleClick(ele)}
              >
                <Card style={{ minWidth: 275, maxWidth: "317px", height: 62, borderRadius: "5px" }}>
                  <Typography mr={"auto"} fontSize={"20px"} fontWeight={400} fontFamily={"Karla"}>
                    {ele.category}
                  </Typography>
                  <Typography ml={"auto"} fontSize={"20px"} fontWeight={400} fontFamily={"Karla"}>
                    {ele.count}
                  </Typography>
                </Card>
              </Grid>
            ))}
        </Grid>
      ) : (
        <Home />
      )}
    </>
  );
}

export default SubCollection;
