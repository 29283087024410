import { Box, Divider, Grid, Typography } from "@mui/material";
import Modal from "components/ui/Modal";
import "./TimeSpent.css";
import React, { useCallback, useEffect } from "react";
import { covertingDateAndTimeClinet } from "./covertingDateAndTimeClinet";
import { capitalEachLetter } from "utils/capitalEachLetter";
import { useInView } from "react-intersection-observer";
import { convertingSecondIntoHoursFormat } from "utils/convertingSecondIntoHoursFormat";

const sliceNumber = (number) => {
  return number && number.split(" ")[1];
};

const TimeSpentModal = ({ open, setOpen, data, title, mainHeading, totalCountTitle, viewCartType, ...props }) => {
  // console.log("key ==============>", viewCartType);
  const { ref, inView } = useInView({
    threshold: 1.0,
  });
  const handleClose = () => {
    setOpen(false);
  };

  // console.log("data ==========>", data);

  const findLastIndex = (data) => {
    let lastIndex = data?.pages?.reduce((acc, cur) => {
      return acc + (cur?.report?.length || 0);
    }, 0);
    return lastIndex;
  };

  const findTotalCount = (data) => {
    let totalCount = (data && data?.pages && data.pages[0]?.totalTimeSpent) || (data && data?.pages && data.pages[0]?.totalCount) || 0;
    // let totalCount = normalizeData(data).reduce((acc, cur) => {
    //   let newData = formatData(cur);
    //   acc += newData.count;
    // }, 0);
    if (viewCartType === "clock") {
      return convertingSecondIntoHoursFormat(totalCount);
    }
    return totalCount;
  };

  const normalizeData = (data) => {
    if (Array.isArray(data?.subData)) {
      return data?.subData;
    } else if (Array.isArray(data?.pages)) {
      return data?.pages.reduce((acc, page) => {
        return acc.concat(page?.report || []);
      }, []);
    }
    return [];
  };

  const formatData = (data) => {
    let newData = {};
    if (viewCartType) {
      newData = {
        ...newData,
        count: viewCartType === "clock" ? convertingSecondIntoHoursFormat(data[viewCartType] || 0) : data[viewCartType] || 0,
      };
    } else {
      newData = {
        ...newData,
        count: data?.count || data?.timeSpentOnCatalog || null,
      };
    }
    return {
      ...newData,
      id: data.id || data?._id,
      clientName: capitalEachLetter(data?.clientName) || capitalEachLetter(data?.clientName) || null,
      dateTime: data?.dateTime || covertingDateAndTimeClinet(data?.loginDate) || null,
      mobileNumber: data?.mobileNumber || sliceNumber(data?.clientNumber) || null,
    };
  };

  const fetchNextPageData = useCallback(() => {
    if (props?.hasNextPage && !props?.isFetchingNextPage) {
      props?.fetchNextPage();
    }
  }, [props?.hasNextPage, props?.isFetchingNextPage]);

  useEffect(() => {
    if (inView) {
      fetchNextPageData();
    }
  }, [inView, props?.hasNextPage, props?.isFetchingNextPage, props?.fetchNextPage]);

  let lastIndex = data?.subData?.length || findLastIndex(data);
  let totalCount = data?.totalCount || findTotalCount(data) || 0;
  let newData = normalizeData(data);

  if (props?.timeIsLoding)
    return (
      <Modal open={open} handleClose={handleClose} style={{ width: "440px", mr: 2.5, scroll: "hidden" }}>
        <Grid container spacing={3}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography className="time-spent-catalog time-spent-catalog-heading">Loding ...</Typography>
          </Grid>
        </Grid>
      </Modal>
    );

  if (props?.timeIsError) {
    return (
      <Modal open={open} handleClose={handleClose} style={{ width: "440px", mr: 2.5, scroll: "hidden" }}>
        <Grid container spacing={3}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography className="time-spent-catalog time-spent-catalog-heading">{`${props?.timeError}`}</Typography>
          </Grid>
        </Grid>
      </Modal>
    );
  }

  return (
    <Modal open={open} handleClose={handleClose} style={{ width: "440px", mr: 2.5, scroll: "hidden" }}>
      <Grid container spacing={3}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography className="time-spent-catalog time-spent-catalog-heading">{mainHeading}</Typography>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Divider orientation="horizontal" sx={{ border: "1px solid #000000" }} />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography className="time-spent-catalog time-spent-catalog-subheading">{totalCountTitle}</Typography>
            <Typography className="time-spent-catalog time-spent-catalog-subheading">{totalCount}</Typography>
          </Box>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Divider orientation="horizontal" sx={{ border: "1px solid #000000" }} />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography className="time-spent-catalog time-spent-catalog-subheading">{title}</Typography>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              height: "auto",
              maxHeight: "51vh",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "0px",
                height: "0px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "transparent",
              },
              outline: "none",
            }}
            tabIndex={0}
          >
            <Grid container spacing={2.5} sx={{ height: "100%" }}>
              {newData?.map((curElement, index) => {
                let element = formatData(curElement);
                return (
                  <>
                    <Grid item key={`grid-${element.id}`} xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Box key={`box-${element.id}`} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: lastIndex !== index + 1 ? "5px 0px" : "0px" }}>
                        {element.clientName && (
                          <Typography className="time-spent-catalog time-spent-font-size-18">
                            {element.clientName}
                            {element?.mobileNumber ? ` - ${element?.mobileNumber}` : ""}
                          </Typography>
                        )}
                        {element.dateTime && <Typography className="time-spent-catalog time-spent-font-size-18">{element.dateTime}</Typography>}
                        <Typography className="time-spent-catalog time-spent-font-size-18">{element.count}</Typography>
                      </Box>
                    </Grid>
                    {lastIndex !== index + 1 && (
                      <Grid key={`divider-${element.id}`} item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Divider
                          orientation="horizontal"
                          sx={{
                            borderColor: "#444444",
                          }}
                        />
                      </Grid>
                    )}
                  </>
                );
              })}
              {props?.isFetchingNextPage && (
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography className="time-spent-catalog time-spent-catalog-heading">Loding ...</Typography>
                </Grid>
              )}
              <div ref={ref} />
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default TimeSpentModal;
