// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.child-header {
  /* border: 1px solid rgb(81, 255, 0); */
  position: sticky;
  top: 81px;
  z-index: 999;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr;
  background-color: #000000;
}

.child-header-sub {
  display: flex;
  grid-auto-flow: column;
  align-items: center;
  gap: 1rem;
  /* border: 1px solid rgb(255, 0, 43); */
  padding: 10px;
}

.child-header-sub span {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
}

.child-header-sort {
  padding-left: 10px;
  display: flex;
  align-items: center;
  /* border: 1px solid rgb(255, 0, 43); */
}

.child-header-create-btn {
  padding-right: 10px;
  display: flex;
  align-items: center;
}

.child-header-create-btn-catalog-setting {
  padding-right: 30px;
  display: flex;
  align-items: center;
}

.setting-child-header-create-btn {
  width: 160px;
  height: 41px;
  border-radius: 5px;
  padding: 10px;

  font-family: "karla";
  font-weight: 500;
  font-size: 18px;
  border: 1px solid #fff;
  cursor: pointer;
  margin-left: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/layouts/childHeader.css"],"names":[],"mappings":"AAAA;EACE,uCAAuC;EACvC,gBAAgB;EAChB,SAAS;EACT,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,0BAA0B;EAC1B,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;EACT,uCAAuC;EACvC,aAAa;AACf;;AAEA;EACE,kCAAkC;EAClC,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uCAAuC;AACzC;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,aAAa;;EAEb,oBAAoB;EACpB,gBAAgB;EAChB,eAAe;EACf,sBAAsB;EACtB,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".child-header {\n  /* border: 1px solid rgb(81, 255, 0); */\n  position: sticky;\n  top: 81px;\n  z-index: 999;\n  display: grid;\n  grid-auto-flow: column;\n  grid-template-columns: 1fr;\n  background-color: #000000;\n}\n\n.child-header-sub {\n  display: flex;\n  grid-auto-flow: column;\n  align-items: center;\n  gap: 1rem;\n  /* border: 1px solid rgb(255, 0, 43); */\n  padding: 10px;\n}\n\n.child-header-sub span {\n  font-family: \"Poppins\", sans-serif;\n  font-weight: 400;\n  font-size: 24px;\n  line-height: 36px;\n}\n\n.child-header-sort {\n  padding-left: 10px;\n  display: flex;\n  align-items: center;\n  /* border: 1px solid rgb(255, 0, 43); */\n}\n\n.child-header-create-btn {\n  padding-right: 10px;\n  display: flex;\n  align-items: center;\n}\n\n.child-header-create-btn-catalog-setting {\n  padding-right: 30px;\n  display: flex;\n  align-items: center;\n}\n\n.setting-child-header-create-btn {\n  width: 160px;\n  height: 41px;\n  border-radius: 5px;\n  padding: 10px;\n\n  font-family: \"karla\";\n  font-weight: 500;\n  font-size: 18px;\n  border: 1px solid #fff;\n  cursor: pointer;\n  margin-left: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
