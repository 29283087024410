import React, { useEffect } from "react";
import Modal from "./Modal";
import { IconButton, Typography } from "@mui/material";
import { getIcon } from "utils/iconsConfig";

const ImageModal = ({ open, handleClose, data }) => {
  return (
    <Modal open={open} handleClose={handleClose} style={{ width: "365px", height: "430px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography id="modal-drawer-title" variant="h5" style={{ fontFamily: "Poppins", fontWeight: "500", fontSize: "24px" }}>
          {data.designNo}
        </Typography>
        <IconButton disableFocusRipple disableRipple sx={{ p: 0 }} onClick={handleClose}>
          {getIcon("close")}
        </IconButton>
      </div>

      <img
        src={data.imgUrl}
        style={{
          width: "317px",
          height: "304px",
          objectFit: "contain",
        }}
      />
    </Modal>
  );
};

export default ImageModal;
