import { FormControl, FormLabel, Grid, MenuItem, OutlinedInput, Select } from "@mui/material";
import React from "react";
import { ReactComponent as CustomIcon } from "assets/icons/selectIcon.svg";

export const OutlineInput = ({ item, onChange, value, isEditUser }) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      sx: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        borderRadius: "5px",
        backgroundColor: "#FFFFFF",
        marginTop: "5px",
        padding: "0px 3px",
        color: "#000000",
        // border: "1px solid red",
        "& .MuiList-root": {
          "& .MuiMenuItem-root": {
            backgroundColor: "#FFFFFF",
            fontFamily: "Karla",
            fontSize: "18px",
            lineHeight: "21.04px",
            fontWeight: 400,
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            margin: "3px",
            "&:hover": {
              fontWeight: 400,
              backgroundColor: "#444444",
              color: "#FFFFFF",
              borderRadius: "5px",
              margin: "3px",
            },
          },
        },
      },
    },
  };

  return (
    <Grid item width={"100%"} xs={12} sm={6} md={4} lg={4} xl={4} sx={{ padding: "10px", paddingLeft: "25px", paddingRight: "20px" }}>
      <FormControl fullWidth>
        <FormLabel>{item.title}</FormLabel>
        {item.type === "select" ? (
          <Select
            name={item.name}
            IconComponent={CustomIcon}
            value={value}
            onChange={onChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            MenuProps={MenuProps}
            renderValue={(selected) => {
              if (selected === "") {
                return (
                  <span disabled style={{ opacity: "0.5" }}>
                    {item.placeholder}
                  </span>
                );
              }
              return selected;
            }}
          >
            {item.roleData.map((role) => (
              <MenuItem key={role._id} value={role.role}>
                {role.role}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <OutlinedInput
            disabled={isEditUser}
            readOnly={isEditUser}
            autoComplete="off"
            type={item.type}
            placeholder={item.placeholder}
            name={item.name}
            id={item.id}
            value={value}
            onChange={(e) => onChange(e)}
          />
        )}
      </FormControl>
    </Grid>
  );
};
