import React, { useContext, useState } from "react";
import { ReactComponent as Set } from "assets/sidebar/enable/sets.svg";
import { ReactComponent as Category } from "assets/sidebar/enable/category.svg";
import { ReactComponent as Collection } from "assets/sidebar/enable/collection.svg";
import { ReactComponent as Archive } from "assets/sidebar/enable/archive.svg";
import { ReactComponent as Sort } from "assets/icons/sort.svg";
import { ReactComponent as Filter } from "assets/filter/filter.svg";
import { ReactComponent as EditIcon } from "assets/icons/CatalogueCard/edit.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { IconButton, styled } from "@mui/material";
import SortContext from "context/SortContext";
import SortModal from "components/ui/SortModal";
import FilterModal from "components/ui/FilterModal/FilterModal";
import ArchiveRemoveModal from "components/ui/RemoveModal/RemoveModal";
import { useFilter } from "context/FilterContext";
import { useGlobalContext } from "context/GlobalContext";
import CreateBtn from "./CreateBtn";

import Button from "components/ui/Button";
import { useNavigate } from "react-router-dom";

const StyledButton = styled(Button)(({ theme, variant, disabled }) => ({
  width: "110px",
  fontFamily: "karla",
  fontWeight: 400,
  fontSize: "16.8px",
  lineHeight: "21.04px",
  height: "41px",
  borderRadius: "5px",
  color: "#FFF",
  border: `1px solid #fff`,
  textTransform: "none",
  backgroundColor: "transparent",

  "&:hover": {
    backgroundColor: "#fff",
    color: "#000",
  },
}));

function IconsChild({ location }) {
  const navigate = useNavigate();

  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [sortOpen, setSortOpen] = useState(false);
  const { sortOptions, isEdit, setIsEdit, isEditClick, setIsEditClick, isCollectionEdit, setIsCollectionEdit } = useContext(SortContext);
  const handleClose = () => setSortOpen(false);

  const state = location.state;
  const editCatalogue = state && state.edit ? state.edit : false;
  const viewCatalogueProduct = state && state.view ? state.view : false;

  const { isFilter } = useFilter();
  const { createBtn, removeIcon, setTrashModalOpen, isArchive, setIsArchive, isArchiveBtn, setShowCollectionTrashIcon, addDesignBtn } = useGlobalContext();

  const handleSetEditClick = () => {
    setIsEdit("click");
    setIsEditClick(true);

    sessionStorage.removeItem("updateProductsData");
  };

  const handleCollectionEditClick = () => {
    setIsCollectionEdit("click");
    setShowCollectionTrashIcon(true);
    sessionStorage.removeItem("updateProductsData");
  };

  const handleCollectionUpdate = () => {
    //set styleids in global state and then save it in session storage

    let existData = JSON.parse(sessionStorage.getItem("collectionsProductsData"));
    sessionStorage.setItem("updateProductsData", JSON.stringify(existData));

    navigate("/Category", {
      state: {
        innerChnages: true,
        catalogueName: `Collection`, //catalogueName same as SETNAME
        collectionUpdate: true,
        backPath: location?.pathname,
        backName: location?.state?.name,
        openCart: false,
      },
    });
  };

  const btnInfo = [
    {
      btnName: isArchive ? "UnArchive" : "Archive",
      bgColor: "#E77B7B",
      click: () => {
        setIsArchive(!isArchive);
        setArchiveModalOpen(false);
      },
    },
    {
      btnName: "Keep",
      bgColor: "#50C878",
      click: () => setArchiveModalOpen(false),
    },
  ];

  if (!viewCatalogueProduct && !editCatalogue) {
    return (
      <>
        {!removeIcon && isFilter && (
          <div className="child-header-sort">
            <IconButton onClick={() => setFilterModalOpen(true)} disableRipple disableFocusRipple>
              <Filter />
            </IconButton>
          </div>
        )}
        {removeIcon && (
          <div className="child-header-sort">
            <IconButton onClick={() => setTrashModalOpen(true)}>
              <TrashIcon />
            </IconButton>
          </div>
        )}
        {/* {filterModalOpen && <FilterModal open={filterModalOpen} setOpen={setFilterModalOpen} />} */}
        <FilterModal open={filterModalOpen} setOpen={setFilterModalOpen} isCollection={location?.state?.collection ? true : false} />

        {createBtn && <CreateBtn location={location} />}

        {(isEdit === "view" || isCollectionEdit === "view") && (
          <IconButton
            onClick={isCollectionEdit === "view" ? handleCollectionEditClick : handleSetEditClick}
            disableTouchRipple
            sx={{
              marginLeft: "10px",
              "&:hover": { backgroundColor: "transparent" }, // Removes hover background color
            }}
          >
            <EditIcon />
          </IconButton>
        )}
        {/* Add Design  btn on subcollection page  */}
        {addDesignBtn && (
          <div className="child-header-sort" onClick={() => handleCollectionUpdate()}>
            <StyledButton>Add Design</StyledButton>
          </div>
        )}

        {!removeIcon && sortOptions?.length > 0 && (
          <div className="child-header-sort">
            <IconButton onClick={() => setSortOpen(true)} disableRipple disableFocusRipple>
              <Sort />
            </IconButton>
          </div>
        )}

        <SortModal open={sortOpen} handleClose={handleClose} title="Sort By" list={sortOptions} />
      </>
    );
  } else if (editCatalogue) {
    return (
      <>
        {createBtn && <CreateBtn location={location} />}
        {removeIcon && (
          <div className="child-header-sort">
            <IconButton onClick={() => setTrashModalOpen(true)} disableRipple disableFocusRipple>
              <TrashIcon />
            </IconButton>
          </div>
        )}
        {isArchiveBtn && (
          <div className="child-header-sort">
            <IconButton onClick={() => setArchiveModalOpen(true)} disableRipple disableFocusRipple>
              <Archive />
            </IconButton>
          </div>
        )}
        {archiveModalOpen && <ArchiveRemoveModal open={archiveModalOpen} setOpen={setArchiveModalOpen} heading="Do you want to Archive the Catalog?" btnInfo={btnInfo} />}
      </>
    );
  } else {
    {
      console.log("------------>  3  <-------------");
    }
    return null;
  }
}

export default IconsChild;
