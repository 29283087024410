export const isChanges = (catalogueName, newName, isArchive) => {
  const originalCatalogProducts = JSON.parse(sessionStorage.getItem("catalogWithProduct")).productData || [];
  const updateCatalogProducts = JSON.parse(sessionStorage.getItem("updateProductsData")) || [];

  // Check if all IDs in originalCatalogProducts are present in updateCatalogProducts
  const originalMismatch = originalCatalogProducts.some((originalProduct) => {
    return !updateCatalogProducts.find((product) => product._id === originalProduct._id || product.id === originalProduct._id);
  });

  // Check if all IDs in updateCatalogProducts are present in originalCatalogProducts
  const updatedMismatch = updateCatalogProducts.some((updateProduct) => {
    return !originalCatalogProducts.find((product) => product._id === updateProduct._id || product.id === updateProduct.id);
  });

  const hasMismatch = originalMismatch || updatedMismatch || originalCatalogProducts.length !== updateCatalogProducts.length;
  return hasMismatch || (catalogueName && newName && catalogueName !== newName) || isArchive;
};
