import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import apiClient from "lib/api-client";
import { getUserDetail } from "utils/getUserDetailsHelper";
import { toast } from "react-toastify";

const editProfile = async ({ firstName, lastName }) => {
  const user = getUserDetail("_id");
  const body = {
    userId: user,
    firstName: firstName,
    lastName: lastName,
  };

  const response = await apiClient.post(`/api/v1/users/editProfile`, body);
  return response.data;
};

export const useEditProfile = ({ onCompleted }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editProfile,
    onSuccess: (data) => {
      if (data.status === 200) {
        toast.success(data.message);
        queryClient.invalidateQueries(["profile"]);
        onCompleted();
      } else {
        toast.error(data.message);
      }
    },
    onError: (error) => {
      console.log("Error in Edit profile", error.message);
    },
  });
};
