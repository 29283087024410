import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, IconButton } from "@mui/material";
import { ReactComponent as ClientActivityEdit } from "assets/catalogSetting/edit.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as Background } from "assets/icons/bg.svg";
import { useSettingContext } from "context/SettingContext";
import useGetAllUsersData from "features/Setting/users/hooks/useGetAllUsers";
import { ToastContainer, toast } from "react-toastify";
import { usePermissions } from "context/PermissionsContext";
import { findSpecificPage, findSpecificSubPage, hasAccessSubPage } from "utils/checkUserRigths";
import RemoveUser from "components/ui/RemoveModal/RemoveModal";
import useDeleteUser from "../hooks/useDeleteUser";

// Styled component for the entire table with padding
const StyledTableContainer = styled(TableContainer)(({}) => ({
  backgroundColor: "transparent",
  maxHeight: "76.5vh",
  position: "relative",
  marginBottom: "0px",
  paddingBottom: "5px",
  // border: "1px solid red",

  // Hide scrollbars for Webkit browsers
  "&::-webkit-scrollbar": {
    width: "0px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "transparent",
  },

  // Hide scrollbars for Firefox
  scrollbarWidth: "none", // For Firefox
}));

const StyledTable = styled(Table)(({}) => ({
  "& th, & td": {
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#FFFFFF",
    textAlign: "center",
  },
  "& th": {
    backgroundColor: "#444444",
  },
}));

const StyledTableHeaderCell = styled(TableCell)(({}) => ({
  fontFamily: "Poppins",
  padding: "10px",
  paddingLeft: "15px",
  paddingRight: "15px",
  border: "none",
  position: "sticky",
  top: 0,
  zIndex: 1,
  "&:first-child ": {
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
  },
  "&:last-child": {
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
    paddingRight: "25px",
  },
}));

const StyledTableBodyCell = styled(TableCell)(({}) => ({
  fontFamily: "Karla",
  padding: "10px",
  paddingLeft: "15px",
  paddingRight: "15px",
  border: "none",
  "&:first-child": {
    paddingLeft: "5px",
  },
  "&:last-child": {
    paddingRight: "20px",
  },
}));

const StyledTableRow = styled(TableRow)(({}) => ({
  "& td": {
    paddingTop: "5px",
    // border: "1px solid red",
  },
  "&:first-of-type td": {
    paddingTop: "10px",
  },
  "&:last-child td": {
    paddingBottom: "20px",
  },
}));

const UsersScreen = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const { setCreateBtn, setCreateBtnName, setCreateUser, setCancelEditUserModal, userCreateStatus, setUserCreatedStatus } = useSettingContext();
  const { data, error, isLoading, isError, isFetching, refetch } = useGetAllUsersData();
  const { mutate: deleteUser, isError: deleteIsError, isSuccess: deleteIsSuccess, error: deleteError } = useDeleteUser();
  const [removeUserModal, setRemoveUserModal] = useState(false);
  const [currectUserData, setCurrentUserData] = useState({});
  const permission = usePermissions();
  const hasAccessAddUser = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Settings", permission), "User"), "add");
  const hasAccessEditUser = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Settings", permission), "User"), "edit");
  const hasAccessDeleteUser = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Settings", permission), "User"), "archive");

  const handlEditUserInfo = (userData) => {
    let state = {
      ...location.state,
      isEditUser: true,
    };
    navigation(`/Setting/users/editUser/${userData._id}`, { state });
  };

  const handlDeleteUserInfo = (userData) => {
    setRemoveUserModal(true);
    setCurrentUserData(userData);
  };

  const btnInfo = [
    {
      btnName: "Remove",
      bgColor: "#E77B7B",
      click: () => {
        deleteUser({ userId: currectUserData._id });
        setRemoveUserModal(false);
      },
    },
    {
      btnName: "Keep",
      bgColor: "#50C878",
      click: () => setRemoveUserModal(false),
    },
  ];

  useEffect(() => {
    setCreateBtn(hasAccessAddUser);
    setCreateBtnName("Create User");
    setCancelEditUserModal(false);
    return () => {
      setCreateBtn(false);
      setCreateBtnName("");
      setUserCreatedStatus(false);
    };
  }, [hasAccessAddUser]);

  useEffect(() => {
    if (data?.data?.message && userCreateStatus) {
      toast.success(`${data?.data?.message}`, {
        position: "top-right",
        autoClose: 2000,
      });
    }
    return () => {
      setUserCreatedStatus(false);
    };
  }, [userCreateStatus]);

  useEffect(() => {
    if (deleteIsSuccess) {
      toast.success(`User Profile Delete Successfully`, {
        position: "top-right",
        autoClose: 2000,
      });
    }

    if (deleteIsError) {
      toast.success(`${deleteError}`, {
        position: "top-right",
        autoClose: 2000,
      });
    }
  }, [deleteIsError, deleteIsSuccess, deleteError]);

  return (
    <>
      <div className="Grid-padding" style={{ marginTop: "15px", marginLeft: "6px", marginRight: "11.5px" }}>
        {data?.data?.User?.length ? (
          <StyledTableContainer>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <StyledTableHeaderCell>Username</StyledTableHeaderCell>
                  <StyledTableHeaderCell>First Name</StyledTableHeaderCell>
                  <StyledTableHeaderCell>Last Name</StyledTableHeaderCell>
                  <StyledTableHeaderCell>Role</StyledTableHeaderCell>
                  {/* <StyledTableHeaderCell>Password</StyledTableHeaderCell> */}
                  {hasAccessEditUser && <StyledTableHeaderCell>Edit</StyledTableHeaderCell>}
                  {hasAccessDeleteUser && <StyledTableHeaderCell>Remove</StyledTableHeaderCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.data?.User.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((curData, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableBodyCell>{curData?.username}</StyledTableBodyCell>
                    <StyledTableBodyCell>{curData?.firstName.trim()}</StyledTableBodyCell>
                    <StyledTableBodyCell>{curData?.lastName.trim()}</StyledTableBodyCell>
                    <StyledTableBodyCell>{curData?.role?.roleName || "-"}</StyledTableBodyCell>
                    {/* <StyledTableBodyCell>{curData.plainPassword.trim()}</StyledTableBodyCell> */}
                    {hasAccessEditUser && (
                      <StyledTableBodyCell>
                        <IconButton disableFocusRipple disableRipple sx={{ padding: "0px" }} onClick={() => handlEditUserInfo(curData)}>
                          <ClientActivityEdit />
                        </IconButton>
                      </StyledTableBodyCell>
                    )}
                    {hasAccessDeleteUser && (
                      <StyledTableBodyCell>
                        <IconButton disableFocusRipple disableRipple sx={{ padding: "0px" }} onClick={() => handlDeleteUserInfo(curData)}>
                          <TrashIcon />
                        </IconButton>
                      </StyledTableBodyCell>
                    )}
                  </StyledTableRow>
                ))}
              </TableBody>
            </StyledTable>
          </StyledTableContainer>
        ) : (
          <div style={{ textAlign: "center", padding: "35px" }}>
            <Background />
          </div>
        )}
      </div>
      <RemoveUser open={removeUserModal} setOpen={setRemoveUserModal} btnInfo={btnInfo} heading="Do you want to Remove the User?" />
      <ToastContainer />
    </>
  );
};

export default UsersScreen;
