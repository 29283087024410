import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import "./product.css";
import { Toast, ProductCard } from "components/ui";
import { useLocation } from "react-router-dom";
import { useGlobalContext } from "context/GlobalContext";
import useProductInCatalogCheck from "hooks/useProductInCatalogCheck";

const Product = ({ product }) => {
  const location = useLocation();
  const innerChnages = location?.state?.innerChnages;
  const isEdit = location?.state?.edit || innerChnages;

  const { setBtnTitle, btnTitle, presentInCatalog } = useProductInCatalogCheck(product);

  const [open, setOpen] = useState(false);
  const [selectedCartProducts, setSelectedCartProducts] = useState({});
  const { setCountCart, countCart } = useGlobalContext();

  const addtoCart = () => {
    setBtnTitle("Added");
    setOpen(true);
  };

  const removeCart = (productToRemove) => {
    setBtnTitle("Add");
    let getCartData = JSON.parse(sessionStorage.getItem("CartData")) || [];
    getCartData = getCartData.filter((item) => (item.id || item._id) !== productToRemove.id);
    // getCartData.pop(productToRemove);
    sessionStorage.setItem("CartData", JSON.stringify(getCartData));
    setCountCart((prevCount) => prevCount - 1);
  };

  useEffect(() => {
    setSelectedCartProducts(() => ({
      ...product,
      isAddedInCart: true,
    }));
  }, []);

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Grid
        item
        // xs={12}
        // sm={6}
        // md={4}
        // lg={4}
        // xl={4}
        className="product"
      >
        <ProductCard
          data={product}
          selectedCartProducts={selectedCartProducts}
          style={{ maxWidth: isEdit ? "430px" : "430px", minWidth: isEdit ? "430px" : "430px" }}
          {...(innerChnages && {
            isBtn: true,
            addtoCart: addtoCart,
            removeCart: removeCart,
            btnTilte: btnTitle,
            presentInCatalog: presentInCatalog,
          })}
        />
        <Toast
          open={open}
          msg={`Design Has Been Added To Cart ${countCart} `}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          ContentProps={{
            sx: { backgroundColor: "#50C878" },
          }}
        />
      </Grid>
    </>
  );
};

export default React.memo(Product);
