// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.time-spent-catalog {
  font-weight: 500 !important;
  color: #141313 !important;
  line-height: 22px !important;
}

.time-spent-catalog-heading {
  font-family: Poppins !important;
  font-size: 24px !important;
}

.time-spent-catalog-subheading {
  font-family: Karla !important ;
  font-size: 20px !important;
}

.time-spent-font-size-18 {
  font-family: Karla !important;
  font-size: 18px !important;
}
`, "",{"version":3,"sources":["webpack://./src/features/catalogue/components/Settings/utils/TimeSpent.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,yBAAyB;EACzB,4BAA4B;AAC9B;;AAEA;EACE,+BAA+B;EAC/B,0BAA0B;AAC5B;;AAEA;EACE,8BAA8B;EAC9B,0BAA0B;AAC5B;;AAEA;EACE,6BAA6B;EAC7B,0BAA0B;AAC5B","sourcesContent":[".time-spent-catalog {\n  font-weight: 500 !important;\n  color: #141313 !important;\n  line-height: 22px !important;\n}\n\n.time-spent-catalog-heading {\n  font-family: Poppins !important;\n  font-size: 24px !important;\n}\n\n.time-spent-catalog-subheading {\n  font-family: Karla !important ;\n  font-size: 20px !important;\n}\n\n.time-spent-font-size-18 {\n  font-family: Karla !important;\n  font-size: 18px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
