import { FormControl, FormLabel, OutlinedInput, ThemeProvider, Typography } from "@mui/material";
import Button from "components/ui/Button";
import custumTheme from "./profile-theme";
import { useEffect, useState } from "react";
import { useGetProfile } from "../apis/get-profile";
import { useChangePassword } from "../apis/change-pass";
import { useEditProfile } from "../apis/edit-profile";
import apiClient from "lib/api-client";
import { getUserDetail } from "utils/getUserDetailsHelper";
import { toast } from "react-toastify";
import DefaultProfile from "assets/img/profile.png";
import { useQueryClient } from "@tanstack/react-query";
import { usePermissions } from "context/PermissionsContext";
import { findSpecificPage, findSpecificSubPage, hasAccessSubPage } from "utils/checkUserRigths";

export const ViewProfile = ({ changeView }) => {
  const { isLoading, isError, data, error } = useGetProfile();
  const [selectedImage, setSelectedImage] = useState(data?.profileImage);
  const queryClient = useQueryClient();

  const permission = usePermissions();
  const editProfileAccess = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Settings", permission), "Profile"), "edit");

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    setSelectedImage(imageUrl);
    const user = getUserDetail("_id");

    const formData = new FormData();
    formData.append("profileImage", file);
    formData.append("userId", user);

    if (file) {
      try {
        const response = await apiClient.post("/api/v1/users/editProfile", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (response.status === 200) {
          queryClient.invalidateQueries("profile");
          toast.success("Image uploaded successfully!", {
            position: "top-right",
            autoClose: 2000,
          });
        } else {
          toast.error("Failed to upload the image.", {
            position: "top-right",
            autoClose: 2000,
          });
        }
      } catch (error) {
        console.error("Error uploading the image:", error);
        alert("An error occurred. Please try again.");
      }
    }
  };

  const handleUploadClick = () => {
    // Programmatically trigger the hidden input element
    const fileInput = document.getElementById("file-input");
    if (fileInput) fileInput.click();
  };

  if (isLoading) {
    return <h1>Loading..........</h1>;
  }

  if (isError) {
    const errorMessage = error?.message || "An error occurred while fetching profile data";
    return <p>{errorMessage}</p>;
  }

  const { firstName, lastName, username, role, profileImage } = data;

  return (
    <>
      <div className="img-div" onClick={handleUploadClick}>
        {!selectedImage && !profileImage ? (
          <img className="profile-img" loading="lazy" src={DefaultProfile} />
        ) : (
          <img className="profile-img" src={selectedImage || profileImage} alt="profile-photo" loading="lazy" />
        )}
        <div className="upload-overlay">Upload Profile</div>
        <input type="file" id="file-input" style={{ display: "none" }} accept="image/*" onChange={handleFileChange} />
      </div>

      <Typography className="center-text">Username : {username}</Typography>
      <Typography className="center-text">Role : {role}</Typography>

      <hr />

      <div className="content-info" style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p>
            <span style={{ flex: "0 0 120px", textAlign: "right", paddingRight: "8px" }}>First Name</span>
            <span style={{ flex: "0 0 1px", textAlign: "center" }}>:</span>
            <span style={{ flex: 1, textAlign: "left" }}>{firstName}</span>
          </p>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <p>
            <span style={{ flex: "0 0 120px", textAlign: "right", paddingRight: "8px" }}>Last Name</span>
            <span style={{ flex: "0 0 1px", textAlign: "center" }}>:</span>
            <span style={{ flex: 1, textAlign: "left" }}>{lastName} </span>
          </p>
        </div>
      </div>

      <Button
        variant="outlined"
        size="large"
        fullWidth
        className="btns btns-green"
        onClick={() => changeView("edit")}
        sx={{
          display: `${editProfileAccess ? "block" : "none"}`,
        }}
      >
        Edit Profile
      </Button>

      <Button
        variant="outlined"
        size="large"
        fullWidth
        className="btns btns-green"
        sx={{
          display: `${editProfileAccess ? "block" : "none"}`,
        }}
        onClick={() => changeView("changePass")}
      >
        Change Password
      </Button>
    </>
  );
};

export const ChangePassword = ({ onCancel, changeView }) => {
  const [formValues, setFormValues] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [isDirty, setIsDirty] = useState(false);

  const resetForm = () => {
    setFormValues({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    changeView("");
  };

  const { mutate: changePassword, isLoading } = useChangePassword({
    onCompleted: resetForm,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setIsDirty(true);
  };

  const validatePasswords = () => {
    return formValues.currentPassword && formValues.newPassword && formValues.confirmPassword && formValues.newPassword === formValues.confirmPassword;
  };

  const shouldShowError = () => {
    return formValues.newPassword && formValues.confirmPassword && formValues.newPassword !== formValues.confirmPassword;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validatePasswords()) {
      changePassword({
        oldPassword: formValues.currentPassword,
        newPassword: formValues.newPassword,
      });
    }
  };
  return (
    <>
      <ThemeProvider theme={custumTheme}>
        <form className="profile-modal" onSubmit={handleSubmit}>
          <p
            style={{
              fontSize: "24px",
              fontWeight: 500,
              lineHeight: "22px",
              fontFamily: "Poppins",
            }}
          >
            Change Password
          </p>
          <FormControl fullWidth>
            <FormLabel>Current Password</FormLabel>
            <OutlinedInput autoComplete="off" type="text" name="currentPassword" value={formValues.currentPassword} onChange={handleInputChange} />
          </FormControl>
          <FormControl fullWidth>
            <FormLabel>New Password</FormLabel>
            <OutlinedInput autoComplete="off" type="text" name="newPassword" value={formValues.newPassword} onChange={handleInputChange} />
          </FormControl>
          <FormControl fullWidth>
            <FormLabel>Confirm Password</FormLabel>
            <OutlinedInput autoComplete="off" type="text" name="confirmPassword" value={formValues.confirmPassword} onChange={handleInputChange} />
            {shouldShowError() && <span className="Error-msg"> Passwords Are Not Matching</span>}
          </FormControl>
          {/* <Link
            to="forgot-password"
            className="link"
            style={{
              color: "#E77B7B",
              fontSize: "20px",
              lineHeight: "22px",
              fontWeight: 500,
              marginRight: "auto",
              marginLeft: "auto",
              textDecoration: "none",
            }}
          >
            Forget Password?
          </Link> */}
          <Button type="submit" variant="outlined" size="large" fullWidth className={`btns ${!validatePasswords() ? "btn-default" : "btns-green"}`}>
            Save Password
          </Button>

          <Button variant="outlined" size="large" fullWidth className="btns btns-red" onClick={() => (!isDirty ? changeView("") : onCancel(true))}>
            Cancel
          </Button>
        </form>
      </ThemeProvider>
    </>
  );
};

export const EditProfile = ({ onCancel, changeView }) => {
  const [isDirty, setIsDirty] = useState(false);
  const [formValues, setFormValues] = useState({
    firstName: "Sneh",
    lastName: "Patel",
  });

  //fetch the profile
  const { data: profileData, isLoading: isFetchingProfile, isError, error } = useGetProfile();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    setIsDirty(true); // Set to true whenever there's a change
  };

  // Update  values when profileData is fetched
  useEffect(() => {
    if (profileData) {
      setFormValues({
        firstName: profileData.firstName || "",
        lastName: profileData.lastName || "",
      });
    }
  }, [profileData]);

  //update profile mutation
  const changes = () => {
    changeView("");
  };

  const { mutate: editProfile, isLoading: isUpdatingProfile } = useEditProfile({ onCompleted: changes });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isDirty) {
      alert("No changes to save.");
      return;
    }
    editProfile(formValues);
  };

  return (
    <>
      <div className="img-div">
        <img src={profileData.profileImage} alt="profile-photo" />
      </div>

      <Typography className="center-text">Username : EMP-001</Typography>
      <Typography className="center-text">Role : Admin</Typography>

      <hr />

      <form className="profile-modal" onSubmit={handleSubmit}>
        <div
          className="content-info"
          style={{
            display: "flex",
            flexDirection: "column",
            // gap: "20px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <p style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{
                  flex: "0 0 auto",
                  minWidth: "120px",
                  textAlign: "right",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                First Name
              </span>
              <span style={{ flex: "0 0 10px", textAlign: "center" }}>:</span>
              <span style={{ flex: 1, textAlign: "left" }}>
                <input
                  name="firstName"
                  value={formValues.firstName}
                  onChange={handleInputChange}
                  style={{
                    height: "42px",
                    fontSize: "20px",
                    fontFamily: "Karla",
                    fontWeight: "500",
                    padding: "10px",
                    width: `${formValues.firstName.length}ch`,
                    minWidth: "120px",
                    maxWidth: "180px",
                  }}
                />
              </span>
            </p>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <p style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{
                  flex: "0 0 auto",
                  minWidth: "120px",
                  textAlign: "right",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Last Name
              </span>
              <span style={{ flex: "0 0 10px", textAlign: "center" }}>:</span>
              <span style={{ flex: 1, textAlign: "left" }}>
                <input
                  name="lastName"
                  value={formValues.lastName}
                  onChange={handleInputChange}
                  style={{
                    height: "42px",
                    fontSize: "20px",
                    fontFamily: "Karla",
                    // width: "50%",
                    fontWeight: "500",
                    padding: "10px",
                    width: `${formValues.lastName.length}ch`,
                    minWidth: "120px",
                    maxWidth: "180px",
                  }}
                />
              </span>
            </p>
          </div>
        </div>

        <Button type="submit" variant="outlined" size="large" fullWidth className={`btns ${!isDirty ? "btn-default" : "btns-green"}`}>
          Save Profile
        </Button>

        <Button variant="outlined" size="large" fullWidth className="btns btns-red" onClick={() => (!isDirty ? changeView("") : onCancel(true))}>
          Cancel
        </Button>
      </form>
    </>
  );
};
