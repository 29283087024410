import React from "react";
import Header from "components/Header/Header";
import Sidebar from "components/Sidebar/Siderbar";
import { Outlet, useLocation } from "react-router-dom";
import { CatalogSettingHeader } from "components/Header/CatalogSettingHeader";
import "../main-layout-style.css";

function CatalogSettingLayout() {
  const location = useLocation();

  return (
    <>
      {/* <div className="main-layout">
        <CatalogSettingHeader location={location} />
        <div className="main-body">
          <Sidebar />
          <div className="content">
            <Outlet />
          </div>
        </div>
      </div> */}
      <div style={{ position: "fixed", color: "white", width: "100%", display: "flex", justifyContent: "flex-start" }}>
        <div style={{ height: "100vh", width: "auto" }}>
          <Sidebar />
        </div>
        <div style={{ padding: "0.5rem", paddingLeft: "0rem", paddingRight: "0rem", paddingTop: "0rem", height: "100vh", width: "93.5%" }}>
          <CatalogSettingHeader location={location} />
          <div style={{ height: "auto", padding: "20px", paddingBottom: "15px", paddingTop: "0px", paddingLeft: "20px", paddingRight: "20px" }}>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}

export default CatalogSettingLayout;
