const getDesingsIds = () => {
  const data = sessionStorage.getItem("CartData");
  if (!data) return [];

  const products = JSON.parse(data);

  const productIds = Array.isArray(products) ? products.map((product) => product.id) : [];

  return productIds;
};

export { getDesingsIds };
