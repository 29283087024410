import React from "react";
import { Grid, Typography } from "@mui/material";
import Card from "components/ui/Card";
import { useLocation, useNavigate } from "react-router-dom";
import { encodeStr } from "utils/encodeDecodeStr";

const Category = ({ category }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = (category) => {
    const state = {
      ...location.state,
      name: [`${category.category}`],
    };

    navigate(`/Category/${encodeStr(category.category)}`, { state });
  };

  return (
    <Card style={{ minWidth: 275, maxWidth: "317px", height: 62, borderRadius: "5px" }} onClick={() => handleNavigate(category)}>
      <Typography mr={"auto"} fontSize={"20px"} fontWeight={400} fontFamily={"Karla"}>
        {category.category}
      </Typography>
      <Typography ml={"auto"} fontSize={"20px"} fontWeight={400} fontFamily={"Karla"}>
        {category.count}
      </Typography>
    </Card>
  );
};

export default Category;
