import React, { useState } from "react";
import Modal from "components/ui/Modal";
import "../../../../styles/sharelinkmodal.css";
import { Box, FormControl, FormLabel, Grid, OutlinedInput, Typography, useMediaQuery, styled, ButtonBase } from "@mui/material";
import Button from "components/ui/Button";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import custumTheme from "../../../../../../thems/catalogueShareLinkThems";

const CustomButton = styled(Button)(({}) => ({
  width: "60%",
  fontFamily: "Karla",
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "22px",
  height: "50px",
  borderRadius: "5px",
  color: "#00000",
  border: `1px solid #ffffff `,
  textTransform: "none",
  backgroundColor: "#50C878",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "#50C878",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
  },
  "&.Mui-disabled": {
    backgroundColor: "#444444",
    color: "#000000",
    border: "1px solid #444444",
  },
}));

const InputButton = styled(ButtonBase)(({ theme }) => ({
  width: "100%",
  textAlign: "center",
  fontFamily: "Karla",
  fontSize: "18px",
  fontWeight: 500,
  lineHeight: "22px",
  height: "50px",
  borderRadius: "5px",
  border: `1px solid #000000`,
  cursor: "pointer",
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

function UploadExcelModule({ open, setOpen, handleRef }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [linkData, setLinkData] = useState({
    fileName: "",
    fileData: "",
    // {
    //   id: 1,
    //   mobileNumber: "+91 7894561230",
    //   clientName: "Chetan",
    //   firstLogIn: 0,
    //   noOfLogIn: 0,
    // },
  });

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const fileName = e.target.files[0].name;
      setLinkData((prevData) => ({
        ...prevData,
        fileName: fileName,
        fileData: e.target.files[0],
      }));
    }
  };

  const handleClose = () => {
    setOpen(false);
    handleResetForm();
  };

  const handleSubmitForm = () => {
    handleRef(linkData);
    handleClose();
  };

  const handleResetForm = () => {
    setLinkData((data) => ({
      ...data,
      fileName: "",
    }));
  };

  const handleInputClick = () => {
    document.getElementById("file-input").click();
  };

  return (
    <ThemeProvider theme={custumTheme}>
      <Modal open={open} handleClose={handleClose} style={{ width: isSmallScreen ? "400px" : "400px" }}>
        <Grid container spacing={1.5}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography className="catalogue-share-link-modal-heading">Upload Bulk List of Number</Typography>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <FormControl sx={{ width: "100%" }}>
              <FormLabel>Upload Excel</FormLabel>
              <Box sx={{ display: "flex", justifyContent: "flex-start", flexDirection: "column" }}>
                <InputButton disableRipple onClick={handleInputClick}>
                  {linkData.fileName || "Select File"}
                </InputButton>
                <input type="file" id="file-input" accept=".xls,.xlsx" style={{ display: "none" }} onChange={handleFileChange} />
              </Box>
            </FormControl>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} display="flex" justifyContent="center" sx={{ width: "100%" }}>
            <CustomButton disableRipple disableFocusRipple variant="contained" disabled={!linkData.fileName} onClick={handleSubmitForm}>
              Upload
            </CustomButton>
          </Grid>
        </Grid>
      </Modal>
    </ThemeProvider>
  );
}

export default UploadExcelModule;
