const { createContext, useState, useContext } = require("react");

export const CatalogSettingContext = createContext();

export const CatalogSettingProvider = ({ children }) => {
  const [userActivity, setUserActivity] = useState(true);
  const [userReport, setUserReport] = useState(true);
  const [userCurrentPath, setUserCurrentPath] = useState({});
  const [shareLinkOpen, setShareLinkOpen] = useState(false);
  const [timeSpentCatalogOpen, setTimeSpentCatalogOpen] = useState(false);
  const [uploadDataModal, setUploadDataModal] = useState(false);
  const [sortModal, setSortModal] = useState(false);
  const [viewLinkModal, setViewLinkModal] = useState(false);
  const [cartModal, setCartModal] = useState(false);
  const [removeCartModal, setRemoveCartModal] = useState(false);
  const [clockCartModal, setClockCartModal] = useState(false);
  const [isRenewAccess, setIsRenewAccess] = useState(false);
  const [renewAccessModal, setRenewAccessModal] = useState(false);
  const [revokAccessModal, setRevokAccessModal] = useState(false);
  const [updateClient, setUpdateClient] = useState({});
  const [isEditClinet, setIsEditClinet] = useState(false);
  const [serarch, setSerarch] = useState("");

  return (
    <CatalogSettingContext.Provider
      value={{
        userActivity,
        setUserActivity,
        userReport,
        setUserReport,
        userCurrentPath,
        setUserCurrentPath,
        shareLinkOpen,
        setShareLinkOpen,
        timeSpentCatalogOpen,
        setTimeSpentCatalogOpen,
        uploadDataModal,
        setUploadDataModal,
        sortModal,
        setSortModal,
        viewLinkModal,
        setViewLinkModal,
        isRenewAccess,
        setIsRenewAccess,
        renewAccessModal,
        setRenewAccessModal,
        revokAccessModal,
        setRevokAccessModal,
        updateClient,
        setUpdateClient,
        isEditClinet,
        setIsEditClinet,
        serarch,
        setSerarch,
      }}
    >
      {children}
    </CatalogSettingContext.Provider>
  );
};

export const useCatalogSettingContext = () => useContext(CatalogSettingContext);
