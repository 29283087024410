import React, { useCallback, useMemo, useState } from "react";
import Modal from "components/ui/Modal";
import "../../../../styles/sharelinkmodal.css";
import { Box, FormControl, FormLabel, Grid, Input, MenuItem, OutlinedInput, Select, Typography, useMediaQuery, styled } from "@mui/material";
import Button from "components/ui/Button";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import custumTheme from "../../../../../../thems/catalogueShareLinkThems";
import PhoneInput from "react-phone-input-2";

const CustomButton = styled(Button)(({}) => ({
  width: "60%",
  fontFamily: "Karla",
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "22px",
  height: "50px",
  borderRadius: "5px",
  color: "#00000",
  border: `1px solid #ffffff `,
  textTransform: "none",
  backgroundColor: "#50C878",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "#50C878",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
  },
  "&.Mui-disabled": {
    backgroundColor: "#444444",
    color: "#000000",
    border: "1px solid #444444",
  },
}));

function CatalogueRenewAccessModule({ open, setOpen, handleNewSessionStart }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [linkData, setLinkData] = useState({
    hours: "",
  });

  const handleOnChangeEvent = (e) => {
    const { name, value } = e.target;
    setLinkData((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const handleClose = () => {
    setOpen(false);
    handleResetForm();
  };

  const handleSubmitForm = () => {
    handleNewSessionStart(linkData);
    handleClose();
  };

  const handleResetForm = () => {
    setLinkData((data) => ({
      ...data,
      hours: "",
    }));
  };

  return (
    <ThemeProvider theme={custumTheme}>
      <Modal open={open} handleClose={handleClose} style={{ width: isSmallScreen ? "400px" : "400px" }}>
        <Grid container spacing={1.5}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography className="catalogue-share-link-modal-heading">Renew Access</Typography>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <FormControl sx={{ width: "100%" }}>
              <FormLabel>Hours</FormLabel>
              <Box sx={{ display: "flex", justifyContent: "flex-start", flexDirection: "column", gap: 2 }}>
                <Typography className="catalogue-share-link-modal-hours">Between 1 To 72 Hours</Typography>
                <OutlinedInput
                  fullWidth
                  sx={{
                    textAlign: "center",
                    fontFamily: "Karla",
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "22px",
                  }}
                  autoComplete="off"
                  value={linkData.hours}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d{0,2}$/.test(value)) {
                      const numericValue = parseInt(value, 10);

                      if (!isNaN(numericValue) && numericValue >= 1 && numericValue <= 72) {
                        handleOnChangeEvent(e);
                      } else if (value === "") {
                        handleOnChangeEvent(e);
                      }
                    }
                  }}
                  placeholder="HH"
                  name="hours"
                  id="hours"
                  type="text"
                  inputProps={{
                    maxLength: 2,
                    inputMode: "numeric",
                  }}
                />
              </Box>
            </FormControl>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} display="flex" justifyContent="center" sx={{ width: "100%" }}>
            <CustomButton variant="contained" disabled={linkData.hours === ""} onClick={handleSubmitForm}>
              Start Session
            </CustomButton>
          </Grid>
        </Grid>
      </Modal>
    </ThemeProvider>
  );
}

export default CatalogueRenewAccessModule;
