import { useInfiniteQuery } from "@tanstack/react-query";
import catalogAPIs from "../api/catalogAPIs";

export const useGetAllCatalogs = ({ limit = 10, sort = "", search = "", isArchivefilter = false }) => {
  return useInfiniteQuery({
    queryKey: ["catalogData", { limit: limit, sortBy: sort, search: search, isArchive: isArchivefilter }],
    queryFn: ({ pageParam = 1 }) => catalogAPIs.getAllCatalogs({ pageParam, queryKey: ["catalogData", { limit: limit, sortBy: sort, search: search, isArchive: isArchivefilter }] }),
    getNextPageParam: (lastPage) => {
      if (lastPage.nextPage < lastPage.totalPage) {
        return lastPage.nextPage + 1;
      }
      return undefined;
    },
    cacheTime: 200000,
    staleTime: 200000,
    refetchOnWindowFocus: true,
  });
};
