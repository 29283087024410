import React, { useEffect, useState } from "react";
import Product from "./Product";
import { Box, CircularProgress, Grid } from "@mui/material";
import SortContext from "context/SortContext";
import { useFilter } from "context/FilterContext";
import { useGlobalContext } from "context/GlobalContext";
import RemoveProduct from "components/ui/RemoveModal/RemoveModal";
import Home from "pages/Home";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import config from "configs/config";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "@tanstack/react-query";

import axios from "axios";
import { transformData, transformDataForTrayModal } from "utils/transformdata";
import ProductSkeleton from "components/ui/Skeleton/ProductSkeleton";
import apiClient from "lib/api-client";
import GoToTop from "components/ui/GoToTop";

function SubCollectionProduct() {
  console.log("====================== Inside Sub Collection Product ===================");
  const queryClient = useQueryClient(); // Initialize query client
  const { setSortOptions, setSelectedSortValue, search, selectedSortValue, sortOptions, setIsEdit } = React.useContext(SortContext);

  const { setRemoveIcon, setTrashModalOpen, trashModalOpen } = useGlobalContext();

  const [checkedItems, setcheckedItems] = useState({});

  const [collectionId, setCollectionId] = useState("");
  const [collectionProductData, setCollectionProductData] = useState([]);
  const { ref, inView } = useInView();
  const navigate = useNavigate();
  const location = useLocation(); // Capture the current location
  const [previousPath, setPreviousPath] = useState(null); // Store previous path

  const selectedOption = sortOptions.find((option) => option.id == selectedSortValue);
  const selectedSortBY = selectedOption?.sortBy;
  const selectedSortOrder = selectedOption?.order;
  const { filterValues, setFilter, setFilterValues, setBackendFilterValues } = useFilter();
  const labourValues = filterValues.Labour.map((item) => item.split(" - ")[0]).filter(Boolean);
  const classType = filterValues.Class;

  // Numeric range filters
  const diamondPieces = filterValues["Diamond Pieces"]; // Access numeric ranges directly
  const diamondWeight = filterValues["Diamond Weight"];
  const goldGrossWeight = filterValues["Gold Gross-Weight"];
  const goldNetWeight = filterValues["Gold Net-Weight"];
  // Boolean filters or "Yes/No" type filters
  const designInCollection = filterValues["Design In Collection"];
  const designInSets = filterValues["Design In Sets"];

  let preState = location.state;

  useEffect(() => {
    setFilter(true);
    return () => {
      setFilter(false);
    };
  }, []);

  // Set the previous path on component mount
  useEffect(() => {
    if (location.pathname) {
      const segments = location.pathname.split("/");
      const parentPath = segments.slice(0, -1).join("/");

      setPreviousPath(parentPath);
      // queryClient.removeQueries("collectiondesigns");
    }
  }, [location.pathname]);

  useEffect(() => {
    return () => {
      // Clear query data on unmount
      queryClient.removeQueries("collectiondesigns", { exact: true });
    };
  }, [queryClient]);

  useEffect(() => {
    setSortOptions([
      { id: 1, name: "Design No. (Low to High)", sortBy: "Design No", order: "asc" },
      { id: 2, name: "Design No. (High to Low)", sortBy: "Design No", order: "desc" },
      { id: 3, name: "Design Date (Oldest to Newest)", sortBy: "Design Date", order: "asc" },
      { id: 4, name: "Design Date (Newest to Oldest)", sortBy: "Design Date", order: "desc" },
      { id: 5, name: "Gold Net Weight (Low to High)", sortBy: "Gold Net Weight", order: "asc" },
      { id: 6, name: "Gold Net Weight (High to Low)", sortBy: "Gold Net Weight", order: "desc" },
      { id: 7, name: "Diamond Weight (Low to High)", sortBy: "Diamond Weight", order: "asc" },
      { id: 8, name: "Diamond Weight (High to Low)", sortBy: "Diamond Weight", order: "desc" },
    ]);

    return () => {
      setSortOptions([]);
      setSelectedSortValue(null);
      setFilterValues({
        Labour: [],
        Class: [],
        "Diamond Pieces": [],
        "Diamond Weight": [],
        "Gold Gross-Weight": [],
        "Gold Net-Weight": [],
        "Design In Collection": [],
        "Design In Sets": [],
      });
      setCollectionProductData([]);
    };
  }, []);

  //----------------- API Integration  ----------------\\

  //---------------- Filter API --------------------\\
  useEffect(() => {
    const fetchFilterData = async () => {
      try {
        const response = await apiClient.post(`${config.baseUrl}/api/v1/collection/getDesignSlidersData`, {
          collectionName: location.state.name[0],
          collectionCatgeoryName: location.state.name[1].split("-")[0].trim(),
        });
        if (response.data && response.data.data) {
          const { diamondPieces, diamondWeight, grossWeight, netWeight } = response.data.data;
          let filterData = {
            Labour: ["R - Regular", "H - High", "X - Exclusive"],
            Class: ["Gold", "Silver", "Bronze"],
            // Class: [],

            "Design In Collection": ["All"],
            "Design In Sets": ["All"],
            "Diamond Pieces": diamondPieces || [],
            "Diamond Weight": diamondWeight || [],
            "Gold Gross-Weight": grossWeight || [],
            "Gold Net-Weight": netWeight || [],
          };
          // Update the state with the fetched data
          setBackendFilterValues(filterData);
          setFilterValues(filterData);
        }
      } catch (error) {
        console.error("Error fetching filter data:", error);
      }
    };

    fetchFilterData();
  }, []);

  //---------------- Design Get API --------------------\\

  const fetchCollectionsDesign = async ({ pageParam = 1, queryKey }) => {
    const [
      ,
      { limit, sortBy, order, searchTerm, labourFilter, classFilter, diamondPieces, diamondWeight, goldGrossWeight, goldNetWeight, designInCollection, designInSets, categoryName, collectionName },
    ] = queryKey;
    const payload1 = {
      collectionName,
      categoryName,
      pageNumber: pageParam,
      limit,
      searchTerm,
      sortBy,
      order,
      labourFilter,
      classFilter,
      diamondPieces,
      diamondWeight,
      goldGrossWeight,
      goldNetWeight,
      designInCollection,
      designInSets,
    };

    const response = await apiClient.post(`${config.baseUrl}/api/v1/collection/getDesignsInCollection`, payload1);
    setCollectionId(response.data.collectionId);

    return { data: response.data.designs, nextPage: response.data.pagination.pageNumber + 1, totalPages: response.data.pagination.totalPages };
  };

  let collectionNameIs = location.state.name[0];
  // let categoryNameIs = location.state.name[1];
  let categoryNameIs = location.state.name[1].split("-")[0].trim();

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isError, error, refetch } = useInfiniteQuery({
    queryKey: ["collectiondesigns"],
    queryFn: ({ pageParam = 1 }) =>
      fetchCollectionsDesign({
        pageParam,
        queryKey: [
          "collectiondesigns",
          {
            limit: 30,
            sortBy: selectedSortBY,
            order: selectedSortOrder,
            searchTerm: search,
            labourFilter: labourValues.length > 0 ? labourValues : "",
            classFilter: classType.length > 0 ? classType : "",
            diamondPieces: diamondPieces.length > 0 ? diamondPieces : "",
            diamondWeight: diamondWeight.length > 0 ? diamondWeight : "",
            goldGrossWeight: goldGrossWeight.length > 0 ? goldGrossWeight : "",
            goldNetWeight: goldNetWeight.length > 0 ? goldNetWeight : "",
            designInCollection: designInCollection.length > 0 ? designInCollection : "",
            designInSets: designInSets.length > 0 ? designInSets : "",

            collectionName: collectionNameIs,
            categoryName: categoryNameIs,
          },
        ],
      }),
    getNextPageParam: (lastPage) => {
      if (lastPage.nextPage <= lastPage.totalPages) {
        return lastPage.nextPage;
      }
      return undefined;
    },
    keepPreviousData: false,
    enabled: !!collectionNameIs && !!categoryNameIs,
  });

  useEffect(() => {
    if (selectedSortValue || filterValues) {
      refetch();
    }
  }, [selectedSortValue, selectedSortBY, selectedSortOrder, filterValues, refetch]);

  useEffect(() => {
    if (data) {
      const allProducts = data.pages.flatMap((page) => page.data);
      setCollectionProductData(allProducts);
    }
  }, [data]);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  //------------------------------------------------------\\

  const handleCheckboxChange = (itemId) => (event) => {
    setcheckedItems((prevCheckedItems) => {
      const newCheckedItems = { ...prevCheckedItems };

      if (event.target.checked) {
        newCheckedItems[itemId] = true;
      } else {
        delete newCheckedItems[itemId];
      }

      return newCheckedItems;
    });
  };

  const btnInfo = [
    {
      btnName: "Remove",
      bgColor: "#E77B7B",
      click: async () => {
        const findRemoveProductIds = Object.keys(checkedItems);

        const payload = {
          collectionId: collectionId,
          styleRefIds: findRemoveProductIds,
        };

        const removeDesignResponse = await apiClient.post(`${config.baseUrl}/api/v1/collection/removeDesign`, payload);

        if (removeDesignResponse.status === 200) {
          let currentPage = location.state.name[location.state.name.length - 1];

          if (removeDesignResponse.data.updatedCollection.numberOfStyles === 0) {
            navigate("/Collection");
          } else {
            let getCategories = removeDesignResponse.data.updatedCollection.categories;

            let findCurrentPageDesignCount = getCategories.find((cat) => {
              if (cat.categoryName == currentPage || currentPage == "All") {
                return cat.count;
              }
            });
            if (!(findCurrentPageDesignCount?.count > 0)) {
              const currentPreState = { ...preState, name: [...preState.name] }; // Copy current state
              navigate(previousPath || "/Collection", { state: currentPreState });
            }
          }

          setTrashModalOpen(false);
          setcheckedItems({});
          await refetch();
        } else {
          alert(removeDesignResponse.message);
        }
      },
    },
    {
      btnName: "Keep",
      bgColor: "#50C878",
      click: () => {
        setTrashModalOpen(false);
        setcheckedItems({});
      },
    },
  ];

  useEffect(() => {
    if (Object.keys(checkedItems).length > 0) {
      setRemoveIcon(true);
    } else {
      setRemoveIcon(false);
    }
    return () => setRemoveIcon(false);
  }, [checkedItems]);

  if (isLoading) return <ProductSkeleton count={10} />;
  if (isError) return <div>Error: {error.message}</div>;

  return (
    <>
      {isLoading ? (
        <ProductSkeleton count={10} />
      ) : collectionProductData && collectionProductData.length > 0 ? (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          sx={{ flex: "1 1 1", display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "30px", rowGap: "32px" }}
          className="Grid-padding"
          rowGap={"16px"}
        >
          {collectionProductData?.map((product, ind) => {
            return <Product product={transformDataForTrayModal(product)} index={ind} key={product._id} handleCheckboxChange={handleCheckboxChange} checkedItems={checkedItems} />;
          })}
          <RemoveProduct open={trashModalOpen} setOpen={setTrashModalOpen} btnInfo={btnInfo} heading="Do you want to remove the Design?" />
        </Grid>
      ) : (
        <Home />
      )}
      <GoToTop />
      {/* Loader when fetching next page */}
      {isFetchingNextPage && <ProductSkeleton count={10} />}

      {/* The element to observe for triggering the next page fetch */}
      <div ref={ref} style={{ height: "1px" }} />
    </>
  );
}

export default SubCollectionProduct;
