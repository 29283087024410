import React, { useEffect, useState } from "react";

import "../../../collection/styles/collectionScreen.css";
import collectionDataJson from "../../../../db/collectionData.json";
import { ReactComponent as Background } from "assets/icons/bg.svg";
import Card from "components/ui/Card";
import { Grid, Skeleton, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useGlobalContext } from "context/GlobalContext";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";

import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import config from "configs/config";
import SortContext from "context/SortContext";
import { encodeStr } from "utils/encodeDecodeStr";
import LoadingSmallCard from "components/ui/Skeleton/LoadingSmallCard";
import apiClient from "lib/api-client";
import { usePermissions } from "context/PermissionsContext";
import { findSpecificPage, hasAccessPage } from "utils/checkUserRigths";
import RemoveModal from "components/ui/RemoveModal/RemoveModal";

function CollectionScreen() {
  const { search, selectedSortValue, setIsEdit, isEdit, setIsEditClick, isCollectionEdit, setIsCollectionEdit } = React.useContext(SortContext);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);

  const [collectionRemovePayload, setCollectionRemovePayload] = useState({});
  let [collectionData, setCollectionData] = useState(collectionDataJson);

  const navigate = useNavigate();
  const location = useLocation();
  const innerChnages = location?.state?.innerChnages;
  const collectionPresent = location?.state?.collection;

  const { setCreateBtn, setCreateBtnName, setCreateBtnOption, showCollectionTrashIcon, setShowCollectionTrashIcon, setCollectionUpdate, setOpenCancelModal, openCancelModal } = useGlobalContext();
  // const [forceLoading, setForceLoading] = useState(true);
  const permission = usePermissions();
  const hasAccessAddCollection = hasAccessPage(findSpecificPage("Collection", permission), "add");
  const hasAccessEditCollection = hasAccessPage(findSpecificPage("Collection", permission), "edit");

  console.log("hasAccessAddCollection----------->", hasAccessAddCollection);
  console.log("hasAccessEditCollection----------->", hasAccessEditCollection);

  const payload = {
    pageNumber: 1,
    limit: 100,
    searchTerm: search, //Winter Collection
    sortBy:
      selectedSortValue == "1" || selectedSortValue == "2"
        ? "alphabetical"
        : selectedSortValue == "3" || selectedSortValue == "4"
          ? "quantity"
          : selectedSortValue == "5" || selectedSortValue == "6"
            ? "collectionDate"
            : "", //alphabetical //quantity
    order: selectedSortValue == "1" || selectedSortValue == "4" || selectedSortValue == "5" ? "asc" : "desc", //asc //desc
  };

  const fetchCollections = async () => {
    const response = await apiClient.post(`${config.baseUrl}/api/v1/collection/getCollections`, payload);
    return response.data;
  };

  const {
    data: collections,
    isLoading,
    error,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["collections", payload],
    queryFn: fetchCollections,
    enabled: !!payload,
    refetchOnWindowFocus: false,
  });

  const formatDateToDDMMYYYY = (dateString) => {
    if (!dateString) return ""; // Handle cases where the date might be null or undefined
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    if (collections) {
      setCollectionData(collections?.collections);
    }
  }, [collections]);

  const handleClick = (ele) => {
    const state = {
      ...location.state,
      name: [`${ele.collectionDetails.collectionName}`],
      collection: collectionPresent === undefined || null ? true : collectionPresent,
    };

    navigate(`/Collection/${encodeStr(ele.collectionDetails.collectionName)}`, { state });
  };

  const handleOpenRemoveModal = (ele) => {
    setCollectionRemovePayload(ele.collectionDetails._id);
    setOpenRemoveModal(true);
  };

  const handleDeleteCollection = async () => {
    let existTempDataOfCollection = JSON.parse(localStorage.getItem("tempCollectionRemoveData")) || [];
    if (existTempDataOfCollection.length > 0) {
      existTempDataOfCollection.push(collectionRemovePayload);
      localStorage.setItem("tempCollectionRemoveData", JSON.stringify(existTempDataOfCollection));
    } else {
      existTempDataOfCollection.push(collectionRemovePayload);

      localStorage.setItem("tempCollectionRemoveData", JSON.stringify([collectionRemovePayload]));
    }
    // Filter out the items in collectionData based on IDs in localStorage
    const filteredCollectionData = collectionData.filter((collection) => {
      if (!existTempDataOfCollection.includes(collection.collectionDetails._id)) {
        return collection;
      }
    });
    setCollectionData(filteredCollectionData);
    setOpenRemoveModal(false);
    setCollectionUpdate(true);
  };

  useEffect(() => {
    console.log("Inside ------------------");
    // refetch();
    if (isCollectionEdit !== "view") {
      setCreateBtn(false);
    } else {
      setCreateBtn(hasAccessAddCollection);

      // setCreateBtn(true);
    }
  }, [isCollectionEdit]);

  useEffect(() => {
    if (!innerChnages) {
      setCreateBtn(hasAccessAddCollection);

      setCreateBtnName("Create Collection");
      if (hasAccessEditCollection) {
        setIsCollectionEdit("view");
      } else {
        setIsCollectionEdit("");
      }
      setCreateBtnOption([{ option: "By Category", link: "/Category", view: hasAccessPage(findSpecificPage("Category", permission), "view") }]);
      sessionStorage.removeItem("updateProductsData");
      sessionStorage.removeItem("collectionsProductsData");

      return () => {
        setIsCollectionEdit("");
        setCreateBtn(false);
        setCreateBtnName("");
        setCreateBtnOption([]);

        setShowCollectionTrashIcon(false);
        setIsEditClick(false);
        localStorage.removeItem("tempCollectionRemoveData");
      };
    }
  }, [innerChnages]);

  if (isLoading) return <LoadingSmallCard count={24} />;
  if (isError) return <div>Error: {error.message}</div>;

  const btnInfo1 = [
    {
      btnName: "Delete",
      bgColor: "#E77B7B",
      click: async () => {
        handleDeleteCollection();
      },
    },
    {
      btnName: "Keep",
      bgColor: "#50c878",
      click: () => {
        setOpenRemoveModal(false);
      },
    },
  ];

  const btnInfo2 = [
    {
      btnName: "Discard",
      bgColor: "#E77B7B",
      click: async () => {
        refetch();

        setIsCollectionEdit("view");
        setShowCollectionTrashIcon(false);
        localStorage.removeItem("tempCollectionRemoveData");
        setOpenCancelModal(false);
        setCollectionUpdate(false);
      },
    },
    {
      btnName: "Keep",
      bgColor: "#50c878",
      click: () => {
        setOpenCancelModal(false);
      },
    },
  ];
  return (
    <>
      {collectionData && collectionData.length > 0 ? (
        <Grid
          container
          //  spacing={2} rowGap={{ lg: 1 }} columnGap={{ lg: 1.2 }} justifyContent="flex-start" alignItems="center"
          className="Grid-padding"
          direction="row"
          justifyContent="flex-start"
          sx={{ flex: "1 1 1", display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "30px", rowGap: "32px" }}
        >
          {collectionData.map((ele, i) => (
            <Grid item key={i} onClick={() => (showCollectionTrashIcon ? "" : handleClick(ele))}>
              <Card style={{ minWidth: 275, maxWidth: "317px", height: 62, borderRadius: "5px" }}>
                <div>
                  <Typography
                    mr={"auto"}
                    fontSize={"20px"}
                    fontWeight={400}
                    fontFamily={"Karla"}
                    width={"150%"}
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "200px", // Adjust as needed for truncation
                      transition: "all 0.3s ease-in-out",
                      position: "relative",
                    }}
                  >
                    {ele.collectionDetails.collectionName}
                  </Typography>
                  <Typography mr={"auto"} fontSize={"12px"} fontWeight={400} fontFamily={"Karla"} color={"#8B8B8B"}>
                    {formatDateToDDMMYYYY(ele.collectionDetails.createdAt)}
                  </Typography>
                </div>

                {showCollectionTrashIcon ? (
                  <TrashIcon onClick={() => handleOpenRemoveModal(ele)} />
                ) : (
                  <Typography ml={"auto"} fontSize={"20px"} fontWeight={400} fontFamily={"Karla"}>
                    {ele.collectionDetails.numberOfStyles}
                  </Typography>
                )}
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <div style={{ disply: "flex", textAlign: "center", padding: "40px", justifyContent: "center", alignItems: "center" }}>
          <Background />
        </div>
      )}
      {openRemoveModal && <RemoveModal open={openRemoveModal} setOpen={setOpenRemoveModal} btnInfo={btnInfo1} heading=" Do you want to Delete this Collection?" />}
      {openCancelModal && <RemoveModal open={openCancelModal} setOpen={setOpenCancelModal} btnInfo={btnInfo2} heading=" Do you want to Discard this changes?" />}
    </>
  );
}

export default CollectionScreen;
