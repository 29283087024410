import { useQuery } from "@tanstack/react-query";
import usersAPI from "features/Setting/users/api/userApis";

const onSuccess = (data) => {
  console.log("successs");
  console.log("Performing  Afther data fecthing", data.data);
};

const onError = (error) => {
  console.log("error");
  console.log("Performing Afther error encountring", error);
};

const useGetAllUsersData = () => {
  return useQuery({
    queryKey: ["users-data"],
    queryFn: usersAPI.getAllUserData,
    cacheTime: 200000,
    staleTime: 20000,
    refetchOnWindowFocus: true,
  });
};

export default useGetAllUsersData;
