import { AppBar, Box, Divider, Toolbar } from "@mui/material";
import React from "react";

export const ActivityHeader = ({ location }) => {
  return (
    <>
      <AppBar position="fixed" elevation={0} sx={{ backgroundColor: "#000", padding: "0px 0px 6px 0px" }}>
        <Toolbar></Toolbar>
      </AppBar>
      <Box
        sx={{
          border: "1px solid #000",
          display: "flex",
          justifyContent: "center",
          padding: "0px",
          paddingBottom: "10px",
          position: "sticky",
          zIndex: "1000",
          top: "69px",
          backgroundColor: "black !important",
        }}
        maxWidth={"100%"}
      >
        <Divider orientation="horizontal" flexItem style={{ borderColor: "#444444", width: "97%" }} />
      </Box>
    </>
  );
};
