import RemoveModal from "components/ui/RemoveModal/RemoveModal";
import React, { useEffect, useState, useRef } from "react";
import { OutlinedInput } from "@mui/material";

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, IconButton } from "@mui/material";
import { useSettingContext } from "context/SettingContext";
import useGetAllUsersData from "../users/hooks/useGetAllUsers";
import { createLimits, getLimits } from "./api/limitsApis";
import { usePermissions } from "context/PermissionsContext";
import { findSpecificPage, findSpecificSubPage, hasAccessSubPage } from "utils/checkUserRigths";

const StyledTableContainer = styled(TableContainer)(({}) => ({
  backgroundColor: "transparent",
  border: "1px solid #fff",
  borderRadius: "5px",
  maxHeight: "71vh",
  overflow: "visible",

  "&::-webkit-scrollbar": {
    width: 0,
    height: 0,
  },
  "&": {
    scrollbarWidth: "none",
    msOverflowStyle: "none",
  },
}));

const StyledTable = styled(Table)(({}) => ({
  "& th, & td": {
    fontSize: "18px",
    fontFamily: "karla",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#FFFFFF",
    textAlign: "center",
    width: "50%",
  },
  "& th": {
    backgroundColor: "#444444",
    borderBottom: "1px solid #fff",
  },
}));

const StyledTableHeaderCell = styled(TableCell)(({}) => ({
  fontFamily: "Poppins",
  padding: "10px",
  border: "none",
  fontSize: "14px !important",
  fontWeight: "400 !important",
  backgroundColor: "#fff",

  "&:last-child": {
    paddingRight: "25px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: "1px solid #fff",

  "& td": {
    paddingTop: "10px ",
    paddingBottom: "10px ",
  },
}));

const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  fontFamily: "Karla",
  padding: "10px",
  padding: "15px",
  verticalAlign: "center",
  border: "none", // Remove border to ensure only row borders are visible
}));

const limitPageData = [
  { function: "Design adding limit to a catalogue", limit: "" },
  { function: "Catalogue access time limit", limit: "" },
  { function: "Catalogue sharing size limit", limit: "" },
];

function LimitsPage() {
  const [limitData, setLimitData] = useState([]);
  const hasFetchedData = useRef(false);

  const {
    setSelectRoleOptions,
    isLimitPage,
    setIsLimitPage,
    selectRoleOptions,
    setEnableSaveLimitsBtn,
    selectRoleBtnName,
    setSelectRoleBtnName,
    setVibrateButton,
    saveRightModalOpen,
    setSaveRightModalOpen,
  } = useSettingContext();

  const permission = usePermissions();

  const hasAccessEditLimit = hasAccessSubPage(findSpecificSubPage(findSpecificPage("Settings", permission), "Limits"), "edit");
  // console.log("check hasAccess-->", hasAccessEditLimit);

  // Destructure the data and error from the useQuery hook
  const { data, error, isLoading } = useGetAllUsersData();

  useEffect(() => {
    setIsLimitPage(true);

    if (data) {
      const users = data?.data?.User?.map((user) => user);

      const limitOptions = users.map((username) => ({ roleName: username.username, userId: username._id }));

      setSelectRoleOptions(limitOptions);
      setSelectRoleBtnName(selectRoleBtnName);
    }

    setLimitData(limitPageData);

    return () => {
      setLimitData([]);
      setSelectRoleOptions([]);
      setIsLimitPage(false);
      setSaveRightModalOpen(false);
      setSelectRoleBtnName("");
      setVibrateButton(false);
    };
  }, [data, isLimitPage]);

  useEffect(() => {
    const loadSavedData = async () => {
      if (selectRoleBtnName && selectRoleBtnName !== "Select User") {
        //here need to call getLimit Api and store converted data in local storage  and set also
        let user = selectRoleOptions?.find((data) => data?.roleName == selectRoleBtnName);
        let response = await getLimits(user?.userId);

        if (response.status === 200 && response.exists) {
          const formattedLimitData = [
            { function: "Design adding limit to a catalogue", limit: response.exists.designAddLimit },
            { function: "Catalogue access time limit", limit: response.exists.accessTimeLimitHours },
            { function: "Catalogue sharing size limit", limit: response.exists.sharingLimit },
          ];

          setLimitData(formattedLimitData);
        } else {
          setLimitData(limitPageData);
        }
      }
    };

    loadSavedData();
  }, [selectRoleBtnName, selectRoleOptions]);

  //---------------- Limit Change Function  start------------\\

  const checkUserExist = () => {
    if (selectRoleBtnName === "Select User") {
      setVibrateButton(true);
      return;
    }
  };

  const handleLimitChange = (e, index) => {
    const newLimitValue = e.target.value;
    const isValid = /^\d*$/.test(newLimitValue);
    if (isValid) {
      setLimitData((prevLimitData) => {
        const updatedLimitData = prevLimitData.map((item, i) => (i === index ? { ...item, limit: newLimitValue } : item));
        const allLimitsFilled = updatedLimitData.every((item) => {
          // Ensure item.limit is a string before trimming
          return String(item?.limit || "").trim() !== "";
        });
        setEnableSaveLimitsBtn(allLimitsFilled);
        return updatedLimitData;
      });
    }
  };

  //---------------- Limit Change Function  End------------\\

  //-------------- Modal Btn Function start ---------------\\

  const handleDiscardLimits = () => {
    // alert("handleDiscardLimits");
    setLimitData(limitPageData);
    setSaveRightModalOpen(false);
    setEnableSaveLimitsBtn(false);
  };

  const handleSaveLimits = async () => {
    // alert("handleSaveLimits");
    if (selectRoleBtnName && selectRoleBtnName !== "Select User") {
      localStorage.setItem(selectRoleBtnName, JSON.stringify(limitData));
      //here need to call createLimit Api and store converted data in local storage  and set also
      let limitsOfUser = JSON.parse(localStorage.getItem(selectRoleBtnName)) || [];

      const [add_limit = 0, access_time_limit = 0, sharing_limit = 0] = limitsOfUser.map((limit) => limit.limit);

      let payload = {
        user: selectRoleOptions.find((data) => data.roleName == selectRoleBtnName).userId,
        limits: {
          add_limit,
          access_time_limit,
          sharing_limit,
        },
      };
      let LimitData = await createLimits(payload);

      if (LimitData.status == 200) {
        setSaveRightModalOpen(false);
        setEnableSaveLimitsBtn(false);
      } else {
        alert("something went wrong !");
      }
    }
  };

  const btnInfo1 = [
    {
      btnName: "Discard Limits",
      bgColor: "#E77B7B",
      click: () => {
        handleDiscardLimits();
      },
    },
    {
      btnName: "Save Limits",
      bgColor: "#50c878",
      click: () => {
        handleSaveLimits();
      },
    },
  ];

  //-------------- Modal Btn Function end ---------------\\

  return (
    <>
      <div className="Grid-padding" style={{ marginTop: "15px", marginLeft: "6px", marginRight: "11.5px" }}>
        {limitData.length ? (
          <StyledTableContainer>
            <StyledTable>
              <TableHead sx={{ position: "sticky", top: "0", zIndex: "1" }}>
                <TableRow>
                  <StyledTableHeaderCell>Function</StyledTableHeaderCell>
                  <StyledTableHeaderCell>Set Limit</StyledTableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {limitData.map((data, index) => (
                  <React.Fragment key={`parent-${index}`}>
                    <StyledTableRow>
                      <StyledTableBodyCell>{data.function}</StyledTableBodyCell>
                      <StyledTableBodyCell>
                        <OutlinedInput
                          disabled={!hasAccessEditLimit}
                          placeholder=""
                          value={data.limit}
                          onChange={(e) => {
                            console.log("selectRoleBtnName---", selectRoleBtnName);
                            if (selectRoleBtnName !== "Select User") {
                              handleLimitChange(e, index);
                            } else {
                              checkUserExist();
                            }
                          }}
                          sx={{
                            width: "150px",
                            height: "30px",
                            borderRadius: "5px",

                            border: selectRoleBtnName === "Select User" ? "1px solid #fff " : "1px solid #fff",
                            color: "#fff",
                            fontSize: "18px",
                            fontFamily: "karla",
                            fontWeight: "500",
                            padding: "2px",
                            outline: "none !important",

                            "& input": {
                              textAlign: "center",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                        />
                      </StyledTableBodyCell>
                    </StyledTableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </StyledTable>
          </StyledTableContainer>
        ) : (
          <div style={{ textAlign: "center", padding: "35px" }}>Home</div>
        )}
      </div>

      {saveRightModalOpen && <RemoveModal open={saveRightModalOpen} setOpen={setSaveRightModalOpen} btnInfo={btnInfo1} heading="Confirm granting this User these Limits?" />}
    </>
  );
}

export default LimitsPage;
