import React, { useEffect } from "react";
import RenderSets from "features/set/components/Sets";
import SortContext from "context/SortContext";
import { useGlobalContext } from "context/GlobalContext";
import { useLocation } from "react-router-dom";
import { useRemoveDesings } from "features/set/hooks/useRemoveDesigns";
import { usePermissions } from "context/PermissionsContext";
import { findSpecificPage, hasAccessPage } from "utils/checkUserRigths";

const Sets = () => {
  const { setSortOptions, setSelectedSortValue, setIsEdit, isEdit } = React.useContext(SortContext);
  const { setCreateBtn, setCreateBtnName, setCreateBtnOption } = useGlobalContext();
  const location = useLocation();
  const innerChnages = location?.state?.innerChnages;
  const { handleCancel: UIUpdate } = useRemoveDesings();

  const permission = usePermissions();

  const setCreateAccess = hasAccessPage(findSpecificPage("Sets", permission), "add");
  const collectionViewAccess = hasAccessPage(findSpecificPage("Collection", permission), "view");
  const categoryViewAccess = hasAccessPage(findSpecificPage("Category", permission), "view");
  const setEditAccess = hasAccessPage(findSpecificPage("Sets", permission), "edit");

  React.useEffect(() => {
    setSortOptions([
      { id: 1, name: "Set No. (Low to High)", sortBy: "Set Date", order: "asc" },
      { id: 2, name: "Set No. (High to Low)", sortBy: "Set Date", order: "desc" },

      { id: 3, name: "Design Pieces (Low to High)", sortBy: "Number of Styles", order: "asc" },
      { id: 4, name: "Design Pieces (High to Low)", sortBy: "Number of Styles", order: "desc" },

      { id: 5, name: "Set Date (Oldest to Newest)", sortBy: "Set Date", order: "asc" },
      { id: 6, name: "Set Date (Newest to Oldest)", sortBy: "Set Date", order: "desc" },

      { id: 7, name: "Gold Net Weight (Low to High)", sortBy: "Gold Weight", order: "asc" },
      { id: 8, name: "Gold Net Weight (High to Low)", sortBy: "Gold Weight", order: "desc" },

      { id: 9, name: "Diamond Weight (Low to High)", sortBy: "Diamond Weight", order: "asc" },
      { id: 10, name: " Diamond Weight (High to Low)", sortBy: "Diamond Weight", order: "desc" },
    ]);

    //for create set btn
    if (!innerChnages) {
      setCreateBtn(setCreateAccess);
      setCreateBtnName("Create Set");
      setCreateBtnOption([
        { option: "By Category", link: "/Category", view: categoryViewAccess },
        { option: "By Collection", link: "/Collection", view: collectionViewAccess },
      ]);

      return () => {
        setCreateBtn(false);
        setCreateBtnName("");
        setCreateBtnOption([]);
      };
    }

    return () => {
      //for create set btn
      setSortOptions([]);
      setSelectedSortValue(null);
    };
  }, [innerChnages, isEdit]);

  useEffect(() => {
    //for edit view
    if (isEdit === "click") {
      setSortOptions([]);
      setSelectedSortValue(null);
      setCreateBtn(false);
      setCreateBtnName("");
      setCreateBtnOption([]);
      setIsEdit("click");
    } else {
      if (innerChnages) {
        setIsEdit("");
      } else {
        // setIsEdit("view");
        setIsEdit(setEditAccess ? "view" : "");
      }
    }
    // else {
    //   const set = JSON.parse(localStorage.getItem("set"));

    //   if (Array.isArray(set) && set.length > 0 && !innerChnages) {
    //     setIsEdit("view");
    //   } else {
    //     setIsEdit("");
    //   }
    // }
    return () => {
      setIsEdit("");
      UIUpdate();
      // localStorage.removeItem("archivedDesigns");
      // localStorage.removeItem("removedDesignsBySet");
    };
  }, [isEdit]);

  //useEffect for remove the updateProductsData
  useEffect(() => {
    if (!location?.state?.cataloguUpdate) {
      sessionStorage.removeItem("updateProductsData");
    }
  }, []);

  return (
    <>
      <RenderSets innerChnages={innerChnages} location={location} />
    </>
  );
};

export default Sets;
