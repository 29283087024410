import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumbs, Typography } from "@mui/material";
import SeparatorIcon from "components/Icons/SeparatorIcon";
import { useGlobalContext } from "context/GlobalContext";
import { isChanges } from "utils/checkExitCatalogUpdate";

const BreadCrumbComponent = ({ breadcrumbs, onBreadcrumbClick }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isArchive, setCheckCatalogBeforeNavigate, newNameCatalog } = useGlobalContext();

  const handleClick = (breadcrumb) => {
    const pathnames = breadcrumb.path.split("/").filter((x) => x);
    if (breadcrumb.name === "Set") {
      onBreadcrumbClick(breadcrumb);
    }

    if (pathnames[0] === "Catalog" && location?.state?.catalogueName) {
      const value = isChanges(location?.state?.catalogueName, newNameCatalog, isArchive);
      if (value) {
        setCheckCatalogBeforeNavigate(value);
      } else {
        let state = { ...location.state, name: [breadcrumb.name], subName: "" };
        switch (pathnames[0]) {
          case "Catalog": {
            if (pathnames.length === 1) {
              state.view = false;
              state.edit = false;
              state.isActivity = false;
            } else if (pathnames.length === 2) {
              state.edit = false;
              state.view = true;
            } else {
              state.subName = "Edit";
            }
            break;
          }
          default: {
            break;
          }
        }
        navigate(breadcrumb.path, { state });
      }
    } else {
      let state = { ...location.state, name: [breadcrumb.name], subName: "" };
      switch (pathnames[0]) {
        case "Catalog": {
          if (pathnames.length === 1) {
            state.view = false;
            state.edit = false;
            state.isActivity = false;
          } else if (pathnames.length === 2) {
            state.edit = false;
            state.view = true;
          } else {
            state.subName = "Edit";
          }
          break;
        }
        default: {
          break;
        }
      }
      navigate(breadcrumb.path, { state });
    }
  };

  return (
    <Breadcrumbs aria-label="breadcrumb" separator={<SeparatorIcon />}>
      {breadcrumbs.map((breadcrumb, index) => (
        <span key={index}>
          {index === breadcrumbs.length - 1 ? (
            <Typography color="textPrimary" sx={{ fontFamily: "Poppins, sans-serif", fontWeight: "400", fontSize: "24px" }}>
              {breadcrumb.name}
            </Typography>
          ) : (
            <Typography
              color="textPrimary"
              sx={{ fontFamily: "Poppins, sans-serif", fontWeight: "400", fontSize: "24px", color: "inherit", cursor: "pointer" }}
              onClick={() => handleClick(breadcrumb, index)}
            >
              {breadcrumb.name}
            </Typography>
          )}
        </span>
      ))}
    </Breadcrumbs>
  );
};

export default BreadCrumbComponent;
