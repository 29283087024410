const { createContext, useState, useContext, useEffect } = require("react");

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [createBtn, setCreateBtn] = useState(false);
  const [addDesignBtn, setAddDesignBtn] = useState(false);

  const [creatBtnName, setCreateBtnName] = useState("");
  const [createBtnOption, setCreateBtnOption] = useState([]);
  const [countCart, setCountCart] = useState(0);
  const [removeIcon, setRemoveIcon] = useState(false);
  const [trashModalOpen, setTrashModalOpen] = useState(false);
  const [isArchive, setIsArchive] = useState(false);
  const [isArchiveBtn, setIsArchiveBtn] = useState(false);
  const [checkCatalogBeforeNavigate, setCheckCatalogBeforeNavigate] = useState(false);
  const [newNameCatalog, setNewNameCatalog] = useState("");

  const [isCollectionEditBtn, setIsCollectionEditBtn] = useState(false);
  const [showCollectionTrashIcon, setShowCollectionTrashIcon] = useState(false);
  const [collectionUpdate, setCollectionUpdate] = useState(false);

  const [openCancelModal, setOpenCancelModal] = useState(false);

  return (
    <GlobalContext.Provider
      value={{
        trashModalOpen,
        setTrashModalOpen,
        removeIcon,
        setRemoveIcon,
        createBtn,
        setCreateBtn,
        creatBtnName,
        setCreateBtnName,
        createBtnOption,
        setCreateBtnOption,
        countCart,
        setCountCart,
        isArchive,
        setIsArchive,
        isArchiveBtn,
        setIsArchiveBtn,
        checkCatalogBeforeNavigate,
        setCheckCatalogBeforeNavigate,
        newNameCatalog,
        setNewNameCatalog,

        showCollectionTrashIcon,
        setShowCollectionTrashIcon,
        addDesignBtn,
        setAddDesignBtn,

        collectionUpdate,
        setCollectionUpdate,

        openCancelModal,
        setOpenCancelModal,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
