import { useInfiniteQuery } from "@tanstack/react-query";
import catalogReportsAPIs from "../api/catalogReportsAPIs";

export const useGetCatalogReportKeyData = ({ limit = 10, catalogId, styleId, key = "view" }) => {
  return useInfiniteQuery({
    queryKey: ["getCatalogReportKeyData", { limit: limit, catalogId: catalogId, styleId: styleId, key: key }],
    queryFn: ({ pageParam = 1 }) =>
      catalogReportsAPIs.getCatalogReportKeyData({
        pageParam,
        queryKey: ["getCatalogReportKeyData", { limit: limit, catalogId: catalogId, styleId: styleId, key: key }],
      }),
    getNextPageParam: (lastPage) => {
      if (lastPage.nextPage < lastPage.totalPage) {
        return lastPage.nextPage + 1;
      }
      return undefined;
    },
    enabled: !!catalogId && !!styleId,
    cacheTime: 200000,
    staleTime: 200000,
    refetchOnWindowFocus: true,
  });
};
