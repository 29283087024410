import React from "react";
import { ReactComponent as Background } from "assets/icons/bg.svg";

const Home = () => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "85vh" }}>
        <Background />
      </div>
    </>
  );
};

export default Home;
