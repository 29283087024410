import React, { useCallback, useEffect, useState } from "react";
import { Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled } from "@mui/material";

import Button from "components/ui/Button";
import { useCatalogSettingContext } from "context/CatalogSettingContext";
import { useLocation, useParams } from "react-router-dom";
import UserCatalogActivityData from "db/userCatalogActvityData.json";
import RemoveProduct from "components/ui/RemoveModal/RemoveModal";
import CatalogueRenewAccessModule from "./CatalogueRenewAccessModule";
import { useGetAllCatalogClinetActivityInfo } from "../hooks/useGetAllCatalogClinetActivityInfo";
import { useInView } from "react-intersection-observer";
import {toast} from "react-toastify";
import { ReactComponent as Background } from "assets/icons/bg.svg";
import { covertingDateAndTime } from "../utilis/covertingDateAndTime";
import { useRevokeAccessCatalogClient } from "../hooks/useRevokeAccessCatalogClient";
import { useCreateNewRenewAccess } from "../hooks/useCreateNewRenewAccess";

// Styled component for the entire table with padding
const StyledTableContainer = styled(TableContainer)(({}) => ({
  backgroundColor: "transparent",
  maxHeight: "100%",
  minHeight: "100%",
  position: "relative",
  marginBottom: "0px",
  paddingBottom: "5px",

  // border: "1px solid red",

  // Hide scrollbars for Webkit browsers
  "&::-webkit-scrollbar": {
    width: "0px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "transparent",
  },

  // Hide scrollbars for Firefox
  scrollbarWidth: "none", // For Firefox
}));

const StyledTable = styled(Table)(({}) => ({
  "& thead, & tbody": {
    "& th, & td": {
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#FFFFFF",
      textAlign: "center",
      // border: "1px solid pink",
    },
  },
  "& th, & td": {
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#FFFFFF",
    textAlign: "center",
  },
  "& th": {
    backgroundColor: "#444444",
    height: "42px !important",
  },
}));

const StyledTableHeaderCell = styled(TableCell)(({}) => ({
  fontFamily: "Poppins !important",
  padding: "10px",
  paddingLeft: "15px",
  paddingRight: "15px",
  border: "none",
  position: "sticky",
  top: 0,
  zIndex: 1,
  // Apply border-radius and borders to the first and last header cells
  "&:first-child": {
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
  },
  "&:last-child": {
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
    // paddingRight: "25px",
  },
}));

const StyledTableBodyCell = styled(TableCell)(({ theme, color }) => ({
  fontFamily: "Karla !important",
  padding: "10px",
  paddingLeft: "5px",
  paddingRight: "5px",
  border: "none",
  color: `${color} !important`,
  //   border: "1px solid red",
  "&:first-child": {
    // paddingLeft: "5px",
  },
  "&:last-child": {
    // paddingRight: "20px",
  },
}));

const StyledTableRow = styled(TableRow)(({}) => ({
  "& td": {
    padding: "30px",
    paddingTop: "0px",
    // border: "1px solid pink",
  },
  "&:first-of-type td": {
    // padding: "0px",
    paddingTop: "20px",
    // paddingBottom: "20px",
    // border: "1px solid red",
  },
  // "&:last-of-type td": {
  //   padding: "0px",
  //   margin: "0px",
  //   // border: "1px solid red",
  // },
}));

const StyledButton = styled(Button)(({ theme, bgcolor }) => ({
  width: "100%",
  fontFamily: "Karla",
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "22px",
  height: "42px",
  borderRadius: "5px",
  color: bgcolor,
  border: `1px solid ${bgcolor}`,
  textTransform: "none",
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: bgcolor,
    color: "black",
  },
  "&.Mui-disabled": {
    backgroundColor: "transparent",
    color: "#8B8B8B",
    border: "1px solid #8B8B8B",
  },
}));

function parseTimeString(timeStr, currentDate) {
  console.log("timeStr ========>", timeStr);
  const [timePart, modifier] = timeStr.split(" "); // Split into time and AM/PM
  let [hours, minutes, seconds] = timePart.split(":").map(Number); // Extract hours, minutes, seconds

  if (modifier === "PM" && hours !== 12) hours += 12; // Convert PM to 24-hour format
  if (modifier === "AM" && hours === 12) hours = 0; // Handle 12 AM as midnight

  const date = new Date(currentDate); // Get current date
  date.setHours(hours, minutes, seconds, 0); // Set parsed time

  return date;
}

function convertSecondsToHMS(seconds) {
  // Calculate hours, minutes, and seconds
  const hours = Math.floor(seconds / 3600); // 1 hour = 3600 seconds
  const minutes = Math.floor((seconds % 3600) / 60); // Remaining minutes
  const remainingSeconds = seconds % 60; // Remaining seconds

  // Format hours, minutes, and seconds to ensure they are two digits
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

  // Return in HH:MM:SS format
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export const CatalogClientActivityScreen = () => {
  const { catalogClinetId } = useParams();
  const { setUserActivity, setUserCurrentPath, isRenewAccess, setIsRenewAccess, renewAccessModal, setRenewAccessModal, revokAccessModal, setRevokAccessModal } = useCatalogSettingContext();
  const [userActivityData, setUserActivityData] = useState([]);
  // const [revokAccessModal, setRevokAccessModal] = useState(false);
  // const [renewAccessModal, setRenewAccessModal] = useState(false);
  const [revokData, setRevokData] = useState({});
  const location = useLocation();
  const { ref, inView } = useInView({
    threshold: 1.0,
  });

  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isError: isGetError,
    isFetching,
    isFetchingNextPage,
    error: getError,
  } = useGetAllCatalogClinetActivityInfo({ catalogClinetId: catalogClinetId });

  const { mutate: revokeAccessCatalogClient, isError: isRevokeError, isSuccess: isRevokeSuccess, error: revokeError } = useRevokeAccessCatalogClient();
  const { mutate: renewAccessCatalogClient, isError: isRenewError, isSuccess: isRenewSuccess, error: renewError } = useCreateNewRenewAccess();

  const btnInfo = [
    {
      btnName: "Revoke",
      bgColor: "#E77B7B",
      click: () => {
        revokeAccessCatalogClient({ catalogClinetId: catalogClinetId });
        setRevokAccessModal(false);
        // setIsRenewAccess(true);
      },
    },
    {
      btnName: "Keep",
      bgColor: "#50C878",
      click: () => setRevokAccessModal(false),
    },
  ];

  const handleRevokAccess = (curData) => {
    setRevokData(curData);
    setRevokAccessModal(true);
  };

  const handleGrandAccess = (curData) => {
    let updatUserActivityData = userActivityData.map((item) => {
      if (item.id == curData.id) {
        return { ...item, grant: true };
      }
      return item;
    });

    setUserActivityData(updatUserActivityData);
  };

  const handleRenewAccess = () => {
    setRenewAccessModal(true);
  };

  const handleNewSessionStart = (newData) => {
    console.log("newData ====>", newData);
    let data = {
      catalogClinetId: catalogClinetId,
      sessionDuration: newData?.hours ? Number(newData?.hours) : 0,
    };
    renewAccessCatalogClient(data);
    setIsRenewAccess(false);
  };

  const fetchNextPageData = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage]);

  useEffect(() => {
    if (inView) {
      fetchNextPageData();
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

  useEffect(() => {
    setUserActivity(false);
    setUserCurrentPath({
      name: `Client Activity ${location?.state?.subName || "Client Activity"}`,
      path: location?.pathname,
    });

    setIsRenewAccess(() => (data?.pages[0].sessionInfo[0].status === "Active" ? false : true));

    return () => {
      setUserActivity(true);
      setIsRenewAccess(false);
      setUserCurrentPath({});
    };
  }, [data]);

  const isCatalogClinetsPresent = (data) => {
    if (data && data.pages && data.pages.length > 0) {
      return data.pages.some((page) => page.sessionInfo && page.sessionInfo.length > 0);
    }
    return false;
  };

  if (isGetError) {
    toast.error(`${getError}`, {
      position: "top-right",
      autoClose: 2000,
    });
  }

  if (isLoading) {
    return (
      <StyledTableContainer sx={{ maxHeight: "72vh", paddingLeft: "10px", paddingRight: "10px" }}>
        <StyledTable>
          <TableHead>
            <TableRow>
              <StyledTableHeaderCell style={{ minWidth: 220 }}>Session Date & Time</StyledTableHeaderCell>
              <StyledTableHeaderCell style={{ minWidth: 220 }}>Session Duration</StyledTableHeaderCell>
              <StyledTableHeaderCell style={{ minWidth: 220 }}>Expire Date & Time</StyledTableHeaderCell>
              <StyledTableHeaderCell style={{ minWidth: 220 }}>Status</StyledTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from({ length: 4 }, (_, index) => (
              <TableRow>
                {Array.from({ length: 4 }, (_, colIndex) => (
                  <StyledTableHeaderCell>
                    <Skeleton variant="text" margin="auto" width="100%" height="auto" />
                  </StyledTableHeaderCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
    );
  }

  return (
    <>
      {isCatalogClinetsPresent(data) ? (
        <div>
          <StyledTableContainer sx={{ maxHeight: "72vh", paddingLeft: "10px", paddingRight: "10px" }}>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <StyledTableHeaderCell style={{ minWidth: 220 }}>Session Date & Time</StyledTableHeaderCell>
                  <StyledTableHeaderCell style={{ minWidth: 220 }}>Session Duration</StyledTableHeaderCell>
                  <StyledTableHeaderCell style={{ minWidth: 220 }}>Expire Date & Time</StyledTableHeaderCell>
                  <StyledTableHeaderCell style={{ minWidth: 220 }}>Status</StyledTableHeaderCell>
                  {/* <StyledTableHeaderCell>Access</StyledTableHeaderCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.pages?.map((page, pageIndex) => (
                  <React.Fragment key={pageIndex}>
                    {page?.sessionInfo?.map((curData) => {
                      return (
                        <StyledTableRow>
                          <StyledTableBodyCell>{covertingDateAndTime(curData.sessionCreatedDateTime)}</StyledTableBodyCell>
                          <StyledTableBodyCell>{curData.sessionDuration}</StyledTableBodyCell>
                          <StyledTableBodyCell>{covertingDateAndTime(curData.sessionExpireDateTime) || "-"}</StyledTableBodyCell>
                          <StyledTableBodyCell color={curData.status === "Active" ? "#50C878" : "#E77B7B"}>{`${curData.status}`}</StyledTableBodyCell>

                          {/* {index == 0 && curData.status === "Active" && curData.grant ? (
                    <StyledTableBodyCell sx={{ width: "150px" }}>
                      <StyledButton bgcolor={"#E77B7B"} onClick={() => handleRevokAccess(curData)}>
                        Revoke Access
                      </StyledButton>
                    </StyledTableBodyCell>
                  ) : index == 0 && curData.status === "Active" ? (
                    <StyledTableBodyCell sx={{ width: "150px" }}>
                      <StyledButton bgcolor={"#50C878"} onClick={() => handleGrandAccess(curData)}>
                        Grant Access
                      </StyledButton>
                    </StyledTableBodyCell>
                  ) : index == 0 ? (
                    <StyledTableBodyCell sx={{ width: "150px" }}>
                      <StyledButton bgcolor={"#50C878"} onClick={() => handleRenewAccess(curData)}>
                        Renew Access
                      </StyledButton>
                    </StyledTableBodyCell>
                  ) : (
                    ""
                  )} */}
                        </StyledTableRow>
                      );
                    })}
                  </React.Fragment>
                ))}
              </TableBody>
            </StyledTable>
          </StyledTableContainer>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            textAlign: "center",
            margin: "auto",
            padding: "auto",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <Background />
        </div>
      )}
      {<RemoveProduct open={revokAccessModal} setOpen={setRevokAccessModal} btnInfo={btnInfo} heading="Do you want to Revoke Access?" />}
      {<CatalogueRenewAccessModule open={renewAccessModal} setOpen={setRenewAccessModal} handleNewSessionStart={handleNewSessionStart} />}
    </>
  );
};
