import React from "react";
import TimeSpentModal from "../../utils/TimeSpentModal";
import { useGetTotalTimeSpentOnCatalogByClient } from "../hooks/useGetTotalTimeSpentOnCatalogByClient";
import { useParams } from "react-router-dom";

// const reportTimeData = {
//   totalCount: "05:44:15",
//   subData: [
//     {
//       id: 1,
//       dateTime: "24/08/24 - 02:15:48 PM",
//       count: "00:15:15",
//     },
//     {
//       id: 2,
//       dateTime: "23/08/24 - 03:12:56 PM",
//       count: "00:10:08",
//     },
//     {
//       id: 3,
//       dateTime: "22/08/24 - 04:01:48 PM",
//       count: "00:21:05",
//     },
//     {
//       id: 4,
//       dateTime: "21/07/24 - 01:10:06 PM",
//       count: "00:18:21",
//     },
//     {
//       id: 5,
//       dateTime: "20/08/24 - 12:12:56 PM",
//       count: "00:25:45",
//     },
//     {
//       id: 6,
//       dateTime: "20/08/24 - 12:12:56 PM",
//       count: "00:25:45",
//     },
//   ],
// };

function CatalogClientTimeSpentModal({ open, setOpen }) {
  const { catalogClinetId } = useParams();
  const {
    data,
    isLoading: timeIsLoding,
    isError: timeIsError,
    error:timeError,
    hasNextPage,
    fetchNextPage,
    isFetchNextPage,
    isFetching,
  } = useGetTotalTimeSpentOnCatalogByClient({ catalogClientId: catalogClinetId });

  return (
    <TimeSpentModal
      open={open}
      setOpen={setOpen}
      data={data || []}
      timeIsLoding={timeIsLoding}
      timeIsError={timeIsError}
      timeError={timeError}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
      isFetchNextPage={isFetchNextPage}
      isFetching={isFetching}
      title="Time Spent On Each Log-In"
      mainHeading="Total Time Spent on Catalog"
      totalCountTitle="Total Time Spent"
    />
  );
}

export default CatalogClientTimeSpentModal;
