// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-modal-heading {
  font-family: "Poppins" !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 36px !important;
  color: #000000 !important;
  margin-bottom: 2rem !important;
}

.filter-modal-subheading {
  font-family: "karla" !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 36px !important;
  color: #444 !important;
  margin-bottom: 2rem !important;
}

.filter-modal-items {
  color: #000000 !important;
  font-family: "Karla" !important;
  font-size: 18px !important;
  line-height: 21.04px !important;
}

.filter-modal-items-margin-left {
  margin-left: 8px !important;
}

.filter-modal-FormControlLabel-div {
  display: flex !important;
  flex-direction: column !important;
  margin: 16px 0px !important;
}

/* style={{ display: "flex", flexDirection: "column", margin: "16px 0px" }} */
`, "",{"version":3,"sources":["webpack://./src/components/ui/FilterModal/filter.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,0BAA0B;EAC1B,2BAA2B;EAC3B,4BAA4B;EAC5B,yBAAyB;EACzB,8BAA8B;AAChC;;AAEA;EACE,+BAA+B;EAC/B,0BAA0B;EAC1B,2BAA2B;EAC3B,4BAA4B;EAC5B,sBAAsB;EACtB,8BAA8B;AAChC;;AAEA;EACE,yBAAyB;EACzB,+BAA+B;EAC/B,0BAA0B;EAC1B,+BAA+B;AACjC;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,wBAAwB;EACxB,iCAAiC;EACjC,2BAA2B;AAC7B;;AAEA,6EAA6E","sourcesContent":[".filter-modal-heading {\n  font-family: \"Poppins\" !important;\n  font-size: 24px !important;\n  font-weight: 500 !important;\n  line-height: 36px !important;\n  color: #000000 !important;\n  margin-bottom: 2rem !important;\n}\n\n.filter-modal-subheading {\n  font-family: \"karla\" !important;\n  font-size: 18px !important;\n  font-weight: 500 !important;\n  line-height: 36px !important;\n  color: #444 !important;\n  margin-bottom: 2rem !important;\n}\n\n.filter-modal-items {\n  color: #000000 !important;\n  font-family: \"Karla\" !important;\n  font-size: 18px !important;\n  line-height: 21.04px !important;\n}\n\n.filter-modal-items-margin-left {\n  margin-left: 8px !important;\n}\n\n.filter-modal-FormControlLabel-div {\n  display: flex !important;\n  flex-direction: column !important;\n  margin: 16px 0px !important;\n}\n\n/* style={{ display: \"flex\", flexDirection: \"column\", margin: \"16px 0px\" }} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
