import axios from "axios";
import config from "configs/config";
const baseUrl = config.baseUrl;

const client = axios.create({ baseURL: baseUrl });

client.interceptors.request.use(
  (config) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const token = userData?.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      const errorMessage = error.response.data.message;

      if (errorMessage === "jwt expired") {
        localStorage.removeItem("userData");
        window.location.href = "/login";
      }
    }
    return Promise.reject(error);
  }
);

const request = ({ ...options }) => {
  const onSuccess = (response) => response;
  const onError = (error) => Promise.reject(error);

  return client(options).then(onSuccess).catch(onError);
};

export default request;
