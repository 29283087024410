import { useInfiniteQuery } from "@tanstack/react-query";
import clientReportsAPIs from "../api/clientReportsAPIs";

export const useGetCatalogClientStylesReports = ({ limit = 22, sortBy = "view", serarch = "", sortType = "desc", catalogId = "671cf9a98ea4aa46627b4470", catalogClinetId = "6744381c59b61af167182258" }) => {
  return useInfiniteQuery({
    queryKey: ["CatalogClientStylesReports", { limit: limit, sortBy: sortBy, serarch: serarch, sortType: sortType, catalogId: catalogId, catalogClinetId: catalogClinetId }],
    queryFn: ({ pageParam = 1 }) =>
      clientReportsAPIs.getCatalogClientStylesReports({
        pageParam,
        queryKey: ["CatalogClientStylesReports", { limit: limit, sortBy: sortBy, sortType: sortType, serarch: serarch, catalogId: catalogId, catalogClinetId: catalogClinetId }],
      }),
    getNextPageParam: (lastPage) => {
      if (lastPage.nextPage < lastPage.totalPage) {
        return lastPage.nextPage + 1;
      }
      return undefined;
    },
    cacheTime: 200000,
    staleTime: 200000,
    refetchOnWindowFocus: true,
  });
};
