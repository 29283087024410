import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast as myToast } from "react-toastify";
import catalogClientAPIs from "../api/catalogClientAPIs";

export const useCreateNewRenewAccess = () => {
  const quaryClient = useQueryClient();
  return useMutation({
    mutationFn: catalogClientAPIs.createNewRenewAccess,
    onSuccess: (response) => {
      const { data } = response;
      if (data?.status === 200) {
        quaryClient.invalidateQueries("catalogClientsActivityInfoData");
        myToast.success(data?.message || "Client Renew Session Successfully in Catalog!", {
          position: "top-right",
          autoClose: 2000,
        });
      } else {
        myToast.error(`Error: ${data?.message || "Failed to Create Catalog Client Renew Session"}`, {
          position: "top-right",
          autoClose: 2000,
        });
      }
    },
    onError: (error) => {
      const errorMessage = error.response?.data?.message || error.message || "An error occurred";
      myToast.error(`Error: ${errorMessage}`, {
        position: "top-right",
        autoClose: 2000,
      });
    },
  });
};
