import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import config from "configs/config";
import apiClient from "lib/api-client";

const fetchSubCollections = async (payload) => {
  const response = await apiClient.post(`${config.baseUrl}/api/v1/collection/getCollections`, payload);

  return response.data;
};

const useGetCollectionCategory = (payload) => {
  return useQuery({
    queryKey: ["subcollections", payload],
    queryFn: () => fetchSubCollections(payload),
    enabled: !!payload,
    refetchOnWindowFocus: false,
  });
};

export default useGetCollectionCategory;
