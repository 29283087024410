import { Grid } from "@mui/material";
import CatalogueCard from "features/catalogue/components/CatalogueCard";
import RemoveProduct from "components/ui/RemoveModal/RemoveModal";
import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as Background } from "assets/icons/bg.svg";
import { useGetAllCatalogs } from "features/catalogue/hooks/useGetAllCatalog";
import CatalogueCardSkeleton from "features/catalogue/components/CatalogueCardSkeleton";
import { returnCatalogCard } from "utils/returnCatalogCard";
import { useInView } from "react-intersection-observer";
import { toast } from "react-toastify";
import { useCatalogArchiveToUnarchive } from "../hook/useCatalogArchiveToUnarchive";
import { useDeleteCatalog } from "../hook/useDeleteCatalog";

const CatalogArchiveScreen = () => {
  const [unArchiveCatalogModal, setUnArchiveCatalogModal] = useState(false);
  const [deleteCatalogModal, setDeleteCatalogModal] = useState(false);
  const [currentCatalog, setCurrentCatalog] = useState({});
  const { ref, inView } = useInView({
    threshold: 1.0,
  });

  const { data, isLoading, fetchNextPage, hasNextPage, isError, isFetching, isFetchingNextPage, error } = useGetAllCatalogs({
    limit: 10,
    sort: "catalogViewsDesc",
    search: "",
    isArchivefilter: true,
  });

  const { mutate } = useCatalogArchiveToUnarchive();
  const { mutate: deleteCatalog } = useDeleteCatalog();

  const unrachiveBtnInfo = [
    {
      btnName: "Unarchive",
      bgColor: "#E77B7B",
      click: () => {
        mutate({ catalogId: currentCatalog.id });
        setUnArchiveCatalogModal(false);
      },
    },
    {
      btnName: "Keep",
      bgColor: "#50C878",
      click: () => setUnArchiveCatalogModal(false),
    },
  ];

  const deleteBtnInfo = [
    {
      btnName: "Delete",
      bgColor: "#E77B7B",
      click: () => {
        deleteCatalog({ catalogId: currentCatalog.id });
        setDeleteCatalogModal(false);
      },
    },
    {
      btnName: "Keep",
      bgColor: "#50C878",
      click: () => setDeleteCatalogModal(false),
    },
  ];

  const handleButtonClick = (item, title, e) => {
    e.stopPropagation();
    setCurrentCatalog(item);
    if (title === "Unarchive") {
      setUnArchiveCatalogModal(true);
    } else if (title === "Delete") {
      setDeleteCatalogModal(true);
    } else {
      console.log("Not Match Tilte");
    }
  };

  const debouncedFetchNextPage = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage]);

  useEffect(() => {
    if (inView) {
      debouncedFetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

  const isCatalogProductPresent = (data) => {
    if (data && data.pages && data.pages.length > 0) {
      return data.pages.some((page) => page.catalogs && page.catalogs.length > 0);
    }
    return false;
  };

  if (isError) {
    toast.error(`${error}`, {
      position: "top-right",
      autoClose: 2000,
    });
  }

  if (isLoading) {
    return (
      <Grid
        container
        className="Grid-padding"
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ flex: "1 1 1", display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "30px", rowGap: "32px" }}
      >
        {Array.from({ length: 4 }, (_, index) => (
          <CatalogueCardSkeleton key={index} item={{ id: index }} />
        ))}
      </Grid>
    );
  }

  return (
    <>
      {isCatalogProductPresent(data) ? (
        <>
          <Grid
            container
            className="Grid-padding"
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
              paddingLeft: "18px",
              paddingRight: "18px",
              maxHeight: "78vh",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                width: "0px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "transparent",
              },

              // Hide scrollbars for Firefox
              scrollbarWidth: "none", // For Firefox
              flex: "1 1 1",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "32px",
              rowGap: "32px",
            }}
          >
            {data?.pages?.map((page, pageIndex) => (
              <React.Fragment key={pageIndex}>
                {page?.catalogs?.map((item) => {
                  const newItem = returnCatalogCard(item);
                  return <CatalogueCard key={newItem.id} item={newItem} handleButtonClick={handleButtonClick} />;
                })}
              </React.Fragment>
            ))}
            {isFetchingNextPage ? Array.from({ length: 4 }, (_, index) => <CatalogueCardSkeleton key={index} item={{ id: index }} />) : ""}
            <div ref={ref} />
          </Grid>
          {/* <div style={{ color: "red" }}>{isFetching && !isFetchingNextPage ? <CircularProgress color="secondary" /> : null}</div> */}
          {<RemoveProduct open={unArchiveCatalogModal} setOpen={setUnArchiveCatalogModal} btnInfo={unrachiveBtnInfo} heading="Do you want to Unarchive this Catalog?" />}
          {<RemoveProduct open={deleteCatalogModal} setOpen={setDeleteCatalogModal} btnInfo={deleteBtnInfo} heading="Do you want to Delete this Catalog?" />}
        </>
      ) : (
        <div
          style={{
            display: "flex",
            textAlign: "center",
            margin: "auto",
            padding: "auto",
            justifyContent: "center",
            alignItems: "center",
            height: "78vh",
          }}
        >
          <Background />
        </div>
      )}
    </>
  );
};

export default CatalogArchiveScreen;
